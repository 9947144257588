import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  Link,
} from '@chakra-ui/react'
import colt from './reach_colt.jpg'
import garrett from './reach_garrett.jpg'
import { useApp } from 'context/App'
import { FaLinkedinIn } from 'react-icons/fa'
import FadeIn from 'components/Shared/FadeIn'

const FounderCard = ({ bgImage, title, name, linkedin }) => {
  const { isMobile } = useApp()
  return (
    <Flex
      flexDir="column"
      p="40px"
      border="1px solid"
      borderColor="white"
      w={isMobile ? '100%' : '320px'}
      h="470px"
      bgColor="black"
    >
      <Box
        w="100%"
        h="240px"
        bgImage={bgImage}
        flexShrink={0}
        bgSize="100%"
        bgPos="50% 15%"
        borderRadius="0px"
        bgRepeat="no-repeat"
        mb={isMobile ? '24px' : '0'}
        mr={isMobile ? '24px' : '48px'}
      />
      <PersonTitle title={title} name={name} linkedin={linkedin} />
    </Flex>
  )
}

FounderCard.propTypes = {
  bgImage: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  linkedin: PropTypes.string,
}

const PersonTitle = ({ title, name, linkedin }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { isMobile } = useApp()
  return (
    <ContentMaxWidth
      px={isMobile ? '0' : '16px'}
      mt={isMobile ? '16px' : '8px'}
      mb={isMobile ? '0px' : '8px'}
    >
      <Box>
        <Flex alignItems="center">
          <Box mr="8px" w="40px" h="4px" bg="reachTeal.400" flex={1} />
          <Text
            fontWeight="medium"
            fontSize={isMobile ? '12px' : '20px'}
            color="white"
          >
            {title}
          </Text>
        </Flex>
        <Heading mb="8px" fontSize={isMobile ? '32px' : '40px'} color="white">
          {name}{' '}
          <IconButton
            borderRadius="4px"
            size="xs"
            _hover={{ background: 'gray.200' }}
            _active={{ background: 'gray.400' }}
            background="white"
            icon={<Icon transform="scale(1.3)" as={FaLinkedinIn} />}
            href={linkedin}
            target="_blank"
            as={Link}
            mr="16px"
          />
        </Heading>
      </Box>
    </ContentMaxWidth>
  )
}

PersonTitle.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  linkedin: PropTypes.string,
}

const CompanyBody = () => {
  const { isMobile } = useApp()

  return (
    <>
      <ContentMaxWidth
        bg="white"
        color="#1a1a1a"
        mb="64px"
        pt={isMobile ? '120px' : '160px'}
        px={isMobile ? '16px' : '32px'}
        maxW="1600px"
        alignItems="flex-start"
      >
        <Box flex={1} mb="32px" mr={isMobile ? '' : '64px'} maxW="600px">
          <FadeIn>
            <Heading fontSize={isMobile ? '40px' : '40px'} lineHeight={1.4}>
              Reach is reinventing the way security is done.
            </Heading>
          </FadeIn>
        </Box>

        <Box flex={1}>
          {/* <FadeIn delay={0.3}>
            <Text mb="24px" lineHeight={1.5} fontSize="20px">
              We founded Reach in 2021 based on the radical idea that a
              cybersecurity company should be measured against the good it does
              for its customers first and foremost. Sounds like a simple idea.
              But security is based on “preventing bad” rather than “doing
              good,” which makes pursuing this idea a little more complex than
              equating revenue with value delivered to customers.
            </Text>
          </FadeIn>
          <FadeIn delay={0.6}>
            <Text mb="24px" lineHeight={1.5} fontSize="20px">
              In spite of this, we’ve built Reach—our teams and
              technology—around the idea of delivering measurable value to the
              people we serve—from reducing attacks that get through to reducing
              the cost and complexity of managing security to shifting a
              security team’s efforts from fight fires to the strategic work of
              securing their enterprises.
            </Text>
          </FadeIn> */}
        </Box>
      </ContentMaxWidth>

      <ContentMaxWidth maxW="1600px" mb="64px">
        <Flex
          flexDir={isMobile ? 'column' : 'row'}
          flexWrap="wrap"
          p={isMobile ? '16px' : '64px'}
          py={isMobile ? '64px' : undefined}
          bg="black"
          w="100%"
        >
          <Box
            flex={1}
            flexShrink={0}
            minW={isMobile ? 'auto' : '500px'}
            mb="16px"
          >
            <Heading mb="24px" color="white">
              The founders
            </Heading>
            <Box
              color="white"
              fontSize={isMobile ? '20px' : '24px'}
              mr="24px"
              mb="24px"
            >
              Reach Security is co-founded by CEO Garrett Hamilton and CTO Colt
              Blackmore, who have spent more than a decade working together and
              building detection and prevention products at some of the most
              iconic cybersecurity companies in the market, including Palo Alto
              Networks, Proofpoint, and Cylance.
            </Box>
            <Box color="white" fontSize={isMobile ? '20px' : '24px'} mr="24px">
              Their experience learning from the tens of thousands of companies
              who have used their products at scale gives them a unique
              perspective into anticipating the security problems companies face
              and how Reach Security can fix them.
            </Box>
          </Box>
          <Flex gap="24px" flexWrap="wrap">
            <FadeIn inViewOptions={{ threshold: 0.9, triggerOnce: true }}>
              <FounderCard
                bgImage={garrett}
                title="CEO & Co-Founder"
                name="Garrett Hamilton"
                linkedin="https://www.linkedin.com/in/garrettdh/"
              />
            </FadeIn>
            <FadeIn inViewOptions={{ threshold: 0.9, triggerOnce: true }}>
              <FounderCard
                bgImage={colt}
                title="CTO & Co-Founder"
                name="Colt Blackmore"
                linkedin="https://www.linkedin.com/in/coltb/"
              />
            </FadeIn>
          </Flex>
        </Flex>
      </ContentMaxWidth>
    </>
  )
}

export default CompanyBody
