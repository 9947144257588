import React, { useState } from 'react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { Box, Button, Center, Grid, Heading } from '@chakra-ui/react'
import { useApp } from 'context/App'
import TextBlurb from 'components/Shared/TextBlurb'
// import reachConnectionSettings from './images/reach-connection-settings.png'
// import sectionOneImage from './images/section_one_720.webp'
// import sectionTwoImage from './images/section_two.gif'

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { TargetedDefenseImages } from 'components/Deprecated/Home/MiddleSection2'
import MotionBoxInView from 'components/Shared/MotionBoxInView'
import ReactPlayer from 'react-player'
import FadeIn from 'components/Shared/FadeIn'

const ProductBody = () => {
  const { isMobile } = useApp()

  const [isDefenseImagesIn, setIsDefenseImagesIn] = useState(false)
  const [isVideo1InView, setIsVideo1InView] = useState(false)
  const [isVideo2InView, setIsVideo2InView] = useState(false)
  const [isVideo3InView, setIsVideo3InView] = useState(false)

  const history = useHistory()

  const toTryReach = () => {
    history.push('/try-reach')
    window.scrollTo(0, 0)
  }

  return (
    <Box bg="black" color="white">
      <ContentMaxWidth
        mb={isMobile ? '72px' : '120px'}
        alignItems="flex-start"
        maxW="1600px"
        flexDir="column"
      >
        <Box px={isMobile ? '16px' : undefined}>
          <FadeIn>
            <Heading
              mt={isMobile ? '120px' : '160px'}
              mb={isMobile ? '120px' : '200px'}
            >
              How it works
            </Heading>
          </FadeIn>
        </Box>

        <Grid
          gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
          gridGap={isMobile ? '40px' : '280px 160px'}
          alignItems="center"
        >
          <TextBlurb
            heading="Connect & Interrogate"
            body="Reach ingests security events, threat intelligence, and configurations from the products you own via API connections. Data aggregation and processing starts seconds after your products are connected. No new sensors, infrastructure, or attack simulations required."
            flex={1}
          />

          <Center w="100%">
            <Box w={isMobile ? '320px' : '100%'} h="340px" position="relative">
              <MotionBoxInView onInView={() => setIsDefenseImagesIn(true)}>
                <TargetedDefenseImages isIn={isDefenseImagesIn} />
              </MotionBoxInView>
            </Box>
          </Center>

          {isMobile && (
            <TextBlurb
              heading="Profile & Posture"
              body="Reach models hundreds of threat actors and attack techniques, thousands of product capabilities, and millions of business data points. It’s a staggering amount of work, far beyond anything a person can do on their own, but Reach considers every option, and finds the single best result for you."
              flex={1}
            />
          )}

          <Box borderRadius="8px" overflow="hidden">
            <MotionBoxInView onInView={() => setIsVideo1InView(true)}>
              <ReactPlayer
                url="videos/profile_and_posture.mp4"
                width="100%"
                height="100%"
                controls={false}
                playing={isVideo1InView}
                playsinline
                loop
                muted
              />
            </MotionBoxInView>
          </Box>

          {!isMobile && (
            <TextBlurb
              heading="Profile & Posture"
              body="Reach models hundreds of threat actors and attack techniques, thousands of product capabilities, and millions of business data points. It’s a staggering amount of work, far beyond anything a person can do on their own, but Reach considers every option, and finds the single best result for you."
              flex={1}
            />
          )}

          <TextBlurb
            heading="Tune & Tailor"
            body="Reach analyzes millions of data points to understand how your organization is being attacked and who presents the most risk. The result isn’t a collection of “best practices”; it’s the best possible security for you, tailored to the risks you’re facing and the tools you have available."
            flex={1}
          />

          <Box borderRadius="8px" overflow="hidden">
            <MotionBoxInView onInView={() => setIsVideo2InView(true)}>
              <ReactPlayer
                url="videos/customize.mov"
                width="100%"
                height="100%"
                controls={false}
                playing={isVideo2InView}
                playsinline
                loop
                muted
              />
            </MotionBoxInView>
          </Box>

          {isMobile && (
            <TextBlurb
              heading="Plan & Execute"
              body="Reach runs continuously to gather details on what can be improved, and translates the improvements into prioritized actions without needing an entire team and weeks of research and collaboration. These actions, called Quests, are tailored to your unique organization: your people, tools, and risks. They take you from what to do, to how to do it, all the way through to deployment."
              flex={1}
            />
          )}

          <Box borderRadius="8px" overflow="hidden">
            <MotionBoxInView onInView={() => setIsVideo3InView(true)}>
              <ReactPlayer
                url="videos/take_action_step.mp4"
                width="100%"
                height="100%"
                controls={false}
                playing={isVideo3InView}
                playsinline
                loop
                muted
              />
            </MotionBoxInView>
          </Box>

          {!isMobile && (
            <TextBlurb
              heading="Plan & Execute"
              body="Reach runs continuously to gather details on what can be improved, and translates the improvements into prioritized actions without needing an entire team and weeks of research and collaboration. These actions, called Quests, are tailored to your unique organization: your people, tools, and risks. They take you from what to do, to how to do it, all the way through to deployment."
              flex={1}
            />
          )}
        </Grid>
      </ContentMaxWidth>
      <Center pb={isMobile ? '80px' : '120px'} w="100%">
        <Button
          variant="outline"
          colorScheme="reachTeal"
          to="/try-reach"
          height="64px"
          p="16px"
          fontSize="24px"
          size="lg"
          onClick={toTryReach}
        >
          Get a free assessment
        </Button>
      </Center>
    </Box>
  )
}

export default ProductBody
