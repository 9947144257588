import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Image,
  Link,
  Heading,
  LightMode,
  Flex,
} from '@chakra-ui/react'
import FadeIn from 'components/Shared/FadeIn'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import nutanixCaseStudy from './nutanix_case_study.png'
import globalMfgCaseStudy from './global_mfg_case_study.png'
import networkVisibilityDecryption from './network_ssl_visibility_decryption.png'
import autodeskCaseStudy from './autodesk_case_study.png'
import iamSolutionGuide from './iam_solution_guide.png'
import maSolutionGuide from './ma_solution_guide.png'
import securingMa from './securing_ma.png'
import reachOverview from './reach_datasheet.png'
import ResourcesFooter from '../ResourcesFooter'

const PdfImage = ({ src, ...remaining }) => (
  <FadeIn>
    <Image
      maxH="520px"
      cursor="pointer"
      borderRadius="8px"
      overflow="hidden"
      border="1px solid"
      borderColor="gray.200"
      my="16px"
      src={src}
      {...remaining}
    />
  </FadeIn>
)

PdfImage.propTypes = {
  src: PropTypes.any,
}

const GridItemComponent = ({ title, href, imageSrc }) => {
  const handleHubspotDownload = () => {
    const _hsq = window._hsq
    // tracking a "download-content-"
    _hsq.push(['setPath', `download-content-${title}`])
    _hsq.push(['trackPageView'])
    // Set the custom path based on the current location
    _hsq.push(['setPath', href])
    // Track the page view with the custom path
    _hsq.push(['trackPageView'])
  }

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      textAlign="center"
    >
      <Heading size="md" mb={4}>
        {title}
      </Heading>
      <Link
        borderRadius="8px"
        href={href}
        onClick={handleHubspotDownload}
        target="_blank"
        download
      >
        <PdfImage src={imageSrc} />
      </Link>
    </Flex>
  )
}

GridItemComponent.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  imageSrc: PropTypes.any.isRequired,
}

const ContentLibrary = () => {
  const { isMobile } = useApp()

  const gridItems = [
    {
      title: 'Nutanix Case Study',
      href: '/downloads/Nutanix_Reach_Case_Study.pdf',
      imageSrc: nutanixCaseStudy,
    },
    {
      title: 'Autodesk Case Study',
      href: '/downloads/Autodesk_Reach_Case_Study.pdf',
      imageSrc: autodeskCaseStudy,
    },
    {
      title: 'Global MFG Co Case Study',
      href: '/downloads/Global_Mfg_Co_Reach_Case_Study.pdf',
      imageSrc: globalMfgCaseStudy,
    },
    {
      title: 'IAM Solution Guide',
      href: '/downloads/IAM_Conditional_Access_Reach_Solution Guide.pdf',
      imageSrc: iamSolutionGuide,
    },
    {
      title: 'Securing M&A Solution Guide',
      href: '/downloads/Securing_acquisitions_Reach_Solution_Guide.pdf',
      imageSrc: securingMa,
    },
    {
      title: 'Most Attacked Solution Guide',
      href: '/downloads/Risky_Users_and_Groups_Reach_Solution_Guide.pdf',
      imageSrc: maSolutionGuide,
    },
    {
      title: 'Network Visibility SSL Decryption Solution Guide',
      href: '/downloads/Network_Visibility_SSL_Decryption_Reach_Solution_Guide.pdf',
      imageSrc: networkVisibilityDecryption,
    },
    {
      title: 'Reach Security Overview',
      href: '/downloads/Reach-Datasheet-Sept2024.pdf',
      imageSrc: reachOverview,
    },
  ]

  return (
    <LightMode>
      <Box minH="100vh" pt={isMobile ? '120px' : '80px'}>
        <ContentMaxWidth
          maxW="1600px"
          color="black"
          alignItems="center"
          flexDir="column"
          pb="120px"
        >
          <Box
            fontSize={isMobile ? '18px' : '20px'}
            px={isMobile ? '16px' : undefined}
          >
            <Heading
              lineHeight={1.5}
              mb="64px"
              fontSize={isMobile ? '30px' : '38px'}
              textAlign="center"
            >
              Learn more about Reach & our customers
            </Heading>
            <Grid
              templateColumns={`repeat(${isMobile ? '1' : '3'}, 1fr)`}
              gap={6}
            >
              {gridItems.map((item, index) => (
                <GridItemComponent key={index} {...item} />
              ))}
            </Grid>
          </Box>
        </ContentMaxWidth>
        <ResourcesFooter />
      </Box>
    </LightMode>
  )
}

export default ContentLibrary
