import React, { useEffect, useRef } from 'react'
import {
  Box,
  Image,
  VStack,
  LightMode,
  Icon,
  Center,
  Flex,
  Link,
} from '@chakra-ui/react'
import { useApp } from 'context/App'
import nutanix from '../images/nutanixLogo.svg'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import BackgroundTopology from 'components/Shared/BackgroundTopology'
import topoimg from 'assets/images/background_topo.png'
import headshot1 from '../images/SebastianGoodwin/sebastian1.jpg'
import headshot2 from '../images/SebastianGoodwin/sebastian4.jpg'
import headshot3 from '../images/SebastianGoodwin/sebastian5.jpg'
import headshot4 from '../images/SebastianGoodwin/sebastian2.jpg'
import { BsMouse } from 'react-icons/bs'
import {
  AiOutlineComment,
  AiOutlineLineChart,
  AiOutlineUser,
} from 'react-icons/ai'
import BackToTop from 'components/Shared/BackToTop'

const MAX_W = '1100px'

const Nutanix = () => {
  const { setIsWhiteHeader, isMobile } = useApp()

  const ref0 = useRef(null)
  const ref1 = useRef(null)
  const ref2 = useRef(null)

  const smallFontSize = isMobile ? '16px' : '20px'
  const mainFontSize = isMobile ? '20px' : '28px'
  const quoteFontSize = isMobile ? '20px' : '26px'
  const quoteSmallFontSize = isMobile ? '18px' : '22px'
  const headerFontSize = isMobile ? '32px' : '48px'

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsWhiteHeader(true)
    return () => setIsWhiteHeader(false)
  }, [])
  return (
    <Box>
      <BackToTop />
      <LightMode>
        <Box
          pt="128px"
          bg="white"
          h={isMobile ? 'auto' : '100vh'}
          color="black"
        >
          <ContentMaxWidth maxW="1400px" flexDir="column">
            <Flex alignItems="flex-start" flexDir={isMobile ? 'column' : 'row'}>
              <Box>
                <Image h="40px" mb={isMobile ? '48px' : '32px'} src={nutanix} />
                <Box position="relative">
                  <Box
                    lineHeight={1.4}
                    fontSize={isMobile ? '24px' : '48px'}
                    fontWeight="light"
                  >
                    How Enterprise Cloud Platform Juggernaut Nutanix uses
                    automated security control hardening to measure and improve
                    security effectiveness
                  </Box>
                </Box>
              </Box>
              <VStack
                flexShrink={0}
                spacing="32px"
                ml={isMobile ? '0' : '40px'}
                mt={isMobile ? '32px' : '56px'}
                mb={isMobile && '72px'}
                color="reachBlue.500"
                alignItems="flex-start"
              >
                <Box>
                  <Link
                    onClick={() =>
                      ref1.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                  >
                    Using Data to Automate
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    control hardening and attack prevention
                  </Box>
                </Box>

                <Box display="block">
                  <Link
                    onClick={() =>
                      ref0.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                    whiteSpace="break-spaces"
                  >
                    Hiring for Security Expertise
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    instead of security product expertise
                  </Box>
                </Box>

                <Box>
                  <Link
                    onClick={() =>
                      ref2.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                  >
                    Product Effectiveness
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    measuring, improving, and reporting
                  </Box>
                </Box>
              </VStack>
            </Flex>
            {!isMobile && (
              <Center mt="128px" flexDir="column">
                <Icon
                  mb="4px"
                  w="32px"
                  h="32px"
                  color="gray.600"
                  as={BsMouse}
                />
                <Box fontSize="14px" color="gray.600">
                  Scroll down
                </Box>
              </Center>
            )}
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <Box>
        <BackgroundTopology
          bgImage={topoimg}
          bgSize="cover"
          pt="128px"
          pb="128px"
          color="white"
        >
          <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
            <Box
              w="256px"
              h="256px"
              mr={!isMobile && '48px'}
              mx={isMobile && 'auto'}
              mt={isMobile ? '16px' : '48px'}
              mb={isMobile ? '64px' : '128px'}
              bg="black"
              borderRadius="50%"
              flexShrink={0}
              bgImage={headshot1}
              bgSize="cover"
              bgPos="50%"
            />
            <Box>
              <Box
                mb="16px"
                fontSize={isMobile ? '24px' : '34px'}
                width="fit-content"
                borderRadius="16px"
                position="relative"
              >
                <Box
                  bg="#0C153D66"
                  p="16px 32px"
                  borderRadius="8px"
                  position="relative"
                >
                  “Reach has given us prescriptive guidance on where we can
                  improve in our existing toolsets. It’s 100% tailored to us,
                  and the things that are recommended are done based on the
                  current state of my environment. It’s not theoretical, it’s
                  practical.”
                </Box>
              </Box>
              <Box
                fontSize={isMobile ? '18px' : '24px'}
                fontWeight="light"
                ml="32px"
                mb="32px"
              >
                - Sebastian Goodwin, CISO of Nutanix
              </Box>
            </Box>
          </ContentMaxWidth>
          <ContentMaxWidth maxW={MAX_W} pt="32px" flexDir="column">
            <Box fontSize="26px" fontWeight="light" mb="24px">
              Sebastian Goodwin is the CISO at Nutanix. In this role, he is
              responsible for security, compliance, and technology risk
              management. Sebastian also serves on the board of SADA Systems, a
              premier provider of Google Cloud services, and he has developed a
              course in Managing Cyber Risk at U.C. Berkeley.
            </Box>
            <Box fontSize="26px" fontWeight="light">
              Sebastian is a recognized cybersecurity industry leader and the
              author of two books on Windows Server security as well as
              co-author of the Security+ certification exam. His research
              interests include AI in cybersecurity, organizational behavior and
              executive leadership impacts on cybersecurity, quantified risk
              decision models, and security in the hybrid cloud.
            </Box>
          </ContentMaxWidth>
        </BackgroundTopology>
      </Box>

      {/* GRAY BIG TEXT SECTION */}
      <Box bg="gray.100" py="128px">
        <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
          <Box>
            <Box
              color="gray.900"
              fontSize={headerFontSize}
              fontWeight="medium"
              mb="24px"
            >
              Background on Nutanix
            </Box>
            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="16px"
            >
              Nutanix is responsible for simplifying the management of IT
              infrastructure for its customers. With over 22,000 customers,
              Nutanix provides technology to handle the multidisciplinary
              requirements of managing multiple clouds so IT champions don’t
              have to. The information security team, led by Sebastian Goodwin,
              took on the challenge of automating security control decisions to
              address the onslaught of new phishing, malware, and account take
              over attacks focused on the Nutanix global enterprise.
            </Box>
            <Box color="gray.900" fontSize={mainFontSize} fontWeight="light">
              Early efforts at adoption of security automation and orchestration
              helped his team respond to events, but it didn’t address
              prevention. Proactively automating security control hardening of
              the security tools Nutanix had in place was always a priority, but
              execution was difficult with employees prioritized on other tasks.
              Sebastian sought out a vendor partner that could extend automation
              beyond response and also include proactive prevention.
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      <Box bg="white" color="black" py="128px">
        <ContentMaxWidth
          maxW={MAX_W}
          alignItems="flex-start"
          flexDir="column"
          fontWeight="normal"
        >
          <Box mb="64px" fontSize="36px" textAlign="center" width="100%">
            Here are the results of this partnership.
          </Box>
          <Flex
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            flexDir={isMobile ? 'column' : 'row'}
          >
            <Center
              mb={isMobile && '24px'}
              w="260px"
              h="260px"
              borderRadius="50%"
              bg="reachTeal.400"
            >
              <Flex color="white" alignItems="center" flexDir="column">
                <Icon w="32px" h="32px" as={AiOutlineLineChart} />
                <Box fontSize="56px" fontWeight="light" lineHeight={1.3}>
                  99%
                </Box>
                <Box textAlign="center" maxW="240px" fontSize="22px">
                  Visibility increase in security product effectiveness
                </Box>
              </Flex>
            </Center>

            <Center
              mb={isMobile && '24px'}
              w="260px"
              h="260px"
              borderRadius="50%"
              bg="reachTeal.400"
            >
              <Flex color="white" alignItems="center" flexDir="column">
                <Icon w="32px" h="32px" as={AiOutlineUser} />
                <Box fontSize="72px" fontWeight="light" lineHeight={1.2}>
                  0
                </Box>
                <Box textAlign="center" maxW="240px" fontSize="22px">
                  Additional Hires Required
                </Box>
              </Flex>
            </Center>

            <Center
              mb={isMobile && '24px'}
              w="260px"
              h="260px"
              borderRadius="50%"
              bg="reachTeal.400"
            >
              <Flex color="white" alignItems="center" flexDir="column">
                <Icon w="32px" h="32px" as={AiOutlineComment} />
                <Box
                  textAlign="center"
                  fontSize="34px"
                  fontWeight="medium"
                  lineHeight={1.2}
                >
                  Increased Collaboration
                </Box>
                <Box textAlign="center" maxW="200px" fontSize="22px">
                  Between Security & IT
                </Box>
              </Flex>
            </Center>
          </Flex>
        </ContentMaxWidth>
      </Box>

      {/* Text Quote Section 2 */}
      <Box ref={ref1} bg="gray.100" color="black" pt="120px" pb="24px">
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="48px" fontSize={headerFontSize} fontWeight="medium">
            Nutanix uses data to automate control hardening decisions and
            increase attack prevention
          </Box>
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            Most organizations lack visibility into the data required to measure
            and manage the capabilities of their growing security stack. Early
            on in the organization’s evolution, the Nutanix information security
            team continued to find themselves in this predicament and knew there
            had to be a better way to keep up with the latest threats and
            automate this process.
          </Box>
        </ContentMaxWidth>
        <ContentMaxWidth pt="40px" alignItems="flex-start" maxW={MAX_W}>
          <Box>
            <Box
              mb="16px"
              fontSize={quoteFontSize}
              width="fit-content"
              borderRadius="16px"
              position="relative"
            >
              <Box
                bg="white"
                p="16px 32px"
                borderRadius="8px"
                position="relative"
              >
                “We were very early adopters of security orchestration and
                response automation. We built a lot of automation around taking
                an event from our SIEM, enriching it, and taking action. As we
                continue to mature, we’re now using Reach’s ability to automate
                the knowledge and tuning of our whole security stack using our
                data.”
              </Box>
            </Box>
            <Box ml="32px" fontSize={smallFontSize} fontWeight="light">
              - Sebastian Goodwin, CISO of Nutanix
            </Box>
          </Box>
          <Box
            w="240px"
            h="240px"
            ml="48px"
            mt="16px"
            bg="black"
            borderRadius="50%"
            mb="128px"
            flexShrink={0}
            bgImage={headshot3}
            bgSize="cover"
            bgPos="50%"
          />
        </ContentMaxWidth>
      </Box>

      <Box bg="gray.100" color="black" pb="120px">
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            In contrast to best practice assessments or quarterly vendor
            briefings, Reach takes a nuanced approach by translating Nutanix
            specific threats into preventative controls across the stack.
            Reach’s modeling of threat events, sandbox forensics, user
            privileges, and product configurations paints a real-time picture of
            what is breaking through existing defenses and being delivered to
            end users. The information security team at Nutanix has gained
            insight into what was getting through, which users were targets, and
            what was preventable using the products in place currently.
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* GRAY BIG TEXT SECTION */}

      <Box ref={ref0} bg="white" pt="128px" pb="64px">
        <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
          <Box>
            <Box
              color="gray.900"
              fontSize={headerFontSize}
              fontWeight="medium"
              mb="24px"
            >
              Hiring for security expertise instead of security product
              expertise
            </Box>
            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="16px"
            >
              Demand for security talent has never been greater as the threat
              landscape continues to evolve.
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* text and quote section */}
      <Box bg="white" color="gray.900" pb="40px">
        <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
          <Box
            w="240px"
            h="240px"
            mr="48px"
            mx={isMobile && 'auto'}
            mt="16px"
            mb={isMobile ? '48px' : '128px'}
            bg="black"
            borderRadius="50%"
            flexShrink={0}
            bgImage={headshot2}
            bgSize="cover"
            bgPos="50%"
          />

          <Box>
            <Box
              mb="16px"
              fontSize={quoteFontSize}
              width="fit-content"
              borderRadius="16px"
              position="relative"
            >
              <Box
                bg="gray.100"
                p="16px 32px"
                borderRadius="8px"
                position="relative"
              >
                “There was a time in my career as a CISO where I had
                specialists. Where I am today, I made the choice not to hire a
                specific specialist in a tool because there are so many tools
                with so many features out there. I’d rather have someone who
                really understands security and how all of our systems work
                fundamentally.”
              </Box>
            </Box>
            <Box
              mb="64px"
              ml="32px"
              fontSize={smallFontSize}
              fontWeight="light"
            >
              - Sebastian Goodwin, CISO of Nutanix
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* white big text section */}
      <Box bg="white" color="black" pb="96px">
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            Reach makes hardening decisions simple by delivering uniform
            configurations as code that are translated from live attack data in
            Nutanix’s environment. With detailed deployment guides and change
            control tickets pre-packaged for IT, Nutanix’s security teams can
            now focus on security outcomes without the need to know, monitor,
            and manually measure product performance. At the same time, this
            helps Sebastian to focus on hiring pure security talent instead of
            hiring siloed security product experts.
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* Text Quote Section 3 */}
      <Box ref={ref2} bg="gray.100" color="black" py="120px">
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="48px" fontSize={headerFontSize} fontWeight="medium">
            Measuring, improving, and reporting on product effectiveness
          </Box>
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            After countless meetings, health checks, and quarterly check-ins
            from disparate vendors, Sebastian knew there had to be a better way
            to maximize value out of their security investments.
          </Box>
          <ContentMaxWidth
            py="40px"
            p={isMobile && '8px'}
            alignItems="flex-start"
            maxW={MAX_W}
          >
            <Box>
              <Box
                mb="16px"
                fontSize={quoteSmallFontSize}
                width="fit-content"
                borderRadius="16px"
                position="relative"
              >
                <Box
                  bg="white"
                  p={isMobile ? '8px 16px' : '16px 32px'}
                  borderRadius="8px"
                  position="relative"
                >
                  “There were a lot of blindspots before. Some tools have so
                  many features that you have to know they exist and then ask
                  the vendor to turn them on. Looking at all the bells and
                  whistles was not enough. The reality is, vendors release a lot
                  more than can fit in one slide, and we don’t have anyone
                  dedicated to reading the release notes for every new version
                  or monitoring every release of every product that we use which
                  leads to blindspots. Reach has really shined a light on those
                  blindspots and told us things we didn’t know our products
                  could do. I think of Reach as a cheat code for our security
                  products.”
                </Box>
              </Box>
              <Box
                ml="32px"
                fontSize={smallFontSize}
                fontWeight="light"
                opacity=".7"
              >
                - Sebastian Goodwin, CISO of Nutanix
              </Box>
            </Box>
            <Box
              w="240px"
              h="240px"
              ml="48px"
              mt="16px"
              bg="black"
              borderRadius="50%"
              mb="128px"
              flexShrink={0}
              bgImage={headshot4}
              bgSize="cover"
              bgPos="50%"
            />
          </ContentMaxWidth>
          <Box mb="32px" fontSize={mainFontSize} fontWeight="light">
            Reach’s approach to measuring product effectiveness consists of the
            following:
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="normal"
          >
            <Box display="inline" fontWeight="bold">
              1.
            </Box>{' '}
            Pull product configurations using cloud APIs at initial setup
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="normal"
          >
            <Box display="inline" fontWeight="bold">
              2.
            </Box>{' '}
            Model threat data to controls
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="normal"
          >
            <Box display="inline" fontWeight="bold">
              3.
            </Box>{' '}
            Provide new configuration recommendations
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="normal"
          >
            <Box display="inline" fontWeight="bold">
              4.
            </Box>{' '}
            Automatically track configuration changes and improvements over time
          </Box>

          <Box mt="32px" fontSize={mainFontSize} fontWeight="light">
            With Reach, Sebastian’s security teams are able to quantify
            cross-stack metrics over time, such as attacks delivered and attacks
            preventable using existing tools. The result? Increased
            collaboration with peers in IT Operations and a clearer
            demonstration to leadership that they are capable of maximizing
            dollars spent.
          </Box>
        </ContentMaxWidth>
      </Box>

      <Box color="white">
        <BackgroundTopology
          pt="120px"
          pb="180px"
          bgImage={topoimg}
          bgSize="cover"
        >
          <ContentMaxWidth
            maxW={MAX_W}
            alignItems="flex-start"
            flexDir="column"
          >
            <Box mb="16px" fontSize={headerFontSize} fontWeight="medium">
              Nutanix and Reach: The story continues
            </Box>
            <Box mb="16px" fontSize={quoteFontSize}>
              “In a perfect world, we’re maximizing every dollar spent. It
              doesn’t make sense to buy an email security tool, get 70% of the
              features, and buy a second layer because the first is missing
              things. There’s a certain amount of defense in depth and layering
              that makes sense, and a certain amount that happens because of
              suboptimal configurations. To the extent we can minimize that, we
              can really optimize our security spend.”
            </Box>
            <Box
              fontSize={smallFontSize}
              fontWeight="light"
              mb="64px"
              ml="16px"
            >
              - Sebastian Goodwin, CISO of Nutanix
            </Box>
            <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
              In the first month with Reach, Nutanix had measured and deployed
              new capabilities in a way that fit their organization best.
              Sebastian will continue to automate security control hardening
              decisions with Reach to maximize the output of Nutanix’s security
              investments.
            </Box>
          </ContentMaxWidth>
        </BackgroundTopology>
      </Box>
    </Box>
  )
}

export default Nutanix
