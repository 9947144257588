import React, { useEffect } from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { useApp } from 'context/App'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import FadeIn from 'components/Shared/FadeIn'
import HubspotForm from 'react-hubspot-form'

const HUBSPOT_FORM_ID = '0097f51d-797a-4101-ab89-60ddcfbe041a'
const HUBSPOT_PORTAL_ID = '21999761'

const ThanksFromReach = () => {
  const { setIsWhiteHeader, isMobile } = useApp()

  useEffect(() => {
    setIsWhiteHeader(true)
    return () => setIsWhiteHeader(false)
  }, [])

  return (
    <>
      <PageHeader />
      <Box pt="120px" pb="120px" h={isMobile ? '1400px' : '100vh'}>
        <Box mb="160px" w="100%" h={isMobile ? '1200px' : '700px'}>
          <FadeIn delay={0.5}>
            <ContentMaxWidth maxW="1120px" px={0}>
              <Box
                flex={1}
                border="3px solid black"
                bg="reachBlue.400"
                w="100%"
                p={isMobile ? '16px' : '64px'}
              >
                <Heading mb="16px" color="black">
                  Thanks from Reach.
                </Heading>
                <Box color="black" mb="32px" fontSize="24px">
                  We hope you left the conversation as excited as we are! We
                  appreciate you spending time with us. Let us show it by
                  filling out the form below.
                </Box>
                <HubspotForm
                  portalId={HUBSPOT_PORTAL_ID}
                  formId={HUBSPOT_FORM_ID}
                  loading={<div>Loading...</div>}
                />
              </Box>
            </ContentMaxWidth>
          </FadeIn>
        </Box>
      </Box>
      <PageFooter />
    </>
  )
}

export default ThanksFromReach
