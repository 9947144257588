import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom'
import ReactGA from 'react-ga4'
import { Box, DarkMode } from '@chakra-ui/react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import themeExtension from './theme'
import { AppProvider } from 'context/App'
import Company from 'components/Company'
import Home from 'components/Home'
import JoinUs from 'components/Deprecated/JoinUs'
import ScheduleDemo from 'components/ScheduleDemo'
import Terms from 'components/Terms'
import PageNotFound from 'components/PageNotFound'
import Product from 'components/Product'
import UseCases from 'components/Deprecated/UseCases'
import CookieNotification from 'components/CookieNotification'
import Customers from 'components/Deprecated/Customers'
import CaseStudies from 'components/Deprecated/Customers/CaseStudies'
import ToolAssessment from 'components/ToolAssessment'
import ExternalRedirect from 'components/Shared/ExternalRedirect'
import Careers from 'components/Careers'
import Resources from 'components/Resources'
import ThanksFromReach from 'components/ThanksFromReach'
import ReachLogoSplash from 'components/Shared/ReachLogoSplash'
import ToolsRationalizationBlog from 'components/Resources/Blog/BlogPosts/TRABlog'
import MicrosoftEntraBlog from 'components/Resources/Blog/BlogPosts/MicrosoftEntraBlog'
import LastMileBlog from 'components/Resources/Blog/BlogPosts/LastMileBlog'
import ServiceNowBlog from 'components/Resources/Blog/BlogPosts/ServiceNowBlog'
import AutomationBlog from 'components/Resources/Blog/BlogPosts/AutomationBlog'
import OktaNetworkZonesBlog from 'components/Resources/Blog/BlogPosts/OktaNetworkZonesBlog'
import ConfigurationDriftBlog from 'components/Resources/Blog/BlogPosts/ConfigurationDriftBlog'
import D3fendBlog from 'components/Resources/Blog/BlogPosts/D3fendBlog'
import GartnerBlog from 'components/Resources/Blog/BlogPosts/GartnerBlog'
import MicrosoftEntraMfaBlog from 'components/Resources/Blog/BlogPosts/MicrosoftEntraMfaBlog'
import IntegrationArchitectureBlog from 'components/Resources/Blog/BlogPosts/IntegrationArchitectureBlog'
import StrengtheningDefensesBlog from 'components/Resources/Blog/BlogPosts/StrengtheningDefensesBlog'
import RoadsNotRoadBlocksBlog from 'components/Resources/Blog/BlogPosts/RoadsNotRoadBlocksBlog'
import LicensingBlog from 'components/Resources/Blog/BlogPosts/LicensingBlog'
import NYSEInterviewBlog from 'components/Resources/Blog/BlogPosts/NYSE'
import MicrosoftLicensingBlog from 'components/Resources/Blog/BlogPosts/MicrosoftLicensing'
import SecurityMisconfigurationsBlog from 'components/Resources/Blog/BlogPosts/NotTop10'

const theme = extendTheme(themeExtension)

const Tracking = () => {
  const location = useLocation()

  useEffect(() => {
    const fullPath = location.pathname + location.search

    // Google Analytics tracking
    ReactGA.send({
      hitType: 'pageview',
      page: fullPath,
    })

    // HubSpot tracking
    const _hsq = (window._hsq = window._hsq || [])
    _hsq.push(['setPath', fullPath])
    _hsq.push(['trackPageView'])
  }, [location])

  return null
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <DarkMode>
        <Router>
          <AppProvider>
            <CookieNotification />
            <Tracking />
            <Box bg="#fff" minH="100vh">
              <Switch>
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
                <Route path="/home">
                  <Home />
                </Route>
                <Route path="/product">
                  <Product />
                </Route>
                <Route path="/company">
                  <Company />
                </Route>
                <Route path="/careers">
                  <Careers />
                </Route>
                <Route path="/use-cases">
                  <UseCases />
                </Route>
                <Route path="/join-us">
                  <JoinUs />
                </Route>
                <Route path="/resources/blog/identity_centric_tools">
                  <ToolsRationalizationBlog />
                </Route>
                <Route path="/resources/blog/microsoft_entra_spotlight">
                  <MicrosoftEntraBlog />
                </Route>
                <Route path="/resources/blog/last_mile">
                  <LastMileBlog />
                </Route>
                <Route path="/resources/blog/servicenow_integration">
                  <ServiceNowBlog />
                </Route>
                <Route path="/resources/blog/automation">
                  <AutomationBlog />
                </Route>
                <Route path="/resources/blog/okta_integration">
                  <OktaNetworkZonesBlog />
                </Route>
                <Route path="/resources/blog/configuration_drift">
                  <ConfigurationDriftBlog />
                </Route>
                <Route path="/resources/blog/mitre_d3fend_blog">
                  <D3fendBlog />
                </Route>
                <Route path="/resources/blog/gartner_blog">
                  <GartnerBlog />
                </Route>
                <Route path="/resources/blog/microsoft_entra_mfa">
                  <MicrosoftEntraMfaBlog />
                </Route>
                <Route path="/resources/blog/not_top_10">
                  <SecurityMisconfigurationsBlog />
                </Route>
                <Route path="/resources/blog/integration_architecture_blog">
                  <IntegrationArchitectureBlog />
                </Route>
                <Route path="/resources/blog/microsoft_licensing">
                  <MicrosoftLicensingBlog />
                </Route>
                <Route path="/resources/blog/strengthening_defenses_blog">
                  <StrengtheningDefensesBlog />
                </Route>
                <Route path="/resources/blog/roads_not_roadblocks_blog">
                  <RoadsNotRoadBlocksBlog />
                </Route>
                <Route path="/resources/blog/nyse_architecture_to_action">
                  <NYSEInterviewBlog />
                </Route>
                <Route path="/resources/blog/licensing_view_blog">
                  <LicensingBlog />
                </Route>
                <Route path="/customers/*">
                  <CaseStudies />
                </Route>
                <Route path="/customers">
                  <Customers />
                </Route>
                <Route path="/try-reach">
                  <ToolAssessment />
                </Route>
                <Route path="/connect">
                  <ScheduleDemo />
                </Route>
                <Route exact path="/reach-logo">
                  <ReachLogoSplash />
                </Route>
                <Route path={['/gratitude', '/thanks-from-reach']}>
                  <ThanksFromReach />
                </Route>
                <Route exact path="/resources">
                  <Redirect to="/resources/news" />
                </Route>
                <Route path="/resources">
                  <Resources />
                </Route>
                <Route exact path="/terms">
                  <Redirect to="/terms/ToS" />
                </Route>
                <Route path="/terms/*">
                  <Terms />
                </Route>
                <Route path="/twitter" exact>
                  <ExternalRedirect url="https://twitter.com/ReachSecurity" />
                </Route>
                <Route path="/linkedin" exact>
                  <ExternalRedirect url="https://www.linkedin.com/company/reach-security/" />
                </Route>
                <Route path="*">
                  <PageNotFound />
                </Route>
              </Switch>
            </Box>
          </AppProvider>
        </Router>
      </DarkMode>
    </ChakraProvider>
  )
}

export default App
