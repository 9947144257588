import { useMediaQuery } from '@chakra-ui/react'
import React, {
  useMemo,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react'
import ReactGA from 'react-ga4'
import { COOKIE_KEY } from './constants'

const AppContext = createContext()

function useApp() {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error(`useApp must be used within a AppProvider`)
  }
  return context
}

function AppProvider(props) {
  const [headerTextColor, setHeaderTextColor] = useState(null)
  const [headerBgColor, setHeaderBgColor] = useState(null)
  const [footerTextColor, setFooterTextColor] = useState(null)
  const [footerBgColor, setFooterBgColor] = useState(null)
  const [isWhiteHeader, setIsWhiteHeader] = useState(false)
  const [isDesktop] = useMediaQuery('(min-width: 60em)')
  const [isSmall] = useMediaQuery('(min-width: 20em)')
  const [isMedium] = useMediaQuery('(min-width: 50em)')
  const [isLarge] = useMediaQuery('(min-width: 93em)')
  const [isXLarge] = useMediaQuery('(min-width: 120em)')

  const [cookiesAccepted, setCookiesAccepted] = useState(
    localStorage.getItem(COOKIE_KEY)
  )
  const isMobile = !isDesktop

  useEffect(() => {
    if (cookiesAccepted === true || cookiesAccepted === 'true') {
      handleInitializeGoogleAnalytics()
    }
  }, [cookiesAccepted])

  const handleInitializeGoogleAnalytics = () => {
    ReactGA.initialize('G-9Z2GKZT84V')
    ReactGA.send('pageview')
  }

  const handleAcceptCookies = () => {
    localStorage.setItem(COOKIE_KEY, true)
    setCookiesAccepted(true)
    handleInitializeGoogleAnalytics()
  }

  const handleEvaulateBreaks = (obj) => {
    if (isXLarge && obj?.xl) return obj.xl
    if (isLarge && obj?.lg) return obj.lg
    if (isMedium && obj?.md) return obj.md
    if (isSmall && obj?.sm) return obj.sm
    return obj?.default || obj
  }

  const value = useMemo(
    () => ({
      isMobile,
      isDesktop,
      isSmall,
      isMedium,
      isLarge,
      isXLarge,
      isWhiteHeader,
      cookiesAccepted,
      headerTextColor,
      headerBgColor,
      footerTextColor,
      footerBgColor,
      setHeaderTextColor,
      setHeaderBgColor,
      setFooterTextColor,
      setFooterBgColor,
      setIsWhiteHeader,
      handleEvaulateBreaks,
      handleAcceptCookies,
      handleInitializeGoogleAnalytics,
    }),
    [
      isMobile,
      isDesktop,
      isWhiteHeader,
      cookiesAccepted,
      headerTextColor,
      headerBgColor,
      footerTextColor,
      footerBgColor,
      isSmall,
      isMedium,
      isLarge,
      isXLarge,
    ]
  )
  return <AppContext.Provider value={value} {...props} />
}

export { AppProvider, useApp }
