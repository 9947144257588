import React from 'react'
import PropTypes from 'prop-types'
import { Box, Center, Image } from '@chakra-ui/react'

const BlogAuthor = ({ img, name, ...remaining }) => (
  <Center flexDir="column" {...remaining}>
    <Image mb="8px" maxW="40px" borderRadius="50%" src={img} />
    <Box fontSize="12px">{name}</Box>
  </Center>
)

BlogAuthor.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string,
}

export default BlogAuthor
