import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading } from '@chakra-ui/react'
import { useApp } from 'context/App'
import FadeIn from './FadeIn'

export const ResponsiveHeading = ({ fontSize, ...props }) => {
  const { isMobile } = useApp()
  return <Heading fontSize={isMobile ? fontSize * 0.66 : fontSize} {...props} />
}

ResponsiveHeading.propTypes = {
  fontSize: PropTypes.number,
}

const TextBlurb = ({ heading, body, ...remaining }) => {
  const { isMobile } = useApp()

  return (
    <Box {...remaining} px={isMobile ? '16px' : undefined}>
      {heading && (
        <FadeIn>
          {typeof heading === 'string' ? (
            <ResponsiveHeading
              fontWeight="semibold"
              fontSize={40}
              lineHeight={1}
              mb="16px"
            >
              {heading}
            </ResponsiveHeading>
          ) : (
            heading
          )}
        </FadeIn>
      )}

      <FadeIn delay={0.35}>
        <Box fontSize="18px" lineHeight={1.7}>
          {body}
        </Box>
      </FadeIn>
    </Box>
  )
}

TextBlurb.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.node,
  isAlwaysInView: PropTypes.bool,
}

TextBlurb.defaultProps = {
  body: `body`,
}

export default TextBlurb
