import React from 'react'
import PropTypes from 'prop-types'
import {
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  Icon,
  Flex,
  Box,
  LightMode,
} from '@chakra-ui/react'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { useApp } from 'context/App'

const HoverMenu = ({ buttonContents, menuList, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isMobile, isWhiteHeader, headerTextColor } = useApp()

  const color =
    headerTextColor || (isWhiteHeader && !isMobile) ? 'gray.900' : 'white'

  return (
    <LightMode>
      <Box color="black" {...props}>
        <Menu borderRadius={0} isOpen={isOpen} bg="white">
          <MenuButton
            w="140px"
            variant="ghost"
            py={[1, 2, 2]}
            px={4}
            borderRadius={5}
            aria-label="Courses"
            fontWeight="normal"
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
          >
            <Flex justifyContent="center" alignItems="center">
              {buttonContents}
              {isOpen ? (
                <Icon color={color} as={AiOutlineUp} />
              ) : (
                <Icon color={color} as={AiOutlineDown} />
              )}
            </Flex>
          </MenuButton>
          <MenuList
            borderRadius={0}
            mt="-8px"
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
          >
            {menuList.map((menuListItem, i) => (
              <MenuItem
                key={`${menuListItem}-${i}`}
                onClick={menuListItem?.onClick}
              >
                {menuListItem?.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </LightMode>
  )
}

HoverMenu.propTypes = {
  buttonContents: PropTypes.node,
  menuList: PropTypes.array,
}

export default HoverMenu
