import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading } from '@chakra-ui/react'
import { useApp } from 'context/App'
import MotionBoxInView from 'components/Shared/MotionBoxInView'

export const ResponsiveHeading = ({ fontSize, ...props }) => {
  const { isMobile } = useApp()
  return <Heading fontSize={isMobile ? fontSize * 0.66 : fontSize} {...props} />
}

ResponsiveHeading.propTypes = {
  fontSize: PropTypes.number,
}

const TextBlurb = ({
  heading,
  tagline,
  body,
  isAlwaysInView,
  dark,
  ...remaining
}) => (
  <Box {...remaining}>
    {heading && (
      <MotionBoxInView
        isAlwaysInView={isAlwaysInView}
        transformOrigin="0% 50%"
        animateIn={{ scale: 1 }}
        animateOut={{ scale: 0.9 }}
        transition={{ duration: 1.25 }}
      >
        {typeof heading === 'string' ? (
          <ResponsiveHeading
            fontWeight="semibold"
            fontSize={54}
            color="reachTeal.400"
            lineHeight={1}
            mb="8px"
          >
            {heading}
          </ResponsiveHeading>
        ) : (
          heading
        )}
      </MotionBoxInView>
    )}
    <MotionBoxInView
      isAlwaysInView={isAlwaysInView}
      animateIn={{ translateY: 0, opacity: 1 }}
      animateOut={{ translateY: 8, opacity: 0.6 }}
      transition={{ duration: 1.25 }}
    >
      <ResponsiveHeading
        fontWeight="semibold"
        fontSize={42}
        color={dark ? 'gray.900' : 'white'}
        lineHeight={1.2}
        mb="16px"
      >
        {tagline}
      </ResponsiveHeading>
    </MotionBoxInView>

    <Box
      fontSize="18px"
      lineHeight={1.7}
      color={dark ? 'gray.900' : 'reachBlue.100'}
    >
      {body}
    </Box>
  </Box>
)

TextBlurb.propTypes = {
  heading: PropTypes.string,
  tagline: PropTypes.string,
  body: PropTypes.node,
  isAlwaysInView: PropTypes.bool,
  dark: PropTypes.bool,
}

TextBlurb.defaultProps = {
  isAlwaysInView: false,
  tagline: 'Tagline',
  body: `body`,
  dark: false,
}

export default TextBlurb
