import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link as DomLink } from 'react-router-dom'
import {
  Box,
  Flex,
  Heading,
  LightMode,
  Link,
  OrderedList,
  ListItem,
} from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import author from '../AuthorImages/nick3.png'
import misconfig_summary from './image1.png'
import attachment_blocking from './image2.png'
import custom_detections from './image3.png'
import network_blocking from './image4.png'
import BlogAuthor from '../../BlogAuthor'
import { BlogImage } from '../TRABlog'
import BackgroundTopology from 'components/Shared/BackgroundTopology'
import topoimg from 'assets/images/background_topo.png'

BlogImage.propTypes = {
  src: PropTypes.any,
}

const SecurityMisconfigurationsBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'}>
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Top 10 Security Misconfigurations to Watch For in 2024
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">November 13, 2024</Box>
                <BlogAuthor name="Nick Roy" img={author} />
                <Box fontSize="12px">3 minute read</Box>
              </Flex>

              <Box mb="16px">
                As we approach the end of the year and everyone starts to
                publish their annual year in reviews and top 10 lists, we at
                Reach decided to join in on the tradition and highlight some of
                the most common security misconfigurations that we&apos;ve
                observed. Through collaboration among our teams, we&apos;ve
                identified the top 10 misconfigurations that often slip through
                the cracks and significantly impact an organization&apos;s
                security.
              </Box>

              <Box mb="16px">
                Below we&apos;ve outlined some of the most commonly missed or
                underutilized configurations, along with insights into why they
                matter and how addressing them can strengthen an
                organization&apos;s security posture.
              </Box>

              <OrderedList mb="32px" spacing={2}>
                <ListItem>No Max Session Requirements</ListItem>
                <ListItem>Blocking Abused or Unused Networks</ListItem>
                <ListItem>
                  Network Visibility (URL Filtering/SSL Decryption)
                </ListItem>
                <ListItem>Rule ordering on firewall</ListItem>
                <ListItem>Credential Phishing Detection</ListItem>
                <ListItem>MFA for Password Changes and Device Joins</ListItem>
                <ListItem>
                  Not Fully Leveraging Custom Detections (STAR/IOA Rules)
                </ListItem>
                <ListItem>Relying on Default Policies</ListItem>
                <ListItem>Attachment Blocking for Unsafe File Types</ListItem>
                <ListItem>Unsecured Web Directories</ListItem>
              </OrderedList>

              <Box mb="32px">
                While this post won&apos;t dive into every misconfiguration on
                the list, we&apos;ll highlight a few that are interesting from a
                defender&apos;s perspective. By addressing even a few of these
                items, organizations can further enhance their resilience
                against threats facing their organization.
              </Box>

              <Heading size="md" mb="16px">
                Attachment Blocking For Unused File Types
              </Heading>
              <Box mb="16px">
                Every security team has faced pushbacks on blocking specific
                file types at some point. Blocking well-known file types like
                .exe or .sh is often easy to justify, but other types such as
                .pdf may require additional checks to avoid interrupting
                business workflows. Recently, there&apos;s been a rise in
                spear-phishing attacks using RDP files, underscoring the need
                for consideration of policies blocking based on attachment
                types.
              </Box>

              <Box mb="32px">
                Reach helps by analyzing data from email security gateways to
                better understand the types of malicious emails being delivered
                to users at your organization. By using this data about the
                types of threats being sent to users and the associated
                malicious file types, Reach makes it easier for teams to
                prioritize attachment blocking decisions that best protect users
                and align with real-world threats.
              </Box>

              <BlogImage mb="32px" src={misconfig_summary} />

              <Heading size="md" mb="16px">
                Underutilized Custom Detections (STAR/IOA Rules)
              </Heading>
              <Box mb="16px">
                Configuring custom activity rules on your endpoint security
                tools provides better visibility into an attacker&apos;s intent
                and behavior and gives insight into threat patterns that
                provides added context outside of regular IOC&apos;s. However,
                it can be difficult to strike a proper balance as too many
                additional rules can overwhelm security teams with more work and
                more alerts to follow up on.
              </Box>

              <Box mb="32px">
                Reach simplifies this by ingesting attack data and recommending
                optimal configurations for endpoint tools, not just using the
                features of the platform but also providing other rules that can
                be actioned to identify and contain malicious behavior. This
                data-driven approach refines how these rules are applied and
                helps security teams correlate data across tools and enact
                defensive measures that address threats across the company.
              </Box>

              <BlogImage mb="32px" src={attachment_blocking} />

              <Heading size="md" mb="16px">
                Blocking Abused or Unused Networks
              </Heading>
              <Box mb="16px">
                Blocking malicious traffic from networks that do not have any
                business value is often overlooked but can significantly reduce
                the logs and alerts that a security team must manage. Although
                these attacks may come from less sophisticated actors, they
                create potential footholds for a group that can later sell
                access to a network to more sophisticated actors.
              </Box>

              <Box mb="32px">
                Reach simplifies this by identifying these networks by
                monitoring failed authentication attempts from untrusted sources
                and highlighting them. This visibility enables teams to take
                quick action and block potentially harmful networks before they
                become a greater risk.
              </Box>

              <BlogImage mb="32px" src={custom_detections} />

              <Heading size="md" mb="16px">
                No Max Session Requirements
              </Heading>
              <Box mb="16px">
                When deploying IAM policies, organizations often overlook
                options for more granular control over session limits. While
                many organizations set broad policies and perhaps a few for
                high-risk logins, additional controls are available to enhance
                the user experience and adjust session durations based on the
                specific risk level of each sign-in.
              </Box>

              <Box mb="32px">
                Reach assists by analyzing existing policies and providing
                insights on optimizing IAM configurations. For example, low-risk
                sign-ins could be granted additional access to productivity
                features like password resets which risky logins from new
                devices or countries might have shorter session limits set in
                order to mitigate any potential risks.
              </Box>

              <BlogImage mb="32px" src={network_blocking} />

              <Box mb="64px">
                If you&apos;re familiar with some of these misconfigurations and
                are looking for proactive ways to identify them and other areas
                for improvement, sign up for a free tools rationalization
                assessment to receive tailored recommendations.
              </Box>
            </Box>
          </ContentMaxWidth>
          <BackgroundTopology bgImage={topoimg} bgSize="cover" color="white">
            <ContentMaxWidth maxW="880px" py="80px" color="white" px="16px">
              <Box fontSize={isMobile ? '18px' : '20px'}>
                Reach is the first purpose-built platform that closes the gap
                between assessing your security posture and reducing your
                exposure through operational changes using configuration guides,
                workflows, and automation - all contextualized and prioritized
                to maximize the efficacy of your existing tech stack. To learn
                more, visit{' '}
                <Link
                  color="reachBlue.200"
                  as={DomLink}
                  fontWeight="bold"
                  to="/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </ContentMaxWidth>
          </BackgroundTopology>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default SecurityMisconfigurationsBlog
