import React from 'react'
import { Box, LightMode, Heading } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { useApp } from 'context/App'
import FadeIn from 'components/Shared/FadeIn'

const Investors = () => {
  const { isMobile } = useApp()
  return (
    <LightMode>
      <ContentMaxWidth
        maxW="1600px"
        display="flex"
        pb="96px"
        px={isMobile ? '16px' : '32px'}
        alignItems="flex-start"
        flexDir={isMobile ? 'column' : 'row'}
        gap={isMobile ? '8px' : '48px'}
      >
        <FadeIn>
          <Heading
            minW={isMobile ? undefined : '600px'}
            fontSize={isMobile ? '32px' : undefined}
            color="#111"
            mb="16px"
            textAlign={isMobile ? 'center' : 'left'}
          >
            Supported by the best at what they do.
          </Heading>
        </FadeIn>
        <FadeIn delay={0.3}>
          <Box fontSize="20px" textAlign="left">
            We’ve raised venture capital from Barmak Meftah at Ballistic
            Ventures, with participation by Artisanal Ventures, Webb Investment
            Network, Ridge Ventures, and TechOperators. Other backers include
            legendary security leaders Mark McLaughlin, Oliver Friedrichs, Geoff
            Belknap, Marshall Heilman, Denise Persson, and others we’re grateful
            to learn from every day.
          </Box>
        </FadeIn>
      </ContentMaxWidth>
    </LightMode>
  )
}

export default Investors
