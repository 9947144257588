import React, { useEffect } from 'react'
import { Box, Heading, LightMode, Link } from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'

const CrowdstrikePartnershipArticle = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading mb="16px" fontSize={isMobile ? '30px' : '36px'}>
                Reach Security Now Available for Purchase on CrowdStrike
                Marketplace
              </Heading>
              <Box mb="16px">
                Reach Security, the company pioneering advanced AI to reinvent
                security operations, today announced its listing in the{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://marketplace.crowdstrike.com/listings/reach-security-platform"
                >
                  CrowdStrike Marketplace
                </Link>
                , a premier technology marketplace and world-class ecosystem of
                third-party security products that integrate with CrowdStrike.
                The integration between Reach Security’s AI-driven enterprise
                security posture management platform and{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://www.crowdstrike.com/platform/"
                >
                  CrowdStrike’s industry-leading AI-native CrowdStrike Falcon
                  XDR platform
                </Link>{' '}
                provides organizations the ability to unlock the full potential
                of their CrowdStrike investment by tailoring preventative
                controls from licensed modules to their risk profile.
              </Box>

              <Box mb="16px">
                Reach integrates with the broader security estate, such as email
                security, network security, endpoint security, and identity and
                access management tools, and pulls threat and event metadata
                from point products to understand the types of threats targeting
                the workforce. Reach uses this data to model controls for the
                CrowdStrike Falcon XDR platform to ensure optimal prevention
                policies and rules are in place to address risk hotspots. This
                allows organizations to deliver the best possible security from
                their CrowdStrike investments and adapt security policies in
                real time to address shifts and nuances in risk distribution.
              </Box>

              <Box mb="16px">
                “At Reach, we’ve identified that on average, 97% of attacks
                delivered to an organization’s workforce are preventable using
                the tooling they have in place,” said Garrett Hamilton,
                Co-Founder and CEO of Reach. “Every other company in our
                industry will say that you need another security mousetrap to
                solve this problem. They&apos;re wrong. Together with
                CrowdStrike, we are helping enterprises measure and manage the
                value they’re getting from their Falcon XDR deployment by
                ensuring the best possible configurations to protect their
                workforce are in place at all times.”{' '}
              </Box>

              <Box mb="32px">
                CrowdStrike customers can now seamlessly purchase Reach Security
                for their CrowdStrike Falcon XDR Platform through the
                CrowdStrike marketplace.{' '}
              </Box>

              <Box fontSize="24px" fontWeight="bold" mb="16px">
                About Reach Security
              </Box>

              <Box>
                Reach Security is transforming the way companies use their
                security tools. With a focus on optimizing what teams already
                own rather than adding complexity with new tools, Reach is
                committed to simplifying cybersecurity, reducing operational
                costs, and enhancing protection against evolving threats.
                Founded by cybersecurity experts with venture backing from
                leading investors and industry luminaries, Reach Security is
                setting a new standard for cybersecurity efficiency and
                effectiveness. For more information, visit{' '}
                <Link fontWeight="bold" as={NavLink} to="/">
                  Reach Security
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default CrowdstrikePartnershipArticle
