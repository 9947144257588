import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  LightMode,
  Spacer,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import ReachLogo from 'components/Shared/ReachLogo'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useSwipeable } from 'react-swipeable'
import { useApp } from 'context/App'
import ContentMaxWidth from '../ContentMaxWidth'
import HoverMenu from '../HoverMenu'
// import { RESOURCES_VIEWS } from 'components/Resources/ResourcesBody'

const HeaderLink = ({ to, ...remainingProps }) => {
  const { isMobile, isWhiteHeader, headerTextColor } = useApp()

  const color =
    headerTextColor || (isWhiteHeader && !isMobile) ? 'gray.900' : 'white'

  return (
    <Box px="16px" textAlign="center">
      <NavLink
        key={`header-nav${to}-${isWhiteHeader}`}
        to={to}
        color={color}
        style={(isActive) => ({
          color,
          fontWeight: isActive ? '800' : '400',
          width: '100px',
          fontSize: isMobile ? '20px' : '18px',
        })}
        {...remainingProps}
      />
    </Box>
  )
}

HeaderLink.propTypes = {
  to: PropTypes.string,
}

const MobileDrawer = ({ isOpen, onClose }) => {
  const handlers = useSwipeable({
    onSwipedRight: onClose,
  })
  const history = useHistory()

  return (
    <Drawer size="xs" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody {...handlers} bg="black" color="white">
          <Stack mt="32px" spacing="16px">
            <HeaderLink p="16px" to="/home">
              Home
            </HeaderLink>
            <HeaderLink p="16px" to="/product">
              Product
            </HeaderLink>
            <HeaderLink to="/try-reach">Tools Rationalization</HeaderLink>
            <HeaderLink to="/company">Company</HeaderLink>
            <HeaderLink to="/careers">Careers</HeaderLink>
            <HeaderLink to="/resources">Resources</HeaderLink>
            <HeaderLink to="/terms">Terms</HeaderLink>
            {/* <HeaderLink to="/join-us">Join Us</HeaderLink> */}
            <LightMode>
              <Button onClick={() => history.push('/connect')}>
                Request Demo
              </Button>
            </LightMode>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

MobileDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

const PageHeader = ({ ...props }) => {
  const { isMobile, isWhiteHeader, headerBgColor, headerTextColor } = useApp()
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false })
  const history = useHistory()

  const bgColor = headerBgColor || (isWhiteHeader ? 'white' : 'reachBlue.600')
  const textColor = headerTextColor || (isWhiteHeader ? 'gray.900' : 'white')
  const borderColor = isWhiteHeader ? 'white' : 'reachBlue.500'
  const iconBg = isWhiteHeader ? 'white' : 'readhBlue.600'

  if (isMobile) {
    return (
      <>
        <Flex
          left="0"
          right="0"
          h="80px"
          bg={bgColor}
          position="absolute"
          top="0"
          alignItems="center"
          {...props}
        >
          <ReachLogo
            ml="12px"
            flexShrink={0}
            color={headerTextColor || (isWhiteHeader ? 'black' : 'white')}
          />
        </Flex>
        <Icon
          zIndex={10}
          w="64px"
          h="48px"
          position="absolute"
          right="16px"
          top="16px"
          cursor="pointer"
          onClick={onOpen}
          as={GiHamburgerMenu}
          color={textColor}
          bg={iconBg}
          borderRadius="8px"
          px="4px"
          {...props}
        />
        <MobileDrawer isOpen={isOpen} onClose={onClose} />
      </>
    )
  }

  return (
    <Box
      w="100vw"
      h="96px"
      bg={bgColor}
      borderBottom="1px solid"
      borderBottomColor={borderColor}
      position="absolute"
      top="0"
      color={isWhiteHeader ? 'gray.900' : 'white'}
      zIndex={10}
      {...props}
    >
      <ContentMaxWidth maxW="1600px" gap="8px">
        <ReachLogo
          mr="24px"
          flexShrink={0}
          color={isWhiteHeader ? 'black' : 'white'}
        />
        <HeaderLink to="/product">Product</HeaderLink>
        <HoverMenu
          buttonContents={
            <HeaderLink mr="8px" to="/company">
              Company
            </HeaderLink>
          }
          menuList={[
            { label: 'About', onClick: () => history.push('/company') },
            {
              label: 'Careers',
              onClick: () => history.push('/careers'),
            },
          ]}
        />
        <HoverMenu
          buttonContents={
            <HeaderLink mr="8px" to="/resources">
              Resources
            </HeaderLink>
          }
          menuList={[
            { label: 'News', onClick: () => history.push('/resources/news') },
            {
              label: 'Videos',
              onClick: () => history.push('/resources/videos'),
            },
            {
              label: 'Blog',
              onClick: () => history.push('/resources/blog'),
            },
            {
              label: 'Content Library',
              onClick: () => history.push('/resources/content-library'),
            },
            // {
            //   label: 'Happy Hour',
            //   onClick: () => history.push(RESOURCES_VIEWS.HAPPY_HOUR),
            // },
          ]}
        />
        {/* <HeaderLink to="/join-us">Join Us</HeaderLink> */}
        <Spacer />
        <LightMode>
          <Button onClick={() => history.push('/connect')}>Request Demo</Button>
        </LightMode>
      </ContentMaxWidth>
    </Box>
  )
}

export default PageHeader
