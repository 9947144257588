import React, { useEffect } from 'react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import ToolsAssessmentBody from './ToolsAssessmentBody'
import { useApp } from 'context/App'

const ToolsAssessment = () => {
  const { setHeaderBgColor } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <ToolsAssessmentBody />
      <PageFooter />
    </>
  )
}

export default ToolsAssessment
