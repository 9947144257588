import React from 'react'
import PropTypes from 'prop-types'
import { Box, LightMode, Heading, Flex, Center, Image } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import FadeIn from 'components/Shared/FadeIn'

import crowdstrikeImage from 'assets/images/product_crowdstrike.svg'
import paloAltoImage from 'assets/images/product_paloalto.svg'
import proofpointImage from 'assets/images/product_proofpoint.svg'
import s1Image from 'assets/images/product_sentinelone.svg'
import oktaImage from 'assets/images/product_okta.svg'
import azureAdImage from 'assets/images/azure-active-directory.svg'
import { useApp } from 'context/App'
import MotionBox from 'components/Shared/MotionBox'

const LogoItem = ({ image }) => {
  const { isMobile } = useApp()
  return (
    <Box w="100%" h={isMobile ? '120px' : '160px'} bg="white">
      <MotionBox h="100%">
        <Center w="100%" h="100%" p="32px">
          {image ? (
            <Image
              filter="grayscale(100%) contrast(7)"
              w="100%"
              maxW={isMobile ? '140px' : '100%'}
              src={image}
            />
          ) : (
            <AzureLogo />
          )}
        </Center>
      </MotionBox>
    </Box>
  )
}

LogoItem.propTypes = {
  image: PropTypes.string,
}

const AzureLogo = () => {
  const { isMobile } = useApp()
  return (
    <Flex alignItems="center" flexShrink={0}>
      <Image
        w={isMobile ? '32px' : '32px'}
        src={azureAdImage}
        mr={isMobile ? '4px' : '8px'}
      />
      <Box
        fontFamily="arial"
        lineHeight={1}
        fontSize="32px"
        letterSpacing={-0.2}
        color="#00bef2"
      >
        <Box fontSize="16px" ml="1px">
          Azure
        </Box>
        <Box fontSize={isMobile ? '14px' : '22px'}>Active Directory</Box>
      </Box>
    </Flex>
  )
}

const Integrations = () => {
  const { isMobile } = useApp()
  return (
    <LightMode>
      <ContentMaxWidth
        display="block"
        borderTop="1px solid"
        borderColor="#1a1a1a"
        pb="80px"
        px="0"
        mx="16px"
        flexDir={isMobile ? 'column' : 'row'}
      >
        <Heading textAlign="center" size="xl" pt="48px">
          Integrations
        </Heading>
        <Flex
          flexDir={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems="center"
          mt="8px"
        >
          <FadeIn
            baseDelay={0.5}
            flexShrink={0}
            w={isMobile ? '240px' : '240px'}
          >
            <LogoItem image={crowdstrikeImage} />
          </FadeIn>
          <FadeIn
            baseDelay={0.5}
            delay={0.15}
            flexShrink={0}
            w={isMobile ? '240px' : '240px'}
          >
            <LogoItem image={paloAltoImage} />
          </FadeIn>
          <FadeIn
            baseDelay={0.5}
            delay={0.3}
            flexShrink={0}
            w={isMobile ? '240px' : '240px'}
          >
            <LogoItem image={proofpointImage} />
          </FadeIn>
          <FadeIn
            baseDelay={0.5}
            delay={0.45}
            flexShrink={0}
            w={isMobile ? '240px' : '240px'}
          >
            <LogoItem image={s1Image} />
          </FadeIn>
          <FadeIn
            baseDelay={0.5}
            delay={0.6}
            flexShrink={0}
            w={isMobile ? 'auto' : '240px'}
          >
            {/* okta logo is way taller, so wrap it in a smaller div to match */}
            <Box w="170px">
              <LogoItem image={oktaImage} />
            </Box>
          </FadeIn>
        </Flex>
        <Center>
          <FadeIn delay={1.5}>
            <Box fontSize="20px" fontWeight="light" opacity={0.8}>
              (and more)
            </Box>
          </FadeIn>
        </Center>
      </ContentMaxWidth>
    </LightMode>
  )
}

export default Integrations
