import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Center, Icon } from '@chakra-ui/react'
import { AiOutlineArrowUp } from 'react-icons/ai'
import MotionBox from './MotionBox'

const BackToTop = ({ ...props }) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isVisible = scrollPosition > 150

  return (
    <MotionBox
      animate={{ scale: isVisible ? 1 : 0 }}
      position="fixed"
      right="40px"
      bottom="40px"
      zIndex={100}
    >
      <Center
        p="16px"
        borderRadius="50%"
        bg="reachBlue.500"
        border="1px solid white"
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
        cursor="pointer"
        {...props}
      >
        <Icon color="white" w="32px" h="32px" as={AiOutlineArrowUp} />
      </Center>
    </MotionBox>
  )
}
BackToTop.propTypes = {
  isVisible: PropTypes.bool,
}

export default BackToTop
