import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Flex,
  Box,
  Text,
  Input,
  Icon,
  Collapse,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import MotionBox from 'components/Shared/MotionBox'
import {
  ERROR_COLOR,
  ERROR_COLOR_DARK,
  FOCUSED_COLOR,
  FOCUSED_COLOR_DARK,
} from 'theme'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import debounce from 'lodash/debounce'

const FormInput = (props) => {
  const {
    mb,
    ml,
    mr,
    mt,
    m,
    label,
    register,
    name,
    id,
    options,
    debounceTime,
    onDebounce,
    onChange,
    onFocus,
    onBlur,
    errors,
    ...remaining
  } = props

  const [debounceFn] = useState(() =>
    debounce((value) => onDebounce?.(value), debounceTime)
  )
  const [focused, setFocused] = useState(false)
  const error = errors[name]
  const errorColor = mode(ERROR_COLOR, ERROR_COLOR_DARK)
  const focusColor = mode(FOCUSED_COLOR, FOCUSED_COLOR_DARK)

  let underlineBgColor = '#ffffff00'
  if (error) {
    underlineBgColor = errorColor
  } else if (focused) {
    underlineBgColor = focusColor
  }

  const {
    onChange: onRegisterChange,
    onFocus: onRegisterFocus,
    onBlur: onRegisterBlur,
    ...remainingRegister
  } = register?.(name, options) || {}

  return (
    <Box mb={mb} mt={mt} ml={ml} mr={mr} m={m}>
      <Text color={error ? errorColor : 'white'} fontWeight="medium" mb="8px">
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={id || name}>{label}</label>
      </Text>
      <Box position="relative">
        <Input
          color="white"
          id={id || name}
          onFocus={(e) => {
            onFocus?.(e)
            onRegisterFocus?.(e)
            setFocused(true)
          }}
          onBlur={(e) => {
            onBlur?.(e)
            onRegisterBlur?.(e)
            setFocused(false)
          }}
          onChange={(e) => {
            onChange?.(e)
            debounceFn?.(e?.target.value)
            onRegisterChange?.(e)
          }}
          {...remainingRegister}
          {...remaining}
        />
        <MotionBox
          transition={{ duration: 0.2 }}
          animate={{ background: underlineBgColor }}
          initial={{ background: '#ffffff00' }}
          h="6px"
          borderBottomRadius="3px"
          position="absolute"
          transform="perspective(800px) rotate3d(-1, 0, 0, 45deg) scaleX(.997) translateY(1px)"
          bottom="0"
          left="0"
          w="100%"
        />
      </Box>
      <Collapse in={error} animateOpacity>
        <Flex pt="2px" alignItems="center">
          <Icon mr="8px" color={errorColor} as={AiOutlineInfoCircle} />
          <Text fontSize="14px" fontWeight="light" color={errorColor}>
            {error?.message}
          </Text>
        </Flex>
      </Collapse>
    </Box>
  )
}

FormInput.defaultProps = {
  options: {},
  errors: {},
  mb: '24px',
  debounceTime: 500,
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.object,
  errors: PropTypes.object,
  register: PropTypes.func,
  debounceTime: PropTypes.number,
  onDebounce: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default FormInput
