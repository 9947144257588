import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import MotionBox from './MotionBox'

const MotionBoxInView = ({
  inViewOptions,
  onInView,
  onOutOfView,
  animateIn,
  animateOut,
  isAlwaysInView,
  ...remainingProps
}) => {
  const { ref, inView } = useInView(inViewOptions)

  useEffect(() => {
    if (inView) {
      onInView?.()
    } else {
      onOutOfView?.()
    }
  }, [inView])

  return (
    <MotionBox
      animate={inView || isAlwaysInView ? animateIn : animateOut}
      initial={animateOut}
      ref={ref}
      {...remainingProps}
    />
  )
}

MotionBoxInView.defaultProps = {
  animateIn: { opacity: 1 },
  animateOut: { opacity: 0.1 },
  inViewOptions: { threshold: 0.6 },
  isAlwaysInView: false,
}

MotionBoxInView.propTypes = {
  animateIn: PropTypes.object,
  animateOut: PropTypes.object,
  inViewOptions: PropTypes.object,
  onInView: PropTypes.func,
  onOutOfView: PropTypes.func,
  isAlwaysInView: PropTypes.bool,
}
export default MotionBoxInView
