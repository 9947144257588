import React, { useEffect, useRef } from 'react'
import {
  Box,
  Image,
  VStack,
  LightMode,
  Icon,
  Center,
  Flex,
  Link,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react'
import { useApp } from 'context/App'
import logoimage from '../images/SSOHardening/topography_wide.png'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import BackgroundTopology from 'components/Shared/BackgroundTopology'
import topoimg from 'assets/images/background_topo.png'
// import headshot from '../images/padlock.jpeg'
import { BsMouse } from 'react-icons/bs'
import BackToTop from 'components/Shared/BackToTop'
import oktaControl1 from '../images/SSOHardening/okta_rule_1.png'
import oktaControl2 from '../images/SSOHardening/okta_rule_2.png'
import oktaControl3 from '../images/SSOHardening/okta_rule_3.png'

const MAX_W = '1100px'

const B = ({ ...props }) => (
  <Box display="inline-block" fontWeight="medium" {...props} />
)

const SSOHardening = () => {
  const { setIsWhiteHeader, isMobile } = useApp()

  const ref0 = useRef(null)
  const ref1 = useRef(null)
  const ref2 = useRef(null)

  const mainFontSize = isMobile ? '20px' : '28px'
  const largeFontSize = isMobile ? '24px' : '32px'
  const headerFontSize = isMobile ? '32px' : '48px'
  const headerFontSizeSmall = isMobile ? '28px' : '40px'

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsWhiteHeader(true)
    return () => setIsWhiteHeader(false)
  }, [])
  return (
    <Box>
      <BackToTop />
      <LightMode>
        <Box
          pt="128px"
          bg="white"
          h={isMobile ? 'auto' : '100vh'}
          color="black"
        >
          <ContentMaxWidth maxW="1400px" flexDir="column">
            <Flex alignItems="flex-start" flexDir={isMobile ? 'column' : 'row'}>
              <Box>
                <Image
                  borderRadius="16px"
                  h="96px"
                  mb={isMobile ? '48px' : '32px'}
                  src={logoimage}
                />
                <Box position="relative">
                  <Box
                    lineHeight={1.4}
                    fontSize={isMobile ? '24px' : '48px'}
                    fontWeight="light"
                    maxW="800px"
                  >
                    Assume Credential Compromise: Implementing Layered
                    Protections using Okta
                  </Box>
                </Box>
              </Box>
              <VStack
                flexShrink={0}
                spacing="32px"
                ml={isMobile ? '0' : '40px'}
                mt={isMobile ? '32px' : '56px'}
                mb={isMobile && '72px'}
                color="reachBlue.500"
                alignItems="flex-start"
              >
                <Box>
                  <Link
                    onClick={() =>
                      ref0.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                  >
                    Quintessential business enabler
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    and security blindspot
                  </Box>
                </Box>

                <Box display="block">
                  <Link
                    onClick={() =>
                      ref1.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                    whiteSpace="break-spaces"
                  >
                    Innovative SSO hardening
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    Driven by data
                  </Box>
                </Box>

                <Box>
                  <Link
                    onClick={() =>
                      ref2.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                  >
                    Easily translate threat activity
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    to configurations
                  </Box>
                </Box>
              </VStack>
            </Flex>
            {!isMobile && (
              <Center mt="128px" flexDir="column">
                <Icon
                  mb="4px"
                  w="32px"
                  h="32px"
                  color="gray.600"
                  as={BsMouse}
                />
                <Box fontSize="14px" color="gray.600">
                  Scroll down
                </Box>
              </Center>
            )}
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <Box>
        <BackgroundTopology
          bgImage={topoimg}
          bgSize="cover"
          pt="128px"
          pb="128px"
          color="white"
        >
          <ContentMaxWidth
            maxW={MAX_W}
            flexDir="column"
            alignItems="flex-start"
            lineHeight={1.6}
          >
            <Box fontSize={headerFontSize} mb="32px">
              Introduction
            </Box>
            <Box fontSize="26px" fontWeight="light" mb="16px">
              Multi-factor Authentication (MFA) and Single Sign-On (SSO).
              There’s no set of security controls on the market that are
              presented as a “silver bullet” more than this pair. Vendors will
              tell you to enable these capabilities because they will prevent
              the impact of credentials being phished. But, in almost all cases,
              that isn’t true.
            </Box>
            <Box fontSize="26px" fontWeight="light" mb="40px">
              What these vendor recommendations represent are the bare minimum
              to mitigate the act of credential compromise, not limit the impact
              of events after credentials are in the hands of a threat actor.
              Taking a threat-informed approach to hardening MFA and SSO
              defenses will move your organization from bare minimum to
              effective coverage tailored to you.
            </Box>
          </ContentMaxWidth>
        </BackgroundTopology>
      </Box>

      {/* Text Quote Section 2 */}
      <Box
        ref={ref0}
        bg="white"
        color="black"
        pt="120px"
        pb="120px"
        fontSize={mainFontSize}
        fontWeight="light"
      >
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="8px" fontSize={headerFontSize} fontWeight="medium">
            Quintessential business enabler
          </Box>
          <Box mb="48px" fontSize={headerFontSizeSmall} fontWeight="medium">
            (and security blindspot)
          </Box>
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            SSO has turned into a top business enabler across organizations of
            all shapes and sizes. When IT Apps teams around the globe enable SSO
            through a vendor like Okta, they’ve done their job. Authentication
            is streamlined and access to critical apps is handled in a simple
            and secure fashion.
          </Box>
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            The value for the business is clear; but how can security be
            thoughtful about hardening SSO when authentication is typically
            owned by IT? Ownership introduces a clear blindspot for security,
            and Reach has found that there are no shortage of capabilities and
            configurations that go unused.
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* GRAY BIG TEXT SECTION */}

      <Box ref={ref1} bg="gray.100" color="black" pt="128px" pb="64px">
        <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
          <Box>
            <Box
              color="gray.900"
              fontSize={headerFontSize}
              fontWeight="medium"
              mb="24px"
            >
              Data-driven SSO hardening: A compromise
            </Box>
            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="32px"
            >
              The way in which you configure security on top of your identity
              product should not be a one-size-fits-all approach. Rather, it
              should be informed by data derived from the threats targeting your
              organization.
            </Box>

            <Box
              color="gray.900"
              fontSize={largeFontSize}
              fontWeight="medium"
              mb="16px"
            >
              The first element - using threat data to inform control
              definitions
            </Box>

            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="16px"
            >
              Threat intelligence and sandbox forensics from attack logs
              contained within your existing security stack (email, endpoint,
              firewall) can inform you of elements like:
            </Box>

            <UnorderedList
              spacing="16px"
              ml={isMobile ? '24px' : '72px'}
              mb="48px"
              fontSize={mainFontSize}
              fontWeight="light"
            >
              <ListItem>
                Severity of attack (what are they going after?)
              </ListItem>
              <ListItem>
                Target of the attack (who are they after, and with what
                frequency?)
              </ListItem>
              <ListItem>
                Make up of the attack (what process, tactics, or techniques are
                used, and what’s the end goal?)
              </ListItem>
              <ListItem>
                Relevancy of the attack (are they using G-suite phishing
                techniques when you’re a Microsoft shop?)
              </ListItem>
            </UnorderedList>

            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="16px"
            >
              Other data points derived from attack logs that can be translated
              to hardened sign-on policies include:
            </Box>

            <UnorderedList
              spacing="16px"
              ml={isMobile ? '24px' : '72px'}
              mb="24px"
              fontSize={mainFontSize}
              fontWeight="light"
            >
              <ListItem>
                Are the source of these attacks from cloud gateways that are
                unsanctioned in your environment?
              </ListItem>
              <ListItem>
                Are they launched from unknown VPNs or TOR proxies?
              </ListItem>
              <ListItem>
                Are they stemming from countries where you don’t do business?
              </ListItem>
            </UnorderedList>

            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="48px"
            >
              Even without looking at your own data, the answers to at least a
              few of these questions are likely clear. In that case, threat data
              coupled with authentication can help you determine security value
              vs. end-user friction.
            </Box>

            <Box
              color="gray.900"
              fontSize={largeFontSize}
              fontWeight="medium"
              mb="16px"
            >
              The second element: figuring out where risk lives in the workforce
            </Box>

            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="16px"
            >
              While security would like to employ more stringent controls for
              all employees, it’s not realistic. Aligning on who the most
              attacked users are throughout the organization can help boost
              security without disruption. By joining threat data with people
              data and understanding who is more susceptible to
              credential-compromising attacks, IT can introduce sign-on friction
              in the event of anomalous log-in behavior to a minority of the
              population that contains a majority of the risk.
            </Box>

            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="16px"
            >
              The result? The kind of compromise that enables the business while
              enhancing security across the board.
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      <Box ref={ref2} />
      <Box
        bg="white"
        color="black"
        pt="120px"
        pb="120px"
        fontSize={mainFontSize}
        fontWeight="light"
      >
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="8px" fontSize={headerFontSize} fontWeight="medium">
            Easily translate threats to configurations
          </Box>

          <Box mb="48px" fontSize={mainFontSize} fontWeight="light">
            Here are some examples of hardened sign-on policies informed by
            threat data that you can implement in Okta to lessen the impact of
            credential compromise.
          </Box>

          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            <B>Threat:</B> Engineering personnel with access to developer
            environments account for 78% of phishing attacks seen in the last 90
            days.
          </Box>
          <Box mb="32px" fontSize={mainFontSize} fontWeight="light">
            <B>Control:</B> Always require MFA when accessing developer
            environments in AWS.
          </Box>

          <Center w="100%">
            <Box
              overflow="hidden"
              borderRadius="24px"
              border="1px solid"
              borderColor="gray.200"
              mb="64px"
              h="220px"
              pl="10px"
              pr="12px"
              boxShadow="0 6px 24px rgba(0,0,0,.05)"
            >
              <Image h="100%" transform="scale(1.1)" src={oktaControl1} />
            </Box>
          </Center>

          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            <B>Threat:</B> 4% of users are observed to account for 84% of
            phishing risk based on clicks made on phishing links. Phishing links
            redirected users to servers hosted in risky cloud providers.
          </Box>
          <Box mb="32px" fontSize={mainFontSize} fontWeight="light">
            <B>Control:</B> Implement a shortened session timeout (1 minute) if
            log-in behavior stems from a new country or region not seen before
            and access is requested from a source IP resolving to Alibaba’s
            public cloud.
          </Box>

          <Center w="100%">
            <Box
              overflow="hidden"
              borderRadius="24px"
              border="1px solid"
              borderColor="gray.200"
              mb="64px"
              h="220px"
              px="16px"
              boxShadow="0 6px 24px rgba(0,0,0,.05)"
            >
              <Image h="100%" transform="scale(1.1)" src={oktaControl2} />
            </Box>
          </Center>

          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            <B>Threat:</B> 4% of users are observed to account for 84% of
            phishing risk based on clicks made on phishing links. Phishing links
            redirected users to servers hosted in Alibaba cloud.
          </Box>
          <Box mb="32px" fontSize={mainFontSize} fontWeight="light">
            <B>Control:</B> Implement a shortened session timeout (1 minute) if
            anomalous behavior is observed, like logging in from a new country
            or device and logging in from untrusted VPN proxies like third-party
            VPNs or TOR exit nodes.
          </Box>

          <Center w="100%">
            <Box
              overflow="hidden"
              borderRadius="24px"
              border="1px solid"
              borderColor="gray.200"
              mb="64px"
              h="220px"
              px="16px"
              boxShadow="0 6px 24px rgba(0,0,0,.05)"
            >
              <Image h="100%" transform="scale(1.1)" src={oktaControl3} />
            </Box>
          </Center>

          <Box mb="64px" fontSize={largeFontSize} fontWeight="light">
            Reach connects to your existing security products and provides this
            translation at scale for your entire organization. If you’re
            interested in learning more, contact us at{' '}
            <B>info@reach.security</B>.
          </Box>
        </ContentMaxWidth>
      </Box>
    </Box>
  )
}

export default SSOHardening
