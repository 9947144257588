import React from 'react'
import { Box, LightMode, Heading, Center } from '@chakra-ui/react'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import FadeIn from 'components/Shared/FadeIn'
import HubspotForm from 'react-hubspot-form'

const HUBSPOT_FORM_ID = '491dc3c1-c070-4609-8c77-c715190ec5a7'
const HUBSPOT_PORTAL_ID = '21999761'

const StayInTouch = () => {
  const { isMobile } = useApp()

  return (
    <LightMode>
      <Box bg="gray.50">
        <ContentMaxWidth
          display="block"
          py={isMobile ? '80px' : '100px'}
          justifyContent="center"
          color="#1a1a1a"
          bg="black"
          maxW="1080px"
        >
          <FadeIn>
            <Box color="white" p={isMobile ? '48px' : '64px'}>
              <Center>
                <Box>
                  <FadeIn>
                    <Heading
                      letterSpacing={-2}
                      textAlign="center"
                      size="2xl"
                      mb="8px"
                      pb="16px"
                      borderBottom="1px solid #111"
                    >
                      Stay in touch.
                    </Heading>
                  </FadeIn>
                  <FadeIn delay={0.3}>
                    <Box
                      fontSize="26px"
                      letterSpacing={-0.75}
                      fontWeight="normal"
                      textAlign="center"
                      pb="32px"
                    >
                      Sign up to get the latest news about Reach and our
                      products.
                    </Box>
                  </FadeIn>
                </Box>
              </Center>

              <FadeIn delay={0.6}>
                <HubspotForm
                  portalId={HUBSPOT_PORTAL_ID}
                  formId={HUBSPOT_FORM_ID}
                  loading={<div>Loading...</div>}
                />
              </FadeIn>
            </Box>
          </FadeIn>
        </ContentMaxWidth>
      </Box>
    </LightMode>
  )
}

export default StayInTouch
