import React, { useEffect } from 'react'
import { Box, Flex, Heading, LightMode, Link } from '@chakra-ui/react'
import zach from '../AuthorImages/ZachMarks.png'
import { useApp } from 'context/App'
import BlogAuthor from '../../BlogAuthor'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { BlogImage } from '../TRABlog'
import caTra1 from './CA_TRA1.png'
import caTra2 from './CA_TRA2.png'
import cap1p2 from './CA_P1_P2.png'
import { Link as DomLink } from 'react-router-dom'
import BackgroundTopology from 'components/Shared/BackgroundTopology'
import topoimg from 'assets/images/background_topo.png'

const MicrosoftLicensingBlog = () => {
  const { isMobile, setHeaderBgColor } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box bg="#fff" minH="100vh" pt={isMobile ? '120px' : '184px'}>
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                textAlign="center"
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
              >
                Platformizing: Navigating Microsoft E3 vs. E5 Licenses for
                Security
              </Heading>

              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">November 6, 2024</Box>
                <BlogAuthor name="Zach Marks" img={zach} />
                <Box fontSize="12px">4 minute read</Box>
              </Flex>

              <Box mt="16px" mb="16px">
                Upgrading Microsoft enterprise licensing from E3 to E5 or P1 to
                P2? Whether your company is upgrading or considering the move,
                you may be facing questions about how to leverage this shift for
                your security team&apos;s advantage. Platformizing is all the
                buzz in 2024, and Microsoft is often central to these efforts
                due to its wide range of capabilities across both E3 and E5
                licenses. However, understanding how to best utilize features
                and capabilities from these licenses requires experts, time, and
                resources.
              </Box>

              <Box mb="16px">
                While E3 provides strong foundations, E5 offers advanced
                functionality helping customers on their journey to address
                sophisticated threats. The challenge lies in understanding the
                tangible value of having these advanced capabilities in place.
                What benefits would they offer? How could they reduce risk
                exposure, and by what measurable amount?{' '}
              </Box>

              <Box mb="32px">
                For example, upgrading to E5 can likely improve security, but
                realized value from doing so should be informed by the impact
                these features would have on reducing risk. To put it simply,
                the key to making the right decision with your Microsoft
                licensing lies in having the right data, and that requires
                context from other parts of your network.
              </Box>

              <Box mb="16px">
                <Heading size="md">
                  A Practical Approach: Start with Current Capabilities
                </Heading>
                <br />A great starting point in understanding what license(s)
                make sense is to assess your existing capabilities to understand
                what risks are covered and where there are potential gaps. This
                approach not only puts you on the path to optimizing the
                existing Microsoft deployment but can also highlight areas where
                future upgrades may be beneficial. For organizations using a
                hybrid E3/E5 setup, understanding the nuances of what&apos;s
                included in each license and how they can be applied to your
                workforce is pivotal. While both licenses come with robust
                security features, tapping into their full value requires a true
                understanding of the environment as a whole.{' '}
              </Box>

              <Box mb="32px">
                For example, you may want to deploy the subset of E5 licenses
                that you have to users that are disproportionately attacked to
                help mitigate the risk they represent relative to the general
                population. This is only possible by looking at other data
                sources within the network. Making informed decisions like this
                requires more than just following best practices—it calls for
                deep insights into your environment to manage them effectively.
                A platform like Reach, which offers risk-based insights on how
                specific license features can mitigate threats, provides the
                crucial data needed to determine if upgrading licenses is the
                right choice.
              </Box>

              <Box my="32px">
                <BlogImage src={cap1p2} />
                <Box fontStyle="italic" fontSize="16px" mt="8px">
                  Understand how features in Entra ID Plan 1 (E3) or Entra ID
                  Plan 2 (E5) can be utilized in Conditional Access policies to
                  mitigate risk in your environment.
                </Box>
              </Box>

              <Box my="32px">
                <BlogImage src={caTra1} />
                <Box fontStyle="italic" fontSize="16px" mt="8px">
                  Drill into how capabilities like User Actions, included in
                  Entra ID Plan 1, can mitigate risk.
                </Box>
              </Box>

              <Box mb="32px">
                <Heading size="md">
                  Using Data to Drive E5 Upgrade Decisions
                </Heading>
                <br />
                For organizations looking to evaluate and potentially upgrade to
                E5, Reach offers a front row seat into the potential impact of
                the advanced security features included in the suite.
                Reach&apos;s platform ingests data to understand unique threats
                facing your environment and assesses your current security
                controls to identify where and how E5&apos;s capabilities - such
                as Threat Explorer in Microsoft Defender for O365 or Risk-Based
                Conditional Access - would make an impact. It translates attacks
                targeting your users into insights, showing you exactly how
                E5&apos;s enhanced features can reduce risks that might
                otherwise go unnoticed.
              </Box>

              <Box my="32px">
                <BlogImage src={caTra2} />
                <Box fontStyle="italic" fontSize="16px" mt="8px">
                  Reach curates policies to help you understand how advanced
                  features, such as Sign-in Risk, could be utilized in policies
                  to mitigate risk. This example highlights a policy that uses
                  Sign-in Risk levels to shorten the session time of users that
                  are disproportionately attacked relative to the rest of the
                  population.
                </Box>
              </Box>

              <Box mb="32px">
                This data-driven approach allows security leaders to see the
                specific benefits of an E5 upgrade in their environment, giving
                them comfort when making a purchasing decision. Instead of
                upgrading based on general assumptions, Reach ensures that the
                decision to move to E5 is based on real, measurable security
                needs. This not only helps back the investment with data but
                also ensures that once the upgrade is made, the organization is
                set up to fully leverage the advanced tools included in the E5
                license.
              </Box>

              <Box mb="32px">
                <Heading size="md">
                  Conclusion: Reach Empowers Data-Driven Security Decisions
                </Heading>
                <br />
                Choosing between Microsoft E3 and E5 licenses is not a decision
                to take lightly. Both offer strong security foundations, but
                understanding how these capabilities fit into your
                organization&apos;s specific threat landscape requires detailed
                insights. The key is to use data to make informed decisions
                about which license, or combination of licenses, is right for
                you. Reach provides that clarity by delivering deep insights
                into your current security posture, highlighting gaps that could
                be addressed with E5 features, and ensuring that all
                tools—whether E3 or E5—are used to their full potential. With
                Reach, organizations can confidently make decisions about
                whether an E5 upgrade makes sense, ensuring that every
                investment in security is both strategic and effective.
              </Box>
            </Box>
          </ContentMaxWidth>
          <BackgroundTopology bgImage={topoimg} bgSize="cover" color="white">
            <ContentMaxWidth maxW="880px" py="80px" color="white" px="16px">
              <Box fontSize={isMobile ? '18px' : '20px'}>
                Reach is the first purpose-built platform that closes the gap
                between assessing your security posture and reducing your
                exposure through operational changes using configuration guides,
                workflows, and automation - all contextualized and prioritized
                to maximize the efficacy of your existing tech stack. To learn
                more, visit{' '}
                <Link
                  color="reachBlue.200"
                  as={DomLink}
                  fontWeight="bold"
                  to="/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </ContentMaxWidth>
          </BackgroundTopology>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default MicrosoftLicensingBlog
