/* eslint-disable react/no-unescaped-entities */

import React, { useEffect } from 'react'
import PageHeader from 'components/Shared/PageHeader'
import {
  Box,
  Flex,
  Heading,
  LightMode,
  Link,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { useApp } from 'context/App'
import BlogAuthor from '../../BlogAuthor'
import zach from '../AuthorImages/ZachMarks.png'
import rewards from './rewards.png'
import deployment from './deployment_group.png'
import issueTracking from './issue_tracking.png'
import access from './access.png'
import azure1 from './azure_1.png'
import azure2 from './azure_2.png'
import stageContent from './stage_content.png'
import { BlogImage } from '../TRABlog'
import PageFooter from 'components/Shared/PageFooter'

const SecToolsBlog = () => {
  const { isMobile, setHeaderBgColor } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box bg="#fff" minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                textAlign="center"
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
              >
                Security Tools Optimization Spotlight: Microsoft Entra ID
                Conditional Access
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">June 18, 2024</Box>
                <BlogAuthor name="Zach Marks" img={zach} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mt="16px" mb="32px">
                <Heading size="md">
                  Ready or Not: Credential Compromise is Coming in 2024{' '}
                </Heading>
                <br />
                According to Google Cloud’s 2023 Threat Horizon report, an
                astounding 86% of breaches involve stolen credentials. Earlier
                this year, Midnight Blizzard—a Russian-state sponsored threat
                actor—compromised high-profile email accounts, using them as a
                springboard to exfiltrate sensitive data. Just last week,
                Mandiant reported that data was exfiltrated from Snowflake
                customer instances in an attack by threat actor UNC5537 that
                originated from compromised customer credentials. It’s time we
                change our perspective on identity-based attacks and credential
                compromise from “if it happens" to “when it happens”.
              </Box>
              <Box mb="32px">
                <Heading size="md">
                  Security Tools Optimization with Microsoft Entra ID
                  Conditional Access
                </Heading>
                <br />
                Enter Microsoft Entra ID Conditional Access, a quintessential
                business enabler often relegated to an afterthought in security
                controls management. It enables IT to streamline business
                operations through SSO and app access. Yet, rich security
                authentication attributes frequently remain underutilized.
                <Box fontStyle="italic" fontSize="16px" mt="16px" mb="16px">
                  (Reader Note: While we focus on Microsoft Entra ID Conditional
                  Access here, it’s important to recognize that similar
                  considerations apply to other leading tools in the identity
                  management space, such as Okta Adaptive MFA and SSO, Duo
                  Security, ForgeRock, and Ping Identity. Each of these
                  platforms offers unique features that enhance security and
                  streamline access management, and they can be optimized in
                  much the same way.)
                </Box>
                To enhance your IAM security posture with Conditional Access,
                it’s crucial to fully understand the capabilities you’re
                licensed for, identify what’s currently being utilized, and
                leverage untapped features to mitigate risks.
              </Box>
              <Box>
                <Heading size="md">
                  Step 1: Understand Your Licensed Capabilities
                </Heading>
                <br />
                Entra ID provides a range of sign-on attributes under both the
                P1 and P2 licenses, essential for mitigating credential
                compromise risks:
                <Box mt="16px">
                  <Heading size="sm" mb="8px">
                    Examples of P1 Features:
                  </Heading>
                  <UnorderedList>
                    <UnorderedList>
                      <ListItem>
                        <b>Session Controls:</b> Reduce the default session time
                        for added security.
                      </ListItem>
                      <ListItem>
                        <b>Named Locations:</b> Implement policies based on the
                        geolocation of sign-in attempts.
                      </ListItem>
                      <ListItem>
                        <b>User Actions:</b> Enforce MFA checks following
                        specific user actions, such as device registration.
                      </ListItem>
                      <ListItem>
                        <b>Phishing-Resistant MFA:</b> Equip high-profile users,
                        like administrators, with phishing-resistant tools such
                        as YubiKeys.
                      </ListItem>
                    </UnorderedList>
                  </UnorderedList>
                </Box>
                <Box mt="16px">
                  <Heading size="sm" mb="8px">
                    Examples of P2 Enhancements:
                  </Heading>
                  <UnorderedList>
                    <UnorderedList>
                      <ListItem fontWeight="bold">
                        Includes all P1 features, plus:{' '}
                      </ListItem>
                      <UnorderedList>
                        <ListItem>
                          <b>User Risk:</b> Assessed by Microsoft's threat
                          intelligence to indicate the likelihood of identity
                          compromise.{' '}
                        </ListItem>
                        <ListItem>
                          <b>Sign-in Risk:</b> Also defined by Microsoft,
                          indicating the authenticity of sign-in attempts.
                        </ListItem>
                      </UnorderedList>
                    </UnorderedList>
                  </UnorderedList>
                </Box>
              </Box>
              <Box>
                <Heading size="md" mt="32px">
                  Step 2: Model Licensed Capabilities to Address Risk Hotspots{' '}
                </Heading>
                <br />
                After assessing available features, focus on those with the
                greatest potential to mitigate risks. This understanding can
                help advocate for the deployment of specific capabilities to IT
                or justify ROI from a risk reduction standpoint when considering
                license upgrades (e.g. P1 to P2).
              </Box>
              <Box>
                <Heading size="md" mt="32px">
                  Step 3: Construct and Deploy Risk-Mitigating Sign-on Policies
                </Heading>
                <br />
                Consider a scenario with a P2 licensed organization aiming to
                block high risk sign-ins for users that are disproportionately
                attacked, but only if their login originates from an unmanaged
                device. Steps include:
                <Box mt="16px" mb="16px">
                  <UnorderedList>
                    <ListItem>
                      Identify high-risk users by analyzing varied security data
                      sources like email, EDR, IAM and network logs.{' '}
                    </ListItem>
                    <ListItem>
                      Monitor these users within Entra, noting changes over
                      time.
                    </ListItem>
                    <ListItem>
                      Implement a custom, risk-based sign-on policy for these
                      users.
                    </ListItem>
                  </UnorderedList>
                </Box>
                After taking care of steps 1 and 2 (covered in our{' '}
                <Link href="/resources/blog/identity_centric_tools">
                  identity-centric approach to Tools Rationalization blog
                </Link>
                ) we can use automation capabilities in Reach Quests to action
                on deployment.
              </Box>
              <BlogImage src={rewards} />
              <Box fontStyle="italic" fontSize="16px" mb="64px">
                Create a sign-on policy that blocks high risk sign-ins from
                untrusted devices.
              </Box>
              <BlogImage src={deployment} />
              <Box fontStyle="italic" fontSize="16px" mb="64px">
                Apply to the ~4% of our knowledge workers that account for 80%
                of our risk.
              </Box>
              <BlogImage src={issueTracking} />
              <Box fontStyle="italic" fontSize="16px" mb="64px">
                Track in ticketing system with a change guide.
              </Box>
              <BlogImage src={stageContent} />
              <Box fontStyle="italic" fontSize="16px" mb="64px">
                Stage configurations as default ”off” to test or preview
                instance after approvals are given.
              </Box>
              <BlogImage src={azure1} />
              <Box fontStyle="italic" fontSize="16px" mb="64px">
                Review staged policy within Conditional Access.
              </Box>
              <BlogImage src={azure2} />
              <Box fontStyle="italic" fontSize="16px" mb="64px">
                Flip to ”on” and take advantage of risk/contextual based sign-in
                attributes included in your license.
              </Box>
              <BlogImage src={access} />
              <Box fontStyle="italic" fontSize="16px" mb="64px">
                Track progress and report upward on capability consumption,
                utilization, and risk reduction value realized.
              </Box>
              <Box mt="16px" mb="32px">
                <Heading size="md">
                  Conclusion: Assume Credential Compromise
                </Heading>
                <br />
                Conditional Access contains simple yet powerful sign-on
                attributes that can be tailored to your business with a
                risk-based approach. Whether you're undergoing security tool
                consolidation efforts or embarking on an IAM hardening project,
                consider starting with a series of tailored sign-on policies
                that address risk where most breaches start.
              </Box>
              <Heading size="md">More About Reach Security</Heading>
              <br />
              <Box>
                Reach is a leader in Automated Security Control Assessment
                (ASCA) with its purpose-built AI-driven platform to reprogram
                your security infrastructure based on who you are and how you’re
                attacked. Going beyond traditional security assessments, Reach
                provides actionable insights and seamless integrations that
                transform findings into real-world defenses. By emphasizing the
                'last mile' of security, Reach ensures that organizations get
                the best protection possible from the tools they already own.
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default SecToolsBlog
