import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const Agreement = ({ markdown }) => {
  const [md, setMd] = useState(null)
  useEffect(() => {
    fetch(markdown)
      .then((response) => response.text())
      .then((text) => {
        setMd(text)
      })
  }, [])
  if (!md) return null
  return <ReactMarkdown remarkPlugins={[remarkGfm]}>{md}</ReactMarkdown>
}

Agreement.propTypes = {
  markdown: PropTypes.any,
}

export default Agreement
