import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'

const WhiteButton = ({ onClick, ...props }) => (
  <Button
    _hover={{ background: 'gray.200' }}
    _active={{ background: 'gray.400' }}
    background="white"
    onClick={onClick}
    {...props}
  />
)

WhiteButton.propTypes = {
  onClick: PropTypes.func,
}

export default WhiteButton
