import React, { useState } from 'react'
import { Box, Flex, Heading, ListItem, UnorderedList } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { useApp } from 'context/App'
import { Widget } from '@typeform/embed-react'
import FadeIn from 'components/Shared/FadeIn'
import MotionBoxInView from 'components/Shared/MotionBoxInView'
import ReactPlayer from 'react-player'
import B from 'components/Shared/B'
import { Blurb } from 'components/Home/WhatWeDo'

const TYPEFORM_UUID = 'Gh8mrh9u'

const SplashArea = () => {
  const { isMobile } = useApp()
  const [isVideo3InView, setIsVideo3InView] = useState(false)
  const unorderedListBody = (
    <UnorderedList>
      <FadeIn delay={0.2}>
        <ListItem>
          <B>Automated Assessments</B>: Save time and effort.
        </ListItem>
      </FadeIn>
      <FadeIn delay={0.4}>
        <ListItem>
          <B>Actionable Insights</B>: Get deployable results tailored to your
          needs.
        </ListItem>
      </FadeIn>
      <FadeIn delay={0.6}>
        <ListItem>
          <B>Prove ROI</B>: Quantify the impact of your security investments.
        </ListItem>
      </FadeIn>
      <FadeIn delay={0.8}>
        <ListItem>
          <B>Build Trust</B>: Enhance confidence in your security posture
        </ListItem>
      </FadeIn>
    </UnorderedList>
  )
  return (
    <>
      <Box
        minH={isMobile ? 'auto' : '44vh'}
        h={isMobile ? 'auto' : '800px'}
        pt={isMobile ? '120px' : '24px'}
        color="white"
        bg="black"
        overflow="visible"
      >
        <ContentMaxWidth maxW="1400px" w="100%" h={isMobile ? 'auto' : '100%'}>
          <Flex
            flexDir={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            w="100%"
            h={isMobile ? 'auto' : '100%'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            mb={'32px'}
          >
            <Box
              mr={!isMobile && '64px'}
              px={isMobile ? '16px' : undefined}
              flex={1}
            >
              <FadeIn mb="32px">
                <Heading
                  fontSize={isMobile ? 40 : 48}
                  color="white"
                  display="inline"
                  lineHeight={1}
                  pt="92px"
                  ml="2px"
                  mb="32px"
                >
                  Automate Assessments. Reduce Risk. Maximize ROI.
                </Heading>
              </FadeIn>
              <FadeIn delay={0.4}>
                <Box borderRadius="8px" overflow="hidden">
                  <MotionBoxInView onInView={() => setIsVideo3InView(true)}>
                    <ReactPlayer
                      url="videos/take_action_step.mp4"
                      width="100%"
                      height="100%"
                      controls={false}
                      playing={isVideo3InView}
                      playsinline
                      loop
                      muted
                    />
                  </MotionBoxInView>
                </Box>
              </FadeIn>
            </Box>
            <Box
              flex={1}
              h="100%"
              w={isMobile && '100%'}
              mb={isMobile && '80px'}
              position="relative"
              pt={!isMobile && '108px'}
              pl={!isMobile && '24px'}
              maxH="800px"
              zIndex={1}
            >
              <Widget
                id={TYPEFORM_UUID}
                style={{
                  zIndex: 100,
                  width: '100%',
                  height: isMobile ? '80vh' : '100%',
                }}
              />
            </Box>
          </Flex>
        </ContentMaxWidth>
      </Box>
      <Box
        minH={isMobile ? 'auto' : '44vh'}
        pt={isMobile ? '120px' : '24px'}
        color="white"
        bg="black"
        overflow="visible"
      >
        <ContentMaxWidth maxW="1400px" w="100%">
          <Flex
            flexDir={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            w="100%"
            h={isMobile ? 'auto' : '100%'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            mb={isMobile ? '32px' : '56px'}
            gap={isMobile ? '16px' : '40px'}
          >
            <FadeIn width="100%" delay={0.33}>
              <Blurb
                width={'100%'}
                heading="Real Measurable Results"
                body="Reach automates costly, time-consuming security maturity assessments. Get prescriptive, actionable results that reduce cyber risks
and prove the ROI of your existing investments"
                headingWidth={100}
              />
            </FadeIn>
            <FadeIn width="100%" delay={0.66}>
              <Blurb
                width={'100%'}
                heading="Why Reach?"
                body={unorderedListBody}
                headingWidth={100}
              />
            </FadeIn>
            <FadeIn width="100%" delay={0.99}>
              {' '}
              <Blurb
                width={'100%'}
                heading="Try Reach"
                body="Experience full product access with one integration of your choice - completely free, with no expiration."
                headingWidth={100}
              />
            </FadeIn>
          </Flex>
        </ContentMaxWidth>
      </Box>
    </>
  )
}

export default SplashArea
