import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { AnimateSharedLayout } from 'framer-motion'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useApp } from 'context/App'
import { Box, Grid, Stack } from '@chakra-ui/react'

import backgroundTopoCompany from 'assets/images/background_topo.png'

import BackgroundTopology from 'components/Shared/BackgroundTopology'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import HistoryPaneButton from 'components/Shared/HistoryPaneButton'
import PostureAutomation from './PostureAutomation'
import ToolsRationalization from './ControlPrioritization'
import UniversalConfiguration from './ToolsRationalization'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import MoreUseCases from './MoreUseCases'

export const USE_CASES_ROUTES = {
  PRODUCT_EFFECTIVENESS: '/use-cases/product-effectiveness',
  UNIVERSAL_CONFIG: '/use-cases/universal-config',
  TARGETED_DEFENSES: '/use-cases/targeted-defenses',
}

const UseCases = ({ history }) => {
  const { isMobile } = useApp()
  const path = history.location.pathname

  const isPostureAutomationSelected =
    USE_CASES_ROUTES.PRODUCT_EFFECTIVENESS === path
  const isUniversalConfigSelected = USE_CASES_ROUTES.UNIVERSAL_CONFIG === path
  const isToolsRationalizationSelected =
    USE_CASES_ROUTES.TARGETED_DEFENSES === path

  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)

  useEffect(() => {
    if (isPostureAutomationSelected)
      ref1.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    if (isUniversalConfigSelected)
      ref2.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    if (isToolsRationalizationSelected)
      ref3.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [ref1.current, ref2.current, ref3.current])

  return (
    <BackgroundTopology
      gradientStartColor="#1F2F5B"
      bgImage={backgroundTopoCompany}
      bgSize="cover"
      py="96px"
      minH="100vh"
      desktopparallax={5}
      mobileparallax={5}
    >
      <ContentMaxWidth
        flexDir="column"
        mb="72px"
        pt={isMobile ? '40px' : '116px'}
        alignItems="flex-start"
      >
        <Grid
          gridTemplateColumns={isMobile ? '1fr' : '340px 1fr'}
          gridGap="32px"
        >
          {!isMobile && (
            <Box h="fit-content" position="sticky" pt="72px" inset={0}>
              <AnimateSharedLayout>
                <Stack bg="white" borderRadius="8px">
                  <HistoryPaneButton
                    w="100%"
                    isSelected={isPostureAutomationSelected}
                    route={USE_CASES_ROUTES.PRODUCT_EFFECTIVENESS}
                    heading="Defensive Posture Automation"
                    body="Automate the deployment of product features available to you, from basic knobs to advanced content, with a couple of clicks."
                  />
                  <HistoryPaneButton
                    w="100%"
                    isSelected={isUniversalConfigSelected}
                    route={USE_CASES_ROUTES.UNIVERSAL_CONFIG}
                    heading="Tools Rationalization"
                    body="Immediate understanding of each tool’s ability to stop threats relevant to your organization."
                  />
                  <HistoryPaneButton
                    w="100%"
                    isSelected={isToolsRationalizationSelected}
                    route={USE_CASES_ROUTES.TARGETED_DEFENSES}
                    heading="Control Prioritization"
                    body="Automatically introduce additional control hardening for the 5% of employees who account for +80% of risk."
                  />
                </Stack>
              </AnimateSharedLayout>
            </Box>
          )}
          <Box>
            {isMobile ? (
              <Box>
                <Box ref={ref1} position="relative" top="-8px" />
                <PostureAutomation />
                <Box ref={ref2} position="relative" top="-8px" />
                <UniversalConfiguration />
                <Box ref={ref3} position="relative" top="-8px" />
                <ToolsRationalization />
              </Box>
            ) : (
              <Switch>
                <Route path={USE_CASES_ROUTES.PRODUCT_EFFECTIVENESS}>
                  <>
                    <Box ref={ref1} position="relative" top="-32px" />
                    <PostureAutomation />
                  </>
                </Route>
                <Route path={USE_CASES_ROUTES.UNIVERSAL_CONFIG}>
                  <>
                    <Box ref={ref2} position="relative" top="-32px" />
                    <UniversalConfiguration />
                  </>
                </Route>
                <Route path={USE_CASES_ROUTES.TARGETED_DEFENSES}>
                  <>
                    <Box ref={ref3} position="relative" top="-32px" />
                    <ToolsRationalization />
                  </>
                </Route>
                <Route path="/use-cases">
                  <Redirect to={USE_CASES_ROUTES.PRODUCT_EFFECTIVENESS} />
                </Route>
              </Switch>
            )}
            <MoreUseCases />
          </Box>
        </Grid>
      </ContentMaxWidth>
    </BackgroundTopology>
  )
}

UseCases.propTypes = { history: PropTypes.object }

export default withRouter(UseCases)
