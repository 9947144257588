import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import {
  Box,
  DarkMode,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import ReachLogo from 'components/Shared/ReachLogo'
import { FaLinkedinIn } from 'react-icons/fa'
import { RiTwitterXLine } from 'react-icons/ri'
import { useApp } from 'context/App'
import ContentMaxWidth from '../ContentMaxWidth'
import soc2Image from './soc-2-icon.webp'

const ReachCopyright = () => {
  const { isMobile } = useApp()
  return (
    <Flex alignItems="center" my={isMobile ? '16px' : '0px'}>
      <ReachLogo
        as={NavLink}
        to="/"
        noText
        logoHeight={isMobile ? '16px' : undefined}
      />
      <Text fontSize={isMobile ? '14px' : '18px'}>© 2024 Reach Security.</Text>
    </Flex>
  )
}

const FooterLink = ({ to, ...remainingProps }) => {
  const { isMobile } = useApp()
  return (
    <Link
      as={NavLink}
      to={to}
      style={({ isActive }) => ({
        color: 'reachBlue.200',
        fontWeight: isActive ? '600' : '200',
        fontSize: isMobile ? '20px' : '16px',
        textAlign: isMobile ? 'center' : 'left',
      })}
      {...remainingProps}
    />
  )
}

FooterLink.propTypes = {
  to: PropTypes.string,
}

const PageFooter = () => {
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <DarkMode>
      <Box
        w="100vw"
        h={isMobile ? 'auto' : '160px'}
        bg="black"
        pt={isMobile ? '32px' : undefined}
        borderTop="1px solid"
        borderTopColor="reachBlue.500"
        color="white"
      >
        <ContentMaxWidth maxW="1600px" flexDir="column">
          <Flex
            flexDir={isMobile ? 'column' : 'row'}
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            mb={isMobile ? '8px' : undefined}
          >
            <Spacer />
            <Flex
              flexWrap="wrap"
              gap={isMobile ? '24px' : '32px'}
              color="reachBlue.200"
              alignItems="center"
              flexDir={isMobile ? 'column' : 'row'}
              mb={isMobile ? '16px' : '0px'}
            >
              <FooterLink to="/product">Product</FooterLink>

              <FooterLink to="/company">About</FooterLink>

              <FooterLink to="/resources/news">Resources</FooterLink>

              <FooterLink to="/connect">Get a demo</FooterLink>

              <FooterLink to="/try-reach">Assessment</FooterLink>
            </Flex>
          </Flex>
          <Flex
            w="100%"
            flexWrap="wrap"
            gap={isMobile ? '24px' : '32px'}
            color="reachBlue.200"
            justifyContent="flex-start"
            alignItems="center"
            flexDir={isMobile ? 'column' : 'row'}
            mb={isMobile ? '16px' : '0px'}
          >
            {!isMobile && (
              <>
                <Image ml="4px" src={soc2Image} maxW="48px" />

                <ReachCopyright />
              </>
            )}

            <FooterLink to="/terms">Terms of use</FooterLink>

            <FooterLink to="/careers">Careers</FooterLink>

            <Spacer />

            <Flex my={isMobile ? '16px' : '0'} mb={isMobile ? '24px' : '0px'}>
              <DarkMode>
                <IconButton
                  icon={<Icon as={RiTwitterXLine} />}
                  variant="ghost"
                  href="https://twitter.com/ReachSecurity"
                  target="_blank"
                  as={Link}
                  mr={isMobile ? '' : '16px'}
                />
                <IconButton
                  variant="ghost"
                  icon={<Icon as={FaLinkedinIn} />}
                  href="https://www.linkedin.com/company/reach-security/"
                  target="_blank"
                  as={Link}
                />
              </DarkMode>
            </Flex>

            {isMobile && (
              <>
                <Image ml="4px" src={soc2Image} maxW="48px" />

                <ReachCopyright />
              </>
            )}
          </Flex>
        </ContentMaxWidth>
      </Box>
    </DarkMode>
  )
}

export default PageFooter
