import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Text, Image } from '@chakra-ui/react'
import reachLogo from 'assets/images/ReachLogo.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ReachLogo = ({
  color,
  noText,
  logoHeight,
  fontSize,
  letterSpacing,
  ...props
}) => {
  const history = useHistory()
  return (
    <Flex p="8px" alignItems="center" {...props}>
      <Image mr={noText ? '0px' : '8px'} height={logoHeight} src={reachLogo} />
      {!noText && (
        <Text
          onClick={() => history.push('/')}
          cursor="pointer"
          color={color || 'white'}
          fontWeight="medium"
          fontSize={fontSize}
          fontFamily="GT Walsheim Pro"
          letterSpacing={letterSpacing}
        >
          Reach
        </Text>
      )}
    </Flex>
  )
}

ReachLogo.defaultProps = {
  logoHeight: '32px',
  fontSize: '24px',
}

ReachLogo.propTypes = {
  letterSpacing: PropTypes.any,
  color: PropTypes.string,
  noText: PropTypes.bool,
  logoHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.string,
}

export default ReachLogo
