import React from 'react'
import PropTypes from 'prop-types'
import { useApp } from 'context/App'
import { Box, Heading, Text, Button } from '@chakra-ui/react'
import MotionBox from './MotionBox'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const PaneButton = ({ body, heading, route, isSelected, ...remaining }) => {
  const { isMobile } = useApp()
  const history = useHistory()

  return (
    <Box position="relative">
      {isSelected && (
        <MotionBox
          position="absolute"
          inset={0}
          bg="reachBlue.800"
          layoutId="pane-button"
          borderRadius="8px"
        />
      )}
      <Button
        onClick={() => history.push(route)}
        color={isSelected ? 'white' : 'gray.900'}
        variant="ghost"
        w={isMobile ? '100%' : '480px'}
        h="120px"
        justifyContent="start"
        {...remaining}
      >
        <Box textAlign="left">
          <Heading size="md" mb="8px">
            {heading}
          </Heading>
          <Text lineHeight={1.25} whiteSpace="break-spaces">
            {body}
          </Text>
        </Box>
      </Button>
    </Box>
  )
}

PaneButton.propTypes = {
  body: PropTypes.string,
  heading: PropTypes.string,
  route: PropTypes.string,
  isSelected: PropTypes.bool,
}

export default PaneButton
