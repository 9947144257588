import React from 'react'
import PropTypes from 'prop-types'
import TextBlurb from 'components/Shared/DeprecatedTextBlurb'
import { Button, Flex } from '@chakra-ui/react'
import { AnimImage } from 'components/Deprecated/Home/MiddleSection2'
import { useApp } from 'context/App'

// import universalConfig1 from '../Home/MiddleSection/content/universal_config1.png'
// import universalConfig2 from '../Home/MiddleSection/content/universal_config2.png'
// import universalConfig3 from '../Home/MiddleSection/content/universal_config3.png'

// export const ControlPrioritizationImages = ({ isIn }) => {
//   const { isMobile } = useApp()
//   return (
//     <>
//       <AnimImage
//         isIn={isIn}
//         boxShadow="1px 2px 8px rgba(0,0,0,.05)"
//         borderRadius="8px"
//         border="1px solid #DDE0E8"
//         src={universalConfig1}
//         x="0px"
//         y="0px"
//         h={isMobile ? null : '500px'}
//         w={isMobile ? '90%' : null}
//         delay={0}
//       />
//       <AnimImage
//         isIn={isIn}
//         boxShadow="1px 2px 8px rgba(0,0,0,.05)"
//         bg="white"
//         borderRadius={isMobile ? '12px' : '28px'}
//         border="1px solid #DDE0E8"
//         src={universalConfig2}
//         x={isMobile ? '60px' : '570px'}
//         y="64px"
//         h={isMobile ? '200px' : '460px'}
//         delay={0.4}
//       />
//       <AnimImage
//         isIn={isIn}
//         boxShadow="1px 2px 8px rgba(0,0,0,.05)"
//         borderRadius="8px"
//         src={universalConfig3}
//         x={isMobile ? '68px' : '590px'}
//         y={isMobile ? '102px' : '152px'}
//         h={isMobile ? '80px' : '180px'}
//         delay={0.6}
//       />
//     </>
//   )
// }
// ControlPrioritizationImages.propTypes = {
//   isIn: PropTypes.bool,
// }

import targetedDefense1 from '../Home/MiddleSection/content/targeted_defense1.png'
import targetedDefense2 from '../Home/MiddleSection/content/targeted_defense2.png'
import targetedDefense3 from '../Home/MiddleSection/content/targeted_defense3.png'
import targetedDefense4 from '../Home/MiddleSection/content/targeted_defense4.png'
import targetedDefense5 from '../Home/MiddleSection/content/targeted_defense5.png'

const ControlPrioritizationImages = ({ isIn }) => {
  const { isMobile } = useApp()
  return (
    <>
      {!isMobile && (
        <AnimImage
          isIn={isIn}
          borderRadius="12px"
          boxShadow="1px 2px 8px rgba(0,0,0,.05)"
          src={targetedDefense1}
          x={isMobile ? '-8px' : '180px'}
          y="128px"
          h={isMobile ? null : '400px'}
          w={isMobile ? '95%' : 'auto'}
          delay={0}
        />
      )}
      <AnimImage
        isIn={isIn}
        borderRadius="8px"
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        src={targetedDefense2}
        x={isMobile ? '160px' : '188px'}
        y={isMobile ? '0px' : '8px'}
        h={isMobile ? '60px' : '106px'}
        delay={0.3}
      />
      <AnimImage
        isIn={isIn}
        borderRadius="8px"
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        src={targetedDefense3}
        x={isMobile ? '160px' : '444px'}
        y={isMobile ? '68px' : '8px'}
        h={isMobile ? '60px' : '106px'}
        delay={0.6}
      />
      <AnimImage
        isIn={isIn}
        borderRadius="8px"
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        src={targetedDefense4}
        x={isMobile ? '160px' : '702px'}
        y={isMobile ? '136px' : '8px'}
        h={isMobile ? '60px' : '106px'}
        delay={0.9}
      />
      <AnimImage
        isIn={isIn}
        borderRadius="12px"
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        src={targetedDefense5}
        x={isMobile ? '0' : '0px'}
        y={isMobile ? '0px' : '136px'}
        h={isMobile ? '240px' : '320px'}
        delay={0.3}
      />
    </>
  )
}
ControlPrioritizationImages.propTypes = {
  isIn: PropTypes.bool,
}

const ControlPrioritization = () => {
  const { isMobile } = useApp()

  return (
    <>
      <TextBlurb
        heading="Control Prioritization"
        tagline="Automatically introduce additional control hardening for the 5% of employees who account for +80% of the risk."
        body={`If 5% of your organization makes up 80% of the risk, why sacrifice operational efficiency and resources by increasing security where it’s less impactful? Reach creates and maintains Most Attacked People groups using attack telemetry and translates the configurations down to each product in your stack. This approach enables security teams to mitigate risk with advanced feature adoption while limiting end-user friction.`}
        mb="16px"
      />
      <Button
        mb="64px"
        variant="outline"
        size="lg"
        onClick={() =>
          window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
          })
        }
      >
        View Examples
      </Button>

      <TextBlurb
        heading={null}
        tagline="Operationalizing most attacked people groups"
        body={null}
      />
      <Flex
        h={isMobile ? '260px' : '600px'}
        w="100%"
        position="relative"
        mb="64px"
      >
        <ControlPrioritizationImages
          isIn
          w="auto"
          h={isMobile ? '260px' : '600px'}
          x="0"
        />
      </Flex>

      <TextBlurb
        heading={null}
        tagline="Problem"
        body={`Security is not a one-size-fits-all approach. In most organizations, a fraction of the population accounts for the most risk when it comes to attacks. Deploying advanced controls to every employee in the organization would lead to operational inefficiencies for users that have no history of being targeted or no history of interacting with inbound threats. Knowing who needs elevated controls is difficult to quantify as attack targets constantly evolve.`}
        mb="64px"
      />

      <TextBlurb
        mb="32px"
        heading={null}
        tagline="How Reach helps"
        body={`By creating and maintaining Most Attacked People (MAP) groups that integrate with your canonical identity record, Reach provides visibility into workforce risk hot spots. Reach helps you operationalize these groups by tailoring more stringent controls from your tools toward these users. Time and resources are saved, risk reduction is quantified, and operational efficiency is not compromised.`}
      />
    </>
  )
}

export default ControlPrioritization
