import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Nutanix from './Nutanix'
import AnonymousManufacturing from './AnonymousManufacturing'
import SSLDecryption from './SSLDecryption'
import SSOHardening from './SSOHardening'
import ToolsRationalization from './ToolsRationalization'

const CaseStudies = () => {
  return (
    <Switch>
      <Route exact path="/customers/nutanix">
        <Nutanix />
      </Route>
      <Route exact path="/customers/anonymous-manufacturing">
        <AnonymousManufacturing />
      </Route>
      <Route exact path="/customers/ssl-decryption">
        <SSLDecryption />
      </Route>
      <Route exact path="/customers/okta-layered-protection">
        <SSOHardening />
      </Route>
      <Route exact path="/customers/tools-rationalization">
        <ToolsRationalization />
      </Route>
    </Switch>
  )
}

export default CaseStudies
