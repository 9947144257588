import React from 'react'
import { Box, Button, Center, Flex, Icon } from '@chakra-ui/react'
import { BiCookie } from 'react-icons/bi'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { useApp } from 'context/App'

const CookieNotification = () => {
  const { cookiesAccepted, handleAcceptCookies } = useApp()
  const { isMobile } = useApp()

  // localstorage can store it as a string
  if (cookiesAccepted === true || cookiesAccepted === 'true') {
    return null
  }

  return (
    <Box
      position="fixed"
      w="100vw"
      bottom="0px"
      bg={isMobile ? '#1F2F5BCC' : '#00000090'}
      zIndex={111}
      pointerEvents="none"
    >
      <Center>
        <Flex align="center" p="16px" color="white">
          <Icon as={BiCookie} mr="8px" />
          <Box mr="16px">
            Cookies help us deliver the best experience on our website. By using
            our website, you agree to the use of cookies.
          </Box>
          {!isMobile && (
            <Button
              flexShrink={0}
              pointerEvents="all"
              leftIcon={<Icon as={AiOutlineCheckCircle} />}
              size="sm"
              colorScheme="reachTeal"
              variant="outline"
              onClick={handleAcceptCookies}
            >
              I Accept
            </Button>
          )}
        </Flex>
      </Center>
      {isMobile && (
        <Center mb="16px">
          <Button
            flexShrink={0}
            pointerEvents="all"
            leftIcon={<Icon as={AiOutlineCheckCircle} />}
            size="lg"
            colorScheme="reachTeal"
            variant="outline"
            onClick={handleAcceptCookies}
          >
            I Accept
          </Button>
        </Center>
      )}
    </Box>
  )
}

export default CookieNotification
