/* eslint-disable react/no-unescaped-entities */
import { Box, Flex, Heading, LightMode, Link } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import PageHeader from 'components/Shared/PageHeader'
import { useApp } from 'context/App'
import React, { useEffect } from 'react'
import BlogAuthor from '../../BlogAuthor'
import { BlogImage } from '../TRABlog'
import PageFooter from 'components/Shared/PageFooter'
import nick from '../AuthorImages/nick3.png'
import survey from './survey.png'
import graph from './graph.png'
import quest from './quest.png'
import controls from './controls.png'

const RoadsNotRoadBlocksBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])
  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Roads, Not Roadblocks: Empowering User Experience with Secure
                Access
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">October 24, 2024</Box>
                <BlogAuthor name="Nick Roy" img={nick} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mb="16px">
                One of the most exciting parts of joining an early-stage company
                is the opportunity to do things differently. Shortly after I
                joined Reach, I learned that customers were using Reach to help
                streamline authentication processes for low-risk users,
                particularly those who log in from the same locations and
                devices each day. Rather than constantly prompting for
                multi-factor authentication, Reach enables security teams to
                identify and control exposure in place for users in familiar
                scenarios, reducing the friction for users, while maintaining
                security.
              </Box>
              <Box mb="64px">
                Credential abuse has consistently topped the Verizon DBIR for
                several years, yet there’s growing frustration from users when
                required to authenticate with MFA too often. Prove Security’s
                2023 State of MFA report revealed that 33% of respondents didn’t
                enable MFA because they felt “it’s annoying”.
              </Box>

              <BlogImage src={survey} m="0 auto" />

              <Box mt="64px" mb="64px">
                While the DBIR shows an increase in vulnerabilities being
                exploited as an initial access method, credential abuse and
                phishing still remain the primary ways that adversaries are
                gaining network access.
              </Box>

              <BlogImage src={graph} m="0 auto" />

              <Box mt="64px" mb="64px">
                While this can be a cause for concern and might lead some to
                tighten controls for end users, it’s important to consider the
                human side of security. When users get frustrated for feeling
                that security is hindering their work, they often find ways to
                bypass those controls. Taking a broader view of your security
                program can help strike a balance by improving security hygiene
                and identifying compensating controls to mitigate risks as well
                as specific situations where more trust can be extended to a
                user. For example, a recent Quest in Reach introduced the option
                to prioritize and tailor controls, streamlining authentication
                in Okta for users signing in from familiar locations, reducing
                unnecessary friction while still maintaining security and
                continuously validating the authentication process.
              </Box>

              <BlogImage m="0 auto" src={quest} />

              <Box mt="64px" mb="64px">
                This example shows how we can leverage your tech stack to
                protect the organization while making life easier for users. If
                malware happens to reach a user’s endpoint, whether coming from
                a malicious email or a different source, Reach provides
                additional recommendations for firewalls and endpoints to
                prevent the download of second-stage payloads and further
                exposure, empowering security teams to mobilize changes quickly.
              </Box>

              <BlogImage m="0 auto" src={controls} />

              <Box mt="64px" mb="16px">
                Security is a delicate balance between users, tools, and
                policies. One of the most powerful aspects of Reach is its
                efficacy in tailoring recommendations based on specific users
                and scenarios. While many organizations begin by tightening
                their security measures, Reach provides the flexibility to
                tailor these controls for well-known users and low-risk
                situations. This approach helps security teams be seen as
                enablers rather than the department that always says “no”.
              </Box>

              <Box mb="16px">
                To see a live demo of this in action, a recording of our recent
                webinar is{' '}
                <Link
                  fontWeight="bold"
                  href="https://reach-security.zoom.us/webinar/register/WN_7dtI-aQeSPKfGowfujsR3A#/registration"
                  target="_blank"
                >
                  here.
                </Link>
              </Box>

              <Heading size="md" mb="16px">
                More About Reach Security
              </Heading>
              <Box mb="16px">
                Reach is the first AI purpose-built to reprogram your security
                infrastructure based on who you are and how you're being
                attacked. Organizations of all sizes trust Reach to make
                mission-critical decisions because it doesn't hallucinate and it
                doesn't make mistakes. It's a different kind of AI. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default RoadsNotRoadBlocksBlog
