import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'
import { useInView } from 'react-intersection-observer'

const InView = ({
  inViewOptions,
  onInView,
  onOutOfView,
  ...remainingProps
}) => {
  const { ref, inView } = useInView(inViewOptions)

  useEffect(() => {
    if (inView) {
      onInView?.()
    } else {
      onOutOfView?.()
    }
  }, [inView])

  return <Box ref={ref} {...remainingProps} />
}

InView.defaultProps = {
  inViewOptions: { threshold: 0.6, triggerOnce: false },
}

InView.propTypes = {
  inViewOptions: PropTypes.object,
  onInView: PropTypes.func,
  onOutOfView: PropTypes.func,
}
export default InView
