/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import { Box, Flex, Heading, LightMode, Link } from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'

import B from 'components/Shared/B'
import { BlogImage } from '../TRABlog'
import BlogAuthor from '../../BlogAuthor'
import trent from '../AuthorImages/trent.png'
import questRewards from './quest_rewards.png'
import deploymentGroup from './deployment_group.png'
import issueTracking from './issuse_tracking.png'
import stageContent from './stage_content.png'
import review from './review.png'
import flipOn from './flip_on.png'
import trackProgress from './track_progress.png'
const AutomationBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                The Power of Automation with Reach
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">July 31, 2024</Box>
                <BlogAuthor name="Trent Weber" img={trent} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mb="16px">
                Ensuring the best possible preventative measures isn't always
                about getting more security tools. Most organizations already
                have a wide range of advanced tools in place. However, there's
                still a challenge in fully utilizing the capabilities of these
                existing tools. It's all about effectively using the
                capabilities you're licensed for to bridge the gap between
                assessing risks and managing them. The challenge lies not only
                in understanding and identifying what you're licensed for but
                also in deploying the optimal combination of these capabilities
                to mitigate and manage risk effectively.
              </Box>
              <Box mb="16px">
                We have written about how Reach will recommend specific controls
                within the tools you are using and how our integrations with
                Jira and ServiceNow will help create tickets to make the changes
                more efficient. But Reach also provides a way for you to
                automatically stage changes in a controlled environment.
              </Box>
              <Box mb="64px">
                In cybersecurity, it is important to maintain a stable and
                secure production environment by staging changes in a controlled
                manner. At Reach we understand the need for safety and control
                which is why our automation works in a staged environment before
                any changes go live.
              </Box>

              <Heading size="md" mb="16px">
                Benefits of staged environment
              </Heading>

              <Box mb="64px">
                The staged automation process at Reach offers many benefits,
                including:
                <br />
                <br />
                <B>Efficiency:</B> Reach's automation significantly reduces the
                time and labor required for deploying changes. Processes that
                would typically be long and laborious are automated, freeing up
                valuable resources and allowing IT teams to focus on more
                strategic tasks. This not only enhances productivity but also
                accelerates the overall implementation timeline. Additionally,
                the streamlined collaboration process between security and IT
                departments is a key benefit. Security teams can define the
                necessary deployments and their risk mitigation impacts through
                detailed change tickets. IT can quickly approve these changes,
                ensuring efficient staging and deployment.
                <br />
                <br />
                <B>Increased Confidence:</B> Knowing that changes have been
                tested and reviewed in a controlled environment instills
                confidence in the final deployment. Users can trust that the
                modifications will work as intended without causing unexpected
                issues.
                <br />
                <br />
                <B>Operational Continuity:</B> By preventing disruptions in the
                production environment, organizations can maintain continuous
                operations and focus on their core business activities without
                worrying about system instability or downtime.
              </Box>

              <Heading size="md" mb="16px">
                Reach automation with Conditional Access
              </Heading>

              <Box mb="16px">
                Reach offers this staging automation across all the security
                tools we integrate with, one of these products is Conditional
                Access.
                <br />
                <br />
                Here is an example of Reach’s automation with Conditional
                Access:
                <BlogImage src={questRewards} />
                <Box fontStyle="italic" mb="64px">
                  Create a sign-on policy that blocks high risk sign-ins from
                  untrusted devices.
                </Box>
                <BlogImage src={deploymentGroup} />
                <Box fontStyle="italic" mb="64px">
                  Apply to the ~4% of our knowledge workers that account for 80%
                  of our risk.
                </Box>
                <BlogImage src={issueTracking} />
                <Box fontStyle="italic" mb="64px">
                  Track in ticketing system with a change guide.
                </Box>
                <BlogImage src={stageContent} />
                <Box fontStyle="italic" mb="64px">
                  Stage configurations as default ”off” to test or preview
                  instance after approvals are given.
                </Box>
                <BlogImage src={review} />
                <Box fontStyle="italic" mb="64px">
                  Review staged policy within Conditional Access.
                </Box>
                <BlogImage src={flipOn} />
                <Box fontStyle="italic" mb="64px">
                  Flip to ”on” and take advantage of risk/contextual based
                  sign-in attributes included in your license.
                </Box>
                <BlogImage src={trackProgress} />
                <Box fontStyle="italic" mb="64px">
                  Track progress and report upward on capability consumption,
                  utilization, and risk reduction value realized.
                </Box>
              </Box>

              <Box mb="64px">
                At Reach, we prioritize the safety and control of our users by
                leveraging a staged environment for automation. This approach
                not only safeguards the production environment but also ensures
                that changes are meticulously tested and approved before going
                live. Whether it’s implementing Conditional Access policies or
                deploying other critical updates, Reach’s staged automation
                empowers organizations to increase their cybersecurity posture
                with confidence, efficiency, and ease.
              </Box>

              <Heading size="md">More About Reach Security</Heading>
              <br />
              <Box>
                Reach is the first AI purpose-built to reprogram your security
                infrastructure based on who you are and how you're being
                attacked. Organizations of all sizes trust Reach to make
                mission-critical decisions because it doesn't hallucinate and it
                doesn't make mistakes. It's a different kind of AI. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default AutomationBlog
