import React, { useEffect, useRef } from 'react'
import {
  Box,
  Image,
  VStack,
  LightMode,
  Icon,
  Center,
  Flex,
  Link,
} from '@chakra-ui/react'
import { useApp } from 'context/App'
import topImage from '../images/ToolsRationalizationOrange.jpeg'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import BackgroundTopology from 'components/Shared/BackgroundTopology'
import topoimg from 'assets/images/background_topo.png'
// import headshot from '../images/padlock.jpeg'
import { BsMouse } from 'react-icons/bs'
import BackToTop from 'components/Shared/BackToTop'

const MAX_W = '1100px'

const ToolsRationalization = () => {
  const { setIsWhiteHeader, isMobile } = useApp()

  const ref0 = useRef(null)
  const ref1 = useRef(null)
  const ref2 = useRef(null)

  const mainFontSize = isMobile ? '20px' : '28px'
  const largeFontSize = isMobile ? '24px' : '32px'
  const headerFontSize = isMobile ? '32px' : '48px'

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsWhiteHeader(true)
    return () => setIsWhiteHeader(false)
  }, [])

  return (
    <Box>
      <BackToTop />
      <LightMode>
        <Flex
          flexDir="column"
          alignItems="center"
          pt="128px"
          bg="white"
          minH={isMobile ? 'auto' : '100vh'}
          h="800px"
          color="black"
        >
          <ContentMaxWidth maxW="1400px" flexDir="column" flex={1}>
            <Flex alignItems="flex-start" flexDir={isMobile ? 'column' : 'row'}>
              <Box>
                <Image
                  borderRadius="16px"
                  h="104px"
                  mb={isMobile ? '48px' : '32px'}
                  src={topImage}
                />
                <Box position="relative">
                  <Box
                    lineHeight={1.4}
                    fontSize={isMobile ? '24px' : '64px'}
                    fontWeight="light"
                    maxW="800px"
                  >
                    Rationalizing Security Tool Spend
                  </Box>
                </Box>
              </Box>
              <VStack
                flexShrink={0}
                spacing="32px"
                ml={isMobile ? '0' : '40px'}
                mt={isMobile ? '32px' : '56px'}
                mb={isMobile && '72px'}
                color="reachBlue.500"
                alignItems="flex-start"
              >
                <Box>
                  <Link
                    onClick={() =>
                      ref0.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                  >
                    What threats
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    impact my organization
                  </Box>
                </Box>

                <Box display="block">
                  <Link
                    onClick={() =>
                      ref1.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                    whiteSpace="break-spaces"
                  >
                    What can we do
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    To address these threats
                  </Box>
                </Box>

                <Box>
                  <Link
                    onClick={() =>
                      ref2.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                  >
                    How much time
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    does this take
                  </Box>
                </Box>
              </VStack>
            </Flex>
            {!isMobile && (
              <Center mt="128px" flexDir="column">
                <Icon
                  mb="4px"
                  w="32px"
                  h="32px"
                  color="gray.600"
                  as={BsMouse}
                />
                <Box fontSize="14px" color="gray.600">
                  Scroll down
                </Box>
              </Center>
            )}
          </ContentMaxWidth>
        </Flex>
      </LightMode>
      <Box>
        <BackgroundTopology
          bgImage={topoimg}
          bgSize="cover"
          pt="128px"
          pb="128px"
          color="white"
        >
          <ContentMaxWidth
            maxW={MAX_W}
            flexDir="column"
            alignItems="flex-start"
            lineHeight={1.6}
          >
            <Box fontSize={headerFontSize} mb="32px">
              Introduction
            </Box>
            <Box fontSize="26px" fontWeight="light" mb="16px">
              More and more IT and Security leaders are talking about the need
              to “do more with less”, with “less” translating to less resources,
              tools, full-time employees, consultants, and support. While this
              sounds great in theory, wouldn’t a more realistic approach be “do
              more with what we have?” When it comes to today’s security stack,
              we think of this concept as tools rationalization, and it starts
              with finding easy ways to map security problems to tool
              capabilities.
            </Box>
            <Box fontSize="26px" fontWeight="light" mb="40px">
              Interrogating tool owners, tools, and their configurations is hard
              work. Security vendors don’t make it easy. Most tools have
              transformed into entire platforms now, which means there’s a wide
              array of embedded features and functionality that you likely
              aren’t aren’t aware of since they were purchased to solve pointed
              problems (stop phishing, stop malware, stop BEC, stop rogue
              logins, etc).
            </Box>
            <Box fontSize="26px" fontWeight="light" mb="40px">
              We’ve spoken with 100’s of security leaders and practitioners
              about the questions and answers needed most when trying to make
              sense of how they are using their tools.
            </Box>
            <Box fontSize="26px" fontWeight="light" mb="40px">
              Here are the standout questions worth getting answers to when it
              comes to rationalizing the tools you have in place.
            </Box>
          </ContentMaxWidth>
        </BackgroundTopology>
      </Box>

      {/* Text Quote Section 2 */}
      <Box
        ref={ref0}
        bg="white"
        color="black"
        pt="120px"
        pb="120px"
        fontSize={mainFontSize}
        fontWeight="light"
      >
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="8px" fontSize={headerFontSize} fontWeight="medium">
            Step 1) What threats have impacted my organization most over the
            last few quarters?
          </Box>
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            Security is not one-size-fits all, and the way in which your
            organization is attacked is unique to others - even those in the
            same industry. Instead of a blanket approach, wouldn’t you want to
            define your security policies relative to the threats seen in your
            environment?
          </Box>
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            Rationalizing the value of tools starts with understanding these
            threats. How did the products deployed and their current
            configurations stack up against attackers in the last quarter, or
            the quarter before that? How many incidents did we have? How many
            attacks were detected after someone had already clicked something?
          </Box>
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            To get answers to these questions, you can interrogate security
            tools for metadata and forensics on attacks seen, attacks that got
            through, and lessons learned. The downside is that this can be a
            manual and time consuming process. In a perfect world, these
            questions are straightforward to answer and go hand in hand with
            measuring controls in place so you can see and track the value
            you’re getting from them. In other words, they provide context for
            modeling which controls should be prioritized.
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* GRAY BIG TEXT SECTION */}

      <Box ref={ref1} bg="gray.100" color="black" pt="128px" pb="64px">
        <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
          <Box>
            <Box
              color="gray.900"
              fontSize={headerFontSize}
              fontWeight="medium"
              mb="24px"
            >
              Step 2) What can and should we do to address these threats?
            </Box>
            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="32px"
            >
              Implementing controls without understanding what attacks you’re
              really up against is like assigning artillery without a map
              telling you where to fire it. In other words, you can spend plenty
              of resources without making an impact.
            </Box>

            <Box
              color="gray.900"
              fontSize={largeFontSize}
              fontWeight="medium"
              mb="16px"
            >
              Let’s use an example as a backdrop to help approach answers to
              this question.
            </Box>

            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="16px"
            >
              In 2022, Microsoft banned the use of Macros, a popular file type
              used to deliver malware by attackers. While the delivery mechanism
              for malware changed, the act of delivering malware did not. The
              shift in macro-based attacks delivering ransomware to ISO and .LNK
              file based attacks has been causing organizations serious pain.
            </Box>

            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="16px"
            >
              In this instance, enumerating for file blocking capabilities in
              your email security product and deploying new capabilities to
              shift your file blocking policies to account for ISO and .LNK
              files would be a sound way to rationalize what’s in place. Doing
              so would allow you to prevent threats targeting your environment
              using existing capabilities available to you. You could go beyond
              the email example and extend it to each vendor tool in your
              network.
            </Box>

            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="48px"
            >
              As a security leader, you should expect actions like these for the
              types of risks you’ve historically faced, beyond just malicious
              file types targeting your network. By modeling the effectiveness
              of controls and configurations based on techniques from attacks
              observed, you can confidently quantify your tooling’s
              effectiveness to stop external attacks relevant to your
              organization with priority.
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      <Box ref={ref2} />
      <Box
        bg="white"
        color="black"
        pt="120px"
        pb="120px"
        fontSize={mainFontSize}
        fontWeight="light"
      >
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="8px" fontSize={headerFontSize} fontWeight="medium">
            Step 3) How much time did this take?
          </Box>

          <Box mb="32px" fontSize={mainFontSize} fontWeight="light">
            We’re offering up questions that we’ve seen others use to help take
            a <Box as="i">security approach</Box> to tools rationalization.
            Talking to vendors, running compliance checks, and using best
            practices can give you an answer, but it won’t be the{' '}
            <Box as="i">right</Box> answer for the security of your
            organization. Your juice to squeeze ratio can only be defined by
            looking at your environment and its controls.
          </Box>

          <Box mb="32px" fontSize={mainFontSize} fontWeight="light">
            A team&apos;s responses to these questions are always point-in-time.
            As you see the value in rationalizing through threat-related
            quantification, you’ll notice the need for it to be continuous as
            attack techniques are changing and vendor subscriptions are rolling
            out new capabilities.
          </Box>
          <Box mb="32px" fontSize={mainFontSize} fontWeight="light">
            Measurement is the key to management, and rationalizing the security
            value of the tools deployed today, to do more going forward, is a
            bi-product of effective measurement and management.
          </Box>

          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            If you’ve found value in getting answers to the questions we’ve
            shared, let us know. If you want to deliver answers to these
            questions and rationalize tool spend in an automated fashion, reach
            out to us at info@reach.security.{' '}
          </Box>
        </ContentMaxWidth>
      </Box>
    </Box>
  )
}

export default ToolsRationalization
