import { Box, Flex, Heading } from '@chakra-ui/react'
import Testimonial from 'components/Shared/Testimonial'
import autodeskLogo from 'assets/images/autodesk_logo.png'
import nutanixLogo from 'components/Deprecated/Customers/images/nutanixLogo.svg'
import gartnerLogo from 'assets/images/gartner_logo.svg'
import eyLogo from 'assets/images/ey_logo_updated.png'
import { useApp } from 'context/App'
import React from 'react'

const CustomerTestimonials = () => {
  const { isMobile } = useApp()

  return (
    <Box mt="32px" textAlign={'center'} w="100%" mb="64px">
      <Heading mb="32px">
        What are customers and industry experts saying?
      </Heading>
      <Flex
        flexDir={isMobile ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
        gap={isMobile ? '16px' : '40px'}
      >
        <Testimonial
          logoPath={autodeskLogo}
          quote="We found value almost immediately once we deployed Reach. It only took a few minutes to integrate the product in the Autodesk environment"
          delay={0.25}
          height={110}
          link="/downloads/Autodesk_Reach_Case_Study.pdf"
        />
        <Testimonial
          logoPath={nutanixLogo}
          quote="Reach has given prescriptive guidance on how to improve in existing tool sets. It’s 100% tailored to the environment –it’s not theoretical, it’s practical."
          delay={0.5}
          height={110}
          link="/downloads/Nutanix_Reach_Case_Study.pdf"
        />
        <Testimonial
          logoPath={gartnerLogo}
          quote="Gartner emphasizes the importance of automated assessments in maintaining a strong security posture in their research on the emerging ASCA market."
          delay={1}
          height={110}
          link="/resources/blog/gartner_blog"
        />
        <Testimonial
          logoPath={eyLogo}
          quote="Only 10% - 20% of cybersecurity technology is actually used, yet teams are buying more without using what they’ve already got."
          delay={0.75}
          height={110}
          scale={isMobile ? 50 : 100}
        />
      </Flex>
    </Box>
  )
}

export default CustomerTestimonials
