/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'
import backgroundTopology from 'assets/images/background_topo.png'
import { useTransform, useViewportScroll } from 'framer-motion'
import { useApp } from 'context/App'

const BackgroundTopology = ({
  children,
  h,
  minH,
  m,
  ml,
  mr,
  mt,
  mb,
  gradientDirection,
  gradientStartColor,
  gradientEndColor,
  bgImage,
  mobileparallax,
  desktopparallax,
  ...props
}) => {
  const { isMobile } = useApp()
  const [backgroundPosition, setBackgroundPosition] = useState(0)
  const { scrollYProgress } = useViewportScroll()
  const yRange = useTransform(
    scrollYProgress,
    [0, 1],
    isMobile ? [0, mobileparallax] : [0, desktopparallax]
  )

  useEffect(() => yRange.onChange((v) => setBackgroundPosition(v)), [yRange])

  return (
    <Box
      bgGradient={`linear(${gradientDirection}, ${gradientStartColor}, ${gradientEndColor})`}
      m={m}
      ml={ml}
      mr={mr}
      mt={mt}
      mb={mb}
      h={h}
      minH={minH}
    >
      <Box
        bgImage={bgImage}
        bgSize="contain"
        bgRepeat="repeat"
        backgroundPosition={`100% ${backgroundPosition}%`}
        h={h}
        minH={minH}
        {...props}
      >
        {children}
      </Box>
    </Box>
  )
}

BackgroundTopology.defaultProps = {
  gradientDirection: 'to-br',
  gradientStartColor: 'reachBlue.700',
  gradientEndColor: 'reachBlue.600',
  bgImage: backgroundTopology,
  mobileparallax: 50,
  desktopparallax: 100,
}

BackgroundTopology.propTypes = {
  bgImage: PropTypes.string,
  children: PropTypes.any,
  h: PropTypes.any,
  minH: PropTypes.any,
  m: PropTypes.any,
  ml: PropTypes.any,
  mr: PropTypes.any,
  mt: PropTypes.any,
  mb: PropTypes.any,
  gradientDirection: PropTypes.string,
  gradientStartColor: PropTypes.string,
  gradientEndColor: PropTypes.string,
  mobileparallax: PropTypes.number,
  desktopparallax: PropTypes.number,
}

export default BackgroundTopology
