/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import { Box, Flex, Heading, LightMode, Link } from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import morey from '../AuthorImages/morey.jpeg'
import BlogAuthor from '../../BlogAuthor'

const GartnerBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])
  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Reach Security mentioned in the 2024 Gartner® Innovation
                Insight: Automated Security Control Assessment
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">September 19, 2024</Box>
                <BlogAuthor name="CP Morey" img={morey} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mb="16px">
                As the cybersecurity landscape continues to evolve, the
                complexity of managing security controls has only grown. In its
                latest Innovation Insight: Automated Security Control Assessment
                (ASCA) report, Gartner states that, “Automated security control
                assessment (ASCA) technologies are not just for mature security
                teams with multimillion dollar budgets. They are suitable for a
                wide range of organizations to help address the risks of
                technical security control misconfiguration and mismanagement.”
                Gartner predicts that, “through 2029, more than 60% of security
                incidents will be traced to misconfigured technical security
                controls.” In our opinion, this market-defining research shines
                a light on how critical it is for organizations to address these
                gaps effectively.
              </Box>
              <Box mb="16px">
                While Gartner is helping to shape this new market category,
                companies like Autodesk and Nutanix are already realizing the
                benefits of this approach through Reach’s platform. By focusing
                on continuous security control assessments that leverage both
                business and adversarial threat context, Reach helps
                organizations like Autodesk and Nutanix understand the specific
                risks they face. Reach automates configuration changes to align
                with these risks, while also ensuring that organizations
                maximize the value of their existing security tools.
              </Box>

              <Heading size="md" mb="16px">
                The Critical Role of ASCA in Modern Cybersecurity
              </Heading>

              <Box mb="16px">
                Gartner’s report emphasizes that the “Security teams can
                optimize their organization’s security posture by establishing
                processes and investing in technologies for continuous
                assessment of technical security controls. Pairing these
                processes and technologies with various exposure data —
                including attack surface context, vulnerabilities, attack
                simulations and threat intelligence relevant to the business —
                enables the assessments to be automated.” Gartner also notes
                that, “Rather than simply verifying the presence of technical
                security controls, ASCA helps evaluate and optimize control
                configurations against best practices, benchmarks and the
                current threat landscape.”
              </Box>

              <Box mb="16px">
                According to Gartner, "By 2027, 40% of broad portfolio security
                providers will contain ASCA features, up from less than 5%
                today." We think this projection underscores how essential
                automated security control assessments will become for
                organizations at every maturity level.
              </Box>

              <Heading size="md" mb="16px">
                How Reach Aligns with ASCA: Delivering Value Today
              </Heading>

              <Box mb="16px">
                While the term ASCA is new, the challenges it addresses are not.
                Reach’s platform has been tackling these issues head-on by
                enabling customers to optimize their existing security
                investments. Our approach focuses on continuous security control
                assessments that use business and adversarial threat context to
                understand the specific risks an organization faces, automation
                of configuration changes to align to the risk identified, and a
                deeper understanding of how to get the most out of the tools
                already in place.
              </Box>

              <Box mb="16px">
                For instance, Autodesk—a global leader in software
                solutions—faced challenges with underutilized security tools.
                Using Reach, they were able to automate key security processes,
                reduce configuration drift, and create actionable insights that
                directly improved their security posture (
                <Link
                  fontWeight="bold"
                  href="https://reach.security/downloads/Autodesk_Reach_Case_Study.pdf"
                  target="_blank"
                >
                  read more
                </Link>
                ). Similarly, Nutanix leveraged Reach to automate security
                control hardening, addressing vulnerabilities proactively rather
                than just responding to incidents (
                <Link
                  fontWeight="bold"
                  href="https://reach.security/downloads/Nutanix_Reach_Case_Study.pdf"
                  target="_blank"
                >
                  read more
                </Link>
                ).
              </Box>

              <Heading size="md" mb="16px">
                Real-World Impact: Customers Getting Value Today
              </Heading>

              <Box mb="16px">
                Autodesk and Nutanix’s success stories are powerful examples of
                how Reach is delivering on the promise of ASCA today. By
                deploying Reach, these organizations have seen reduced
                operational complexity, quicker responses to threats, and
                greater visibility into how their security tools are performing.
              </Box>

              <Box mb="16px">
                At Autodesk, the ability to tailor conditional access policies
                and automate ticket generation allowed their team to streamline
                processes, reduce workload, and improve their security defenses.
                For Nutanix, Reach provided real-time insights into how their
                security controls could be optimized, helping them to harden
                defenses and maximize the ROI of their security investments.
              </Box>

              <Heading size="md" mb="16px">
                Looking Ahead
              </Heading>

              <Box mb="16px">
                Reach will continue to work with customers to ensure they
                maximize the value of their security tools, today and into the
                future.
              </Box>

              <Box mb="64px">
                For more information on how Reach can help your organization
                understand ACSA and the benefits it offers, visit{' '}
                <Link
                  fontWeight="bold"
                  href="https://reach.security/try-reach"
                  target="_blank"
                >
                  reach.security/try-reach.
                </Link>
              </Box>
              <Box>
                Gartner, Innovation Insight: Automated Security Control
                Assessment, <Link fontWeight="bold">Evgeny Mirolyubov</Link>, 29
                August 2024
                <br />
                <b>GARTNER</b> is a registered trademark and service mark of
                Gartner, Inc. and/or its affiliates in the U.S. and
                internationally and is used herein with permission. All rights
                reserved.
                <br />
                Gartner does not endorse any vendor, product or service depicted
                in its research publications, and does not advise technology
                users to select only those vendors with the highest ratings or
                other designation. Gartner research publications consist of the
                opinions of Gartner’s research organization and should not be
                construed as statements of fact. Gartner disclaims all
                warranties, expressed or implied, with respect to this research,
                including any warranties of merchantability or fitness for a
                particular purpose.
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default GartnerBlog
