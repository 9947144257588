import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { useApp } from 'context/App'

const SplashArea = () => {
  const { isMobile } = useApp()
  return (
    <>
      <Box pt={isMobile && '128px'} h={isMobile ? 'auto' : '300px'}>
        <ContentMaxWidth maxW="1200px" flexDir="column">
          <Flex alignItems="center" justifyContent="center" w="100%" h="100%">
            <Box textAlign="center">
              <Box fontSize={32} fontWeight="light" lineHeight={1.1}>
                Partnering with security superheroes at world-class
                organizations.
              </Box>
            </Box>
          </Flex>
        </ContentMaxWidth>
      </Box>
    </>
  )
}

export default SplashArea
