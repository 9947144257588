import React from 'react'
import { Box } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'

const CareersBody = () => {
  return (
    <ContentMaxWidth maxW="1600px">
      <Box pt="120px" minH="100vh" w="100%">
        <div id="ashby_embed"></div>
      </Box>
    </ContentMaxWidth>
  )
}
export default CareersBody
