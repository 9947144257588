import { createBreakpoints } from '@chakra-ui/theme-tools'
export const OUTLINE_COLOR = '#2948bd'
export const ERROR_COLOR = '#EE4353'
export const ERROR_COLOR_DARK = '#f26674'
export const FOCUSED_COLOR = '#3f59a7ff'
export const FOCUSED_COLOR_DARK = '#819BD2ff'

export default {
  initialColorMode: 'dark',
  breakpoints: createBreakpoints({
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
    '3xl': '128em',
    '4xl': '160em',
  }),
  colors: {
    button: {
      50: '#F7F8FD',
      100: '#D7E3F7',
      200: '#B2C7F0',
      300: '#819BD2',
      400: '#556DA5',
      500: '#26386A',
      600: '#1F2F5B',
      700: '#131F4C',
      800: '#0C153D',
      900: '#070E32',
    },
    reachBlue: {
      50: '#F7F8FD',
      100: '#D7E3F7',
      200: '#B2C7F0',
      300: '#819BD2',
      400: '#556DA5',
      500: '#26386A',
      600: '#1B2A5B',
      700: '#131F4C',
      800: '#0C153D',
      900: '#070E32',
    },
    reachTeal: {
      50: '#E7F9F5',
      100: '#BFEEE3',
      200: '#96E3D2',
      300: '#6CD8C0',
      400: '#46CEB0',
      500: '#2FB194',
      600: '#248973',
      700: '#1A6151',
      800: '#0F382F',
      900: '#04100D',
    },
    yellow: {
      50: '#FEF7E6',
      100: '#FDE8BA',
      200: '#FCDA8D',
      300: '#FACB60',
      400: '#F9BD34',
      500: '#F8AE07',
      600: '#C68B06',
      700: '#956804',
      800: '#634603',
      900: '#322301',
    },
    gray: {
      50: '#F9F9FB',
      100: '#F0F1F5',
      200: '#DDE0E8',
      300: '#BFC4D4',
      400: '#A0A7BF',
      500: '#828BAB',
      600: '#656F95',
      650: '#5B6486',
      700: '#505976',
      800: '#3C4258',
      850: '#303441',
      900: '#2D2E33',
      1000: '#1D1E21',
    },
    electricBlue: {
      50: '#E8EAFC',
      100: '#C0C5F7',
      200: '#97A0F2',
      300: '#6E7AEC',
      400: '#4655E7',
      500: '#1D30E2',
      600: '#1726B5',
      700: '#111D88',
      800: '#0C135A',
      900: '#060A2D',
    },
    brand: {
      primary: '#26386A',
      primaryDark: '#4362d7',
      secondary: '#46CEB0',
      dark: '#142040',
      light: '#FFFFFF',
      danger: '#EE4353',
    },
  },
  shadows: {
    outline: `0 0 1px 3px ${OUTLINE_COLOR}`,
  },
  components: {
    Input: {
      defaultProps: {
        variant: 'filled',
        focusBorderColor: 'transparent',
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: OUTLINE_COLOR,
      },
    },
    MenuList: {
      baseStyle: () => ({
        borderRadius: 0,
      }),
    },
    Link: {
      baseStyle: ({ colorMode }) => ({
        fontWeight: 'medium',
        fontFamily: 'Satoshi',
        color: colorMode === 'dark' ? 'button.200' : 'button.500',
        _hiver: colorMode === 'dark' ? 'blue.300' : 'blue.600',
      }),
    },
    Button: {
      defaultProps: {
        colorScheme: 'button',
      },
      baseStyle: {
        fontWeight: 500,
        borderRadius: '0',
        fontFamily: 'Satoshi',
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: 'button',
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: 'Craftworkgrotesk',
        letterSpacing: '-2px',
        fontWeight: 'black',
      },
      sizes: {
        sub: {
          fontSize: '24px',
          lineHeight: '26px',
          letterSpacing: '-0.5px',
          fontWeight: 'medium',
          fontFamily: 'Satoshi',
        },
        sub2: {
          fontSize: '20px',
          lineHeight: '22px',
          letterSpacing: '-0.5px',
          fontWeight: 'medium',
          fontFamily: 'Satoshi',
        },
        sub3: {
          fontSize: '16px',
          lineHeight: '18px',
          letterSpacing: '-0.5px',
          fontWeight: 'medium',
          fontFamily: 'Satoshi',
        },
        sub4: {
          fontSize: '14px',
          lineHeight: '16px',
          letterSpacing: '-0px',
          fontWeight: 'medium',
          fontFamily: 'Satoshi',
        },
        sm: {
          fontSize: '20px',
          lineHeight: '22px',
          letterSpacing: '-.5px',
          fontFamily: 'Satoshi',
        },
        md: {
          fontSize: '24px',
          lineHeight: '26px',
          letterSpacing: '-.5px',
          fontFamily: 'Satoshi',
        },
        lg: {
          fontSize: '28px',
          lineHeight: '35px',
          letterSpacing: '-.5px',
          fontFamily: 'Satoshi',
        },
        xl: { fontSize: '36px', lineHeight: '44px', letterSpacing: '-1px' },
        '2xl': {
          fontSize: '44px',
          lineHeight: '48px',
          letterSpacing: '-1.5px',
        },
        '3xl': { fontSize: '56px', lineHeight: '60px', letterSpacing: '-2px' },
      },
    },
    Text: {
      baseStyle: {
        letterSpacing: '-.25px',
        fontWeight: 'light',
        fontFamily: 'Satoshi',
      },
      sizes: {
        xs: { fontSize: '12px', lineHeight: '16px' },
        sm: { fontSize: '14px', lineHeight: '18px' },
        md: { fontSize: '16px', lineHeight: '20px' },
        lg: { fontSize: '20px', lineHeight: '24px' },
        xl: { fontSize: '24px', lineHeight: '28px' },
        '2xl': { fontSize: '32px', lineHeight: '36px' },
      },
    },
    Modal: {
      baseStyle: (props) => ({
        dialog: {
          bg: props.colorMode === 'dark' ? 'gray.800' : 'white',
        },
      }),
    },
  },
  fonts: {
    heading: 'GT Walsheim Pro, Arial',
    body: 'GT Walsheim Pro, Arial',
  },
}
