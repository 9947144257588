import React from 'react'
import BackgroundTopology from 'components/Shared/BackgroundTopology'
import backgroundTopoCompany from 'assets/images/background_topo_company.svg'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { Box, Heading, Text } from '@chakra-ui/react'
import { useApp } from 'context/App'
import JoinUsForm from './JoinUsForm'

const JoinUs = () => {
  const { isMobile } = useApp()

  return (
    <BackgroundTopology
      gradientStartColor="#1F2F5B"
      bgImage={backgroundTopoCompany}
      py="96px"
      minH="100vh"
    >
      <ContentMaxWidth pt={isMobile ? '40px' : '116px'} mb="6vh">
        <Box flex={1}>
          <Heading size="3xl" color="white" mb="16px">
            Submit Your Email
          </Heading>
          <Text color="reachBlue.200" mb="24px" lineHeight={1.8}>
            You don’t need another product to protect you from the attacks
            facing your organization. We automate the work required to deliver
            the best possible security, using the products you already own.
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </Text>
        </Box>
      </ContentMaxWidth>
      <ContentMaxWidth>
        <JoinUsForm />
      </ContentMaxWidth>
    </BackgroundTopology>
  )
}

export default JoinUs
