import React from 'react'
import { Box, Stack, LightMode } from '@chakra-ui/react'
import NewsEntry from './NewsEntry'
import techCrunchLogo from './images/TechCrunch_logo.svg'
import reachRaise from './images/reach_raise_thumbnail.png'
import crowdstrikePartner from './images/crowdstrike_marketplace.png'
import securityWeekLogo from './images/security_week_logo.png'
import siliconAngleLogo from './images/siliconANGLE_logo.png'
import fintechGlobalLogo from './images/fintech_global_logo.png'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'

const NewsBody = () => {
  return (
    <LightMode>
      <ContentMaxWidth maxW="1600px" h="100%" alignItems="start">
        <Box py="64px">
          <Stack
            gridTemplateColumns="repeat(auto-fit, minmax(320px, 1fr));"
            gap="16px"
          >
            <NewsEntry
              delay={0}
              title="Reach Security Now Available for Purchase on CrowdStrike Marketplace "
              description="Reach Security, the company pioneering advanced AI to reinvent security operations, today announced its listing in the CrowdStrike Marketplace, a premier technology marketplace and world-class ecosystem of third-party security products that integrate with CrowdStrike."
              imageUrl={crowdstrikePartner}
              internal
              url="/resources/news/crowdstrike_marketplace"
            />
            <NewsEntry
              delay={0}
              title="Reach Security Raises $20M for AI That Transforms How Companies Use Their Cybersecurity Products"
              description="Press release"
              imageUrl={reachRaise}
              internal
              url="/resources/news/reach_raise"
            />
            <NewsEntry
              delay={0.33}
              title="Reach Security taps a company’s existing tools to fight cyber threats"
              description="TechCrunch reports on the cybersecurity budget challenges faced by organizations, with Reach Security offering a solution."
              imageUrl={techCrunchLogo}
              url="https://techcrunch.com/2024/03/07/reach-security-taps-a-companys-existing-tools-to-fight-cyber-threats/"
            />
            <NewsEntry
              title="Reach Security Raises $20M to Help Manage Cybersecurity Products"
              description="Reach Security, a California startup promising technology to help businesses manage the maze of security tools and products, has raised $20 million in early stage venture capital funding."
              imageUrl={securityWeekLogo}
              url="https://www.securityweek.com/reach-security-raises-20m-to-help-manage-cybersecurity-products/"
            />
            <NewsEntry
              title="Reach Security raises $20M to enhance AI-driven cybersecurity tools"
              description="Cybersecurity startup Reach Security Inc. announced today that it has raised $20 million in new funding to develop more artificial intelligence security features."
              imageUrl={siliconAngleLogo}
              url="https://siliconangle.com/2024/03/07/reach-security-raises-20m-enhance-ai-driven-cybersecurity-tools/"
            />
            <NewsEntry
              title="Ballistic Ventures spearheads $20m investment in AI-driven Reach Security"
              description="The investment is led by Ballistic Ventures and sees contributions from Artisanal Ventures and notable industry figures such as Mark McLaughlin, former CEO and President of Palo Alto Networks, and Denise Persson, CMO of Snowflake."
              imageUrl={fintechGlobalLogo}
              url="https://fintech.global/2024/03/08/ballistic-ventures-spearheads-20m-investment-in-ai-driven-reach-security/"
            />
            <Box />
          </Stack>
        </Box>
      </ContentMaxWidth>
    </LightMode>
  )
}

export default NewsBody
