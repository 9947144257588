/* eslint-disable react/no-unescaped-entities */
// eslint-disable-next-line react/no-unescaped-entities
import { Box, Icon, Button, LightMode, Center, Heading } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import FadeIn from 'components/Shared/FadeIn'
import { useApp } from 'context/App'
import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ContactSales = () => {
  const { isMobile } = useApp()
  const history = useHistory()
  const handleClick = () => {
    history.replace('/connect')
  }
  return (
    <LightMode>
      <Box bg="gray.50">
        <ContentMaxWidth
          display="block"
          py={isMobile ? '0px' : '140px'}
          justifyContent="center"
          color="#1a1a1a"
          bg="#141414"
        >
          <FadeIn>
            <Box bg="reachTeal.400" p={isMobile ? '64px 32px' : '64px'}>
              <Center>
                <Box>
                  <FadeIn>
                    <Heading
                      letterSpacing={-2}
                      textAlign="left"
                      size="2xl"
                      mb="32px"
                      pb="16px"
                      borderBottom="1px solid #111"
                    >
                      Contact Sales
                    </Heading>
                  </FadeIn>
                  <FadeIn delay={0.3}>
                    <Box
                      fontSize="26px"
                      letterSpacing={-0.75}
                      fontWeight="medium"
                      textAlign="left"
                      pb="32px"
                    >
                      Reach considers every possibility and guides you on the
                      single best course of action, automatically reprogramming
                      your security infrastructure based on who you are and how
                      you're being attacked.
                      <Button
                        h="72px"
                        w="100%"
                        rightIcon={<Icon as={AiOutlineArrowRight} />}
                        onClick={handleClick}
                        size="lg"
                        colorScheme="reachTeal"
                        fontSize="24px"
                        borderRadius="0"
                        mt="32px"
                      >
                        Contact Sales
                      </Button>
                    </Box>
                  </FadeIn>
                </Box>
              </Center>
            </Box>
          </FadeIn>
        </ContentMaxWidth>
      </Box>
    </LightMode>
  )
}

export default ContactSales
