import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Box,
  Center,
  Collapse,
  Flex,
  Grid,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import MotionBox from 'components/Shared/MotionBox'
import oneClick from './MiddleSection/content/oneclick_720.webp'
import productEffectiveness1 from './MiddleSection/content/product_effectiveness1.png'
import productEffectiveness2 from './MiddleSection/content/product_effectiveness2.png'
import productEffectiveness3 from './MiddleSection/content/product_effectiveness3.png'
import universalConfig1 from './MiddleSection/content/universal_config1.png'
import universalConfig2 from './MiddleSection/content/universal_config2.png'
import universalConfig3 from './MiddleSection/content/universal_config3.png'
import targetedDefense1 from './MiddleSection/content/targeted_defense1.png'
import targetedDefense2 from './MiddleSection/content/targeted_defense2.png'
import targetedDefense3 from './MiddleSection/content/targeted_defense3.png'
import targetedDefense4 from './MiddleSection/content/targeted_defense4.png'
import targetedDefense5 from './MiddleSection/content/targeted_defense5.png'
import toolsRationalizationBody from './MiddleSection/content/tools_rationalization_body.png'
import toolsRationalizationProduct1 from './MiddleSection/content/tools_rationalization_product_1.png'
import toolsRationalizationProduct2 from './MiddleSection/content/tools_rationalization_product_2.png'
import toolsRationalizationProduct3 from './MiddleSection/content/tools_rationalization_product_3.png'
import toolsRationalizationProduct4 from './MiddleSection/content/tools_rationalization_product_4.png'

import crowdstrikeImage from 'assets/images/product_crowdstrike.svg'
import paloAltoImage from 'assets/images/product_paloalto.svg'
import proofpointImage from 'assets/images/product_proofpoint.svg'
import s1Image from 'assets/images/product_sentinelone.svg'
import oktaImage from 'assets/images/product_okta.svg'
import azureAdImage from 'assets/images/azure-active-directory.svg'
import PaneButton from 'components/Shared/PaneButton'
import { USE_CASES_ROUTES } from 'components/Deprecated/UseCases'

const LogoItem = ({ image }) => {
  const { isMobile } = useApp()
  return (
    <Box w="100%" h={isMobile ? '180px' : '120px'} bg="white">
      <MotionBox h="100%">
        <Center w="100%" h="100%" px="32px">
          {image ? (
            <Image w="100%" maxW={isMobile ? '140px' : '100%'} src={image} />
          ) : (
            <AzureLogo />
          )}
        </Center>
      </MotionBox>
    </Box>
  )
}

LogoItem.propTypes = {
  image: PropTypes.any,
}

const AzureLogo = () => {
  const { isMobile } = useApp()
  return (
    <Flex alignItems="center" flexShrink={0}>
      <Image
        w={isMobile ? '32px' : '32px'}
        src={azureAdImage}
        mr={isMobile ? '4px' : '8px'}
      />
      <Box
        fontFamily="arial"
        lineHeight={1}
        fontSize="32px"
        letterSpacing={-0.2}
        color="#00bef2"
      >
        <Box fontSize="16px" ml="1px">
          Azure
        </Box>
        <Box fontSize={isMobile ? '14px' : '22px'}>Active Directory</Box>
      </Box>
    </Flex>
  )
}

export const AnimImage = ({ isIn, delay, x, y, h, w, src, ...remaining }) => (
  <MotionBox
    position="absolute"
    animate={
      isIn ? { scale: 1, opacity: 1, x, y } : { scale: 0, opacity: 0, x, y }
    }
    initial={{ scale: 0, opacity: 0, x, y }}
    transition={
      isIn ? { delay: delay + 0.4, type: 'spring', bounce: 0.1 } : { delay: 0 }
    }
  >
    <Image h={h} w={w} borderRadius="8px" src={src} {...remaining} />
  </MotionBox>
)

AnimImage.propTypes = {
  isIn: PropTypes.bool,
  src: PropTypes.string,
  delay: PropTypes.number,
  x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export const ProductEffectivenessImages = ({ isIn, ...remaining }) => {
  const { isMobile } = useApp()
  return (
    <>
      <AnimImage
        isIn={isIn}
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        border="1px solid #DDE0E8"
        borderRadius="16px"
        src={productEffectiveness1}
        x={isMobile ? '-8px' : '32px'}
        y="0px"
        delay={0}
        {...remaining}
      />
      <AnimImage
        isIn={isIn}
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        border="1px solid #DDE0E8"
        src={productEffectiveness3}
        x="40px"
        y="16px"
        delay={0.2}
        h={isMobile ? '200px' : '420px'}
      />
      <AnimImage
        isIn={isIn}
        src={productEffectiveness2}
        x={isMobile ? '80px' : '240px'}
        y="100px"
        delay={0.4}
        h={isMobile ? '120px' : '260px'}
      />
    </>
  )
}
ProductEffectivenessImages.propTypes = {
  isIn: PropTypes.bool,
}

export const OneClickImages = ({ isIn, ...remaining }) => {
  return (
    <>
      <AnimImage
        isIn={isIn}
        boxShadow="1px 2px 16px rgba(0,0,0,.25)"
        border="1px solid #DDE0E8"
        borderRadius="16px"
        src={oneClick}
        delay={0}
        {...remaining}
      />
    </>
  )
}
OneClickImages.propTypes = {
  isIn: PropTypes.bool,
}

export const TargetedDefenseImages = ({ isIn }) => {
  const { isMobile } = useApp()
  return (
    <>
      {!isMobile && (
        <AnimImage
          isIn={isIn}
          borderRadius="12px"
          boxShadow="1px 2px 8px rgba(0,0,0,.05)"
          src={targetedDefense1}
          x={isMobile ? '-8px' : '124px'}
          y="52px"
          h={isMobile ? null : '320px'}
          w={isMobile ? '95%' : 'auto'}
          delay={0}
        />
      )}
      <AnimImage
        isIn={isIn}
        borderRadius="8px"
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        src={targetedDefense2}
        x={isMobile ? '160px' : '96px'}
        y={isMobile ? '0px' : '-48px'}
        h={isMobile ? '60px' : '96px'}
        delay={0.3}
      />
      <AnimImage
        isIn={isIn}
        borderRadius="8px"
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        src={targetedDefense3}
        x={isMobile ? '160px' : '320px'}
        y={isMobile ? '68px' : '-48px'}
        h={isMobile ? '60px' : '96px'}
        delay={0.6}
      />
      <AnimImage
        isIn={isIn}
        borderRadius="8px"
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        src={targetedDefense4}
        x={isMobile ? '160px' : '544px'}
        y={isMobile ? '136px' : '-48px'}
        h={isMobile ? '60px' : '96px'}
        delay={0.9}
      />
      <AnimImage
        isIn={isIn}
        borderRadius="12px"
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        src={targetedDefense5}
        x={isMobile ? '0' : '-40px'}
        y={isMobile ? '0px' : '40px'}
        h={isMobile ? '240px' : '260px'}
        delay={0.3}
      />
    </>
  )
}
TargetedDefenseImages.propTypes = {
  isIn: PropTypes.bool,
}

export const UniversalConfigImages = ({ isIn }) => {
  const { isMobile } = useApp()
  return (
    <>
      <AnimImage
        isIn={isIn}
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        borderRadius="8px"
        border="1px solid #DDE0E8"
        src={universalConfig1}
        x="0px"
        y="-8px"
        h={isMobile ? null : '480px'}
        w={isMobile ? '90%' : null}
        delay={0}
      />
      <AnimImage
        isIn={isIn}
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        borderRadius="24px"
        border="1px solid #DDE0E8"
        bg="gray.50"
        src={universalConfig2}
        x={isMobile ? '60px' : '140px'}
        y="44px"
        h={isMobile ? '200px' : '420px'}
        delay={0.4}
      />
      <AnimImage
        isIn={isIn}
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        borderRadius="8px"
        border="1px solid #DDE0E8"
        src={universalConfig3}
        x={isMobile ? '68px' : '194px'}
        y={isMobile ? '102px' : '2px'}
        h={isMobile ? '80px' : '180px'}
        delay={0.6}
      />
    </>
  )
}
UniversalConfigImages.propTypes = {
  isIn: PropTypes.bool,
}

export const ToolsRationalizationImages = ({ isIn }) => {
  const { isMobile } = useApp()
  return (
    <>
      <AnimImage
        isIn={isIn}
        boxShadow="1px 2px 16px rgba(0,0,0,.4)"
        borderRadius="8px"
        border="1px solid #1D1E21"
        src={toolsRationalizationBody}
        x="0px"
        y="0px"
        h={isMobile ? null : 'auto'}
        w={isMobile ? '440px' : '100%'}
        delay={0}
      />
      <AnimImage
        isIn={isIn}
        src={toolsRationalizationProduct1}
        x={isMobile ? '5px' : '-88px'}
        h={isMobile ? '150px' : 'auto'}
        w={isMobile ? null : '25.5%'}
        y={isMobile ? '64px' : '70px'}
        delay={0.4}
      />
      <AnimImage
        isIn={isIn}
        src={toolsRationalizationProduct2}
        x={isMobile ? '5px' : '84px'}
        h={isMobile ? '150px' : 'auto'}
        w={isMobile ? null : '25.5%'}
        y={isMobile ? '64px' : '70px'}
        delay={0.6}
      />
      <AnimImage
        isIn={isIn}
        src={toolsRationalizationProduct3}
        x={isMobile ? '5px' : '258px'}
        h={isMobile ? '150px' : 'auto'}
        w={isMobile ? null : '25.5%'}
        y={isMobile ? '64px' : '70px'}
        delay={0.8}
      />
      <AnimImage
        isIn={isIn}
        src={toolsRationalizationProduct4}
        x={isMobile ? '5px' : '432px'}
        h={isMobile ? '150px' : 'auto'}
        w={isMobile ? null : '25.5%'}
        y={isMobile ? '64px' : '70px'}
        delay={1}
      />
    </>
  )
}
ToolsRationalizationImages.propTypes = {
  isIn: PropTypes.bool,
}

const MiddleHomeSection = () => {
  const { isMobile } = useApp()
  const history = useHistory()
  const [selectedPane, setSelectedPane] = useState(0)

  const onLearnMoreClick = () => {
    let route
    switch (selectedPane) {
      case 0:
        route = USE_CASES_ROUTES.PRODUCT_EFFECTIVENESS
        break
      case 1:
        route = USE_CASES_ROUTES.UNIVERSAL_CONFIG
        break
      case 2:
        route = USE_CASES_ROUTES.TARGETED_DEFENSES
        break
      default:
        route = USE_CASES_ROUTES.PRODUCT_EFFECTIVENESS
    }
    history.push(route)
    if (!isMobile) {
      window.scrollTo(0, 0)
    }
  }

  return (
    <AnimateSharedLayout>
      <Box bg="white">
        <ContentMaxWidth mt="128px" justifyContent="space-between">
          <Box>
            <Text
              fontSize={isMobile ? '24px' : '28px'}
              lineHeight={isMobile ? '28px' : '35px'}
              mb={isMobile && '8px'}
              letterSpacing="-2%"
              color="black"
            >
              Integrate Reach to improve your defensive posture with the tools
              already in place.
            </Text>
          </Box>
        </ContentMaxWidth>
        <ContentMaxWidth mt="64px" justifyContent="space-between">
          <Grid
            gridGap="1px"
            bg="gray.100"
            gridTemplateColumns={
              isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr 1fr 1fr'
            }
          >
            <LogoItem image={crowdstrikeImage} />
            <LogoItem image={paloAltoImage} />
            <LogoItem image={proofpointImage} />
            <LogoItem image={s1Image} />
            <LogoItem />
            <LogoItem image={oktaImage} />
          </Grid>
          <Text my="16px" opacity={0.8}>
            And more...
          </Text>
        </ContentMaxWidth>
        <ContentMaxWidth
          mt="32px"
          mb={isMobile ? '0px' : '128px'}
          justifyContent="space-between"
        >
          <Stack spacing="16px">
            {isMobile && (
              <Collapse in={selectedPane === 3} animateOpacity>
                <Box h="300px">
                  <Box h="180px">
                    <ProductEffectivenessImages isIn={selectedPane === 3} />
                  </Box>
                  <Button
                    transform="translateY(64px)"
                    size="lg"
                    bg="reachBlue.500"
                    color="white"
                    onClick={onLearnMoreClick}
                  >
                    Learn More
                  </Button>
                </Box>
              </Collapse>
            )}

            <PaneButton
              paneIndex={0}
              selectedPane={selectedPane}
              setSelectedPane={setSelectedPane}
              heading="Defensive Posture Automation"
              body="Automate the deployment of product features available to you, from basic knobs to advanced content, with a couple of clicks."
            />

            {isMobile && (
              <Collapse in={selectedPane === 0} animateOpacity>
                <Box h="300px">
                  <Box h="180px">
                    <OneClickImages isIn={selectedPane === 0} />
                  </Box>
                  <Button
                    transform="translateY(64px)"
                    size="lg"
                    bg="reachBlue.500"
                    color="white"
                    onClick={onLearnMoreClick}
                  >
                    Learn More
                  </Button>
                </Box>
              </Collapse>
            )}

            <PaneButton
              paneIndex={1}
              selectedPane={selectedPane}
              setSelectedPane={setSelectedPane}
              heading="Tools Rationalization"
              body="Immediate understanding of each tool’s ability to stop threats relevant to your organization."
            />

            {isMobile && (
              <Collapse in={selectedPane === 1} animateOpacity>
                <Box h="300px">
                  <Box h="200px">
                    <ToolsRationalizationImages isIn />
                  </Box>
                  <Button
                    transform="translateY(64px)"
                    size="lg"
                    bg="reachBlue.500"
                    color="white"
                    onClick={onLearnMoreClick}
                  >
                    Learn More
                  </Button>
                </Box>
              </Collapse>
            )}

            <PaneButton
              paneIndex={2}
              selectedPane={selectedPane}
              setSelectedPane={setSelectedPane}
              heading="Control Prioritization"
              body="Automatically introduce additional control hardening for the 5% of employees who account for +80% of the risk."
            />

            {isMobile && (
              <Collapse in={selectedPane === 2} animateOpacity>
                <Box h="300px">
                  <Box h="200px">
                    <TargetedDefenseImages isIn />
                  </Box>
                  <Button
                    transform="translateY(64px)"
                    size="lg"
                    bg="reachBlue.500"
                    color="white"
                    onClick={onLearnMoreClick}
                  >
                    Learn More
                  </Button>
                </Box>
              </Collapse>
            )}
          </Stack>
          <Box flex={1} h="100%" px="40px">
            <Grid gridTemplateRows="120px 1fr" color="#111">
              {!isMobile && (
                <Center position="relative">
                  <AnimatePresence>
                    {selectedPane === 0 && (
                      <MotionBox
                        mb="8px"
                        key="middle-header-1"
                        animate={{ opacity: 1, transition: { delay: 0.5 } }}
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        position="absolute"
                      >
                        <Text textAlign="center" fontSize="21px" mb="40px">
                          Automatically push control recommendations back to
                          products to improve your defensive posture
                        </Text>
                      </MotionBox>
                    )}
                    {selectedPane === 1 && (
                      <MotionBox
                        key="middle-header-2"
                        animate={{ opacity: 1, transition: { delay: 0.5 } }}
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        position="absolute"
                      >
                        <Text textAlign="center" fontSize="24px" mb="56px">
                          Measure and manage the value from your tools over time
                        </Text>
                      </MotionBox>
                    )}
                    {selectedPane === 2 && (
                      <MotionBox
                        key="middle-header-3"
                        animate={{ opacity: 1, transition: { delay: 0.5 } }}
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        position="absolute"
                      >
                        <Text textAlign="center" fontSize="24px" mb="40px">
                          Start where you have the biggest impact
                        </Text>
                      </MotionBox>
                    )}
                  </AnimatePresence>
                </Center>
              )}
              {!isMobile && (
                <Flex direction="column" alignItems="flex-end">
                  <Center position="relative" w="100%" h="400px">
                    <OneClickImages isIn={selectedPane === 0} />
                    <ToolsRationalizationImages isIn={selectedPane === 1} />
                    <TargetedDefenseImages isIn={selectedPane === 2} />
                  </Center>
                  <Button
                    transform="translateY(64px)"
                    size="lg"
                    bg="reachBlue.500"
                    color="white"
                    onClick={onLearnMoreClick}
                  >
                    Learn More
                  </Button>
                </Flex>
              )}
            </Grid>
          </Box>
        </ContentMaxWidth>
      </Box>
    </AnimateSharedLayout>
  )
}

export default MiddleHomeSection
