import React from 'react'
import { useApp } from 'context/App'
import { NavLink } from 'react-router-dom'
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  LightMode,
} from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import FadeIn from 'components/Shared/FadeIn'
import { AiOutlineArrowRight } from 'react-icons/ai'

const ProductSplashArea = () => {
  const { isMobile } = useApp()
  return (
    <>
      <LightMode>
        <Box
          minH="800px"
          pt={isMobile ? '120px' : '240px'}
          color="black"
          bg="white"
        >
          <ContentMaxWidth maxW="1600px">
            <Flex alignItems="center" justifyContent="center" w="100%" h="100%">
              <Flex
                w="100%"
                flexDir={isMobile ? 'column' : 'row'}
                justifyContent="center"
                alignItems="flex-start"
                mb={isMobile ? '32px' : '56px'}
              >
                <Box maxW="720px" mr={!isMobile && '16px'}>
                  <FadeIn>
                    <Heading
                      fontSize={isMobile ? 40 : 56}
                      lineHeight={1.25}
                      textAlign="center"
                      color="gray.900"
                      mb="24px"
                    >
                      Unlock the full power of your security tools.
                    </Heading>
                  </FadeIn>
                  <FadeIn delay={0.35}>
                    <Box
                      fontSize={isMobile ? 20 : 24}
                      mb="32px"
                      textAlign="center"
                      fontWeight="light"
                      lineHeight={1.3}
                      letterSpacing={isMobile && '0px'}
                    >
                      Reach considers every possibility and guides you on the
                      single best course of action, automatically reprogramming
                      your security infrastructure based on who you are and how
                      you’re being attacked.
                    </Box>
                  </FadeIn>
                  <FadeIn delay={0.7}>
                    <Center>
                      <Button
                        h="64px"
                        as={NavLink}
                        to="/connect"
                        rightIcon={<Icon as={AiOutlineArrowRight} />}
                        size="lg"
                        colorSheme="reachBlue"
                        fontSize="20px"
                        mb={isMobile ? '56px' : '0px'}
                      >
                        Request a demo
                      </Button>
                    </Center>
                  </FadeIn>
                </Box>
                {/* <Box
                  h="480px"
                  flex={1}
                  bgSize="cover"
                  bgPos="50% 50%"
                  border="6px solid black"
                  bgImage="url(https://media.giphy.com/media/5IzEsw8GJXUrTjVHF9/giphy.gif)"
                /> */}
              </Flex>
            </Flex>
          </ContentMaxWidth>
        </Box>
      </LightMode>
    </>
  )
}

export default ProductSplashArea
