import React, { useEffect } from 'react'
import { Box, Flex, Heading, LightMode, Link } from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import ReactPlayer from 'react-player'
import morey from '../AuthorImages/morey.jpeg'
import BlogAuthor from '../../BlogAuthor'

const NYSEInterviewBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Zero Trust on NYSE Taking Stock: A Conversation with
                Reach&apos;s CEO
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">November 4, 2024</Box>
                <BlogAuthor name="CP Morey" img={morey} />
                <Box fontSize="12px">3 minute watch</Box>
              </Flex>

              <Box mb="16px">
                Reach Security CEO & Co-Founder Garrett Hamilton sits down with
                Trinity Chavez of the NYSE to explore how Reach takes
                organizations from &ldquo;architecture to action&rdquo; with
                Zero Trust. Garrett explains how Reach uses context from
                analyzing your existing configurations, licensing, and attack
                patterns to prioritize controls, helping teams deploy
                capabilities like conditional access to enhance security while
                creating a seamless user experience. He also shares insights on
                the game-changing potential of AI, showing how, when applied
                thoughtfully, it enables defenders to respond faster to threats.
              </Box>

              <Box mb="32px">
                Garrett discusses some of the most pressing issues facing
                cybersecurity leaders today, from the dual role of AI in both
                defense and offense to the complexities of implementing a robust
                Zero Trust Architecture. Through these insights, he illustrates
                how Reach empowers security leaders to reduce risk, optimize
                ROI, and drive trust across their organizations.
              </Box>

              <Box mb="32px" className="player-wrapper">
                <ReactPlayer
                  url="/videos/nyse_reach_interview.mp4"
                  width="100%"
                  height="100%"
                  controls={true}
                  playing={false}
                  className="react-player"
                  style={{
                    aspectRatio: '16/9',
                  }}
                />
              </Box>

              <Box>
                Reach is the first purpose-built platform that closes the gap
                between assessing your security posture and reducing your
                exposure through operational changes using configuration guides,
                workflows, and automation - all contextualized and prioritized
                to maximize the efficacy of your existing tech stack. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default NYSEInterviewBlog
