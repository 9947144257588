import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { Box, Stack } from '@chakra-ui/react'
import { useApp } from 'context/App'
import { Switch, Route, withRouter, NavLink } from 'react-router-dom'

import styled from '@emotion/styled'

import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'

import termsOfService from './markdown/termsOfService.md'
import securityAndPrivacy from './markdown/security_and_privacy.md'
import serviceLevelAgreement from './markdown/serviceLevelAgreement.md'
import reachSubprocessors from './markdown/reachSubprocessors.md'
import privacyPolicyPostLaunch from './markdown/privacyPolicyPostLaunch.md'
import privacyPolicy from './markdown/privacyPolicy.md'
import enterpriseSubscriptionAgreement from './markdown/enterpriseSubscriptionAgreement.md'
import dataProcessing from './markdown/dataProcessing.md'
import Agreement from './Agreement'

const StyleWrapper = styled.div`
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

  table {
    border: 1px solid white;
    padding: 16px;
    margin-bottom: 32px;
    background: #f9f9fb;
  }

  th {
    padding: ${({ isMobile }) => (isMobile ? '4px' : '12px 16px')};
    font-size: ${({ isMobile }) => (isMobile ? '16px' : '24px')};
    border: 1px solid #bfc4d4;
  }

  td {
    padding: ${({ isMobile }) => (isMobile ? '4px' : '12px 16px')};
    border: 1px solid #bfc4d4;
    vertical-align: top;
    font-size: ${({ isMobile }) => (isMobile ? '14px' : '16px')};
  }

  li {
    margin-left: ${({ isMobile }) => (isMobile ? '8px' : '32px')};
    line-height: ${({ isMobile }) => (isMobile ? '1.5' : '1.7')};
    margin-bottom: 8px;
  }

  ol {
    margin-bottom: 24px;
    margin-bottom: 16px;
    color: #111;
    font-weight: 400;
    font-size: 14px;
  }

  a {
    font-weight: bold;
    color: #26386a;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-bottom: 14px;
    line-height: ${({ isMobile }) => (isMobile ? '1.4' : '1.6')};
    color: #111;
    font-weight: 200;
    font-size: 18px;
  }

  h1 {
    font-family: 'Craftworkgrotesk', 'Satoshi', sans-serif;
    font-weight: 800;
    font-size: ${({ isMobile }) => (isMobile ? '40px' : '56px')};
    margin-bottom: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-left: 6px solid white;
  }
  h2 {
    font-family: 'Craftworkgrotesk', 'Satoshi', sans-serif;
    font-weight: bold;
    font-size: ${({ isMobile }) => (isMobile ? '32px' : '40px')};
    font-weight: 800;
    margin-bottom: 16px;
  }
  h3 {
    font-family: 'Craftworkgrotesk', 'Satoshi', sans-serif;
    font-weight: bold;
    font-size: ${({ isMobile }) => (isMobile ? '24px' : '32px')};
    font-weight: 800;
    margin-bottom: 16px;
  }
  h4 {
    font-family: 'Craftworkgrotesk', 'Satoshi', sans-serif;
    font-weight: bold;
    font-size: ${({ isMobile }) => (isMobile ? '22px' : '24px')};
    font-weight: 800;
    margin-bottom: 16px;
  }
  h5 {
    font-family: 'Craftworkgrotesk', 'Satoshi', sans-serif;
    font-weight: bold;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
`

const TermsLink = ({ to = '/', ...props }) => {
  const { isMobile } = useApp()
  return (
    <NavLink
      to={to}
      {...props}
      style={(isActive) =>
        isActive
          ? {
              padding: '8px 24px',
              background: '#26386A',
              color: 'white',
              fontWeight: 800,
              fontSize: isMobile ? '20px' : '16px',
              textDecoration: 'none',
            }
          : {
              padding: '8px 24px',
              borderLeft: '4px solid transparent',
              fontWeight: 300,
              fontSize: isMobile ? '20px' : '16px',
              textDecoration: 'none',
            }
      }
    />
  )
}

TermsLink.propTypes = {
  history: PropTypes.object,
  to: PropTypes.string,
}

const JoinUs = ({ history }) => {
  const { isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [history.location.pathname])

  return (
    <>
      <PageHeader />
      <Box color="#111" py="96px" minH="100vh">
        <StyleWrapper isMobile={isMobile}>
          <ContentMaxWidth
            alignItems="flex-start"
            pt={isMobile ? '40px' : '116px'}
            px={isMobile ? '24px' : '32px'}
            mb="6vh"
          >
            <Box w={isMobile ? '100%' : '260px'} mr="80px">
              <Box
                w={isMobile ? '100%' : '260px'}
                border="1px solid"
                borderColor="gray.300"
                bg="gray.50"
                py={isMobile ? '8px' : '16px'}
                px={isMobile ? '8px' : '8px'}
                position={isMobile ? 'block' : 'fixed'}
                mb={isMobile ? '16px' : '0'}
              >
                <Stack spacing="8px">
                  <TermsLink history={history} to="/terms/ToS">
                    Terms of Service
                  </TermsLink>

                  <TermsLink history={history} to="/terms/security-and-privacy">
                    Security & Privacy
                  </TermsLink>

                  <TermsLink history={history} to="/terms/service-level">
                    Service Level Agreement
                  </TermsLink>

                  <TermsLink history={history} to="/terms/privacy-policy">
                    Privacy Policy
                  </TermsLink>
                  {/* <TermsLink
                  history={history}
                  to="/terms/privacy-policy-post-launch"
                >
                  Post Launch Privacy
                </TermsLink> */}
                  <TermsLink
                    history={history}
                    to="/terms/enterprise-subscription"
                  >
                    Subscription Agreement
                  </TermsLink>
                  <TermsLink history={history} to="/terms/data-processing">
                    Data Processing
                  </TermsLink>
                  <TermsLink history={history} to="/terms/subprocessors">
                    Reach Subprocessors
                  </TermsLink>
                </Stack>
              </Box>
            </Box>

            <Box flex={1} key={history.location.pathname}>
              <Switch>
                <Route exact path="/terms/ToS">
                  <Agreement markdown={termsOfService} />
                </Route>
                <Route exact path="/terms/security-and-privacy">
                  <Agreement markdown={securityAndPrivacy} />
                </Route>
                <Route path="/terms/service-level">
                  <Agreement markdown={serviceLevelAgreement} />
                </Route>
                <Route path="/terms/subprocessors">
                  <Agreement markdown={reachSubprocessors} />
                </Route>
                <Route path="/terms/privacy-policy-post-launch">
                  <Agreement markdown={privacyPolicyPostLaunch} />
                </Route>
                <Route path="/terms/privacy-policy">
                  <Agreement markdown={privacyPolicy} />
                </Route>
                <Route path="/terms/enterprise-subscription">
                  <Agreement markdown={enterpriseSubscriptionAgreement} />
                </Route>
                <Route path="/terms/data-processing">
                  <Agreement markdown={dataProcessing} />
                </Route>
              </Switch>
            </Box>
          </ContentMaxWidth>
        </StyleWrapper>
      </Box>
      <PageFooter />
    </>
  )
}

JoinUs.propTypes = {
  history: PropTypes.object,
}

export default withRouter(JoinUs)
