/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import {
  Box,
  Flex,
  Heading,
  LightMode,
  Link,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'

import B from 'components/Shared/B'
import { BlogImage } from '../TRABlog'
import BlogAuthor from '../../BlogAuthor'
import reachTra from './reach_tra.png'
import jiraCreation from './jira_creation.png'
import stageContent from './stage_content.png'
import email from './email.png'
import trent from '../AuthorImages/trent.png'

const LastMileBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                The Last Mile in Cybersecurity: Turning Assessments into Action
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">July 10, 2024</Box>
                <BlogAuthor name="Trent Weber" img={trent} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mb="16px">
                In many fields, the "last mile" represents the final, often most
                challenging step of a process. In logistics, it's the segment
                where goods move from the warehouse to the customer's doorstep.
                In telecommunications, the last mile connects the network to the
                end user's home. These last mile challenges highlight the
                importance of execution in getting the desired outcome.
              </Box>
              <Box mb="16px">
                In cybersecurity, the "last mile" is no different. It's not
                about acquiring more security tools—most organizations already
                have a wide range of advanced controls in place. The challenge
                lies in effectively utilizing these existing security controls
                to bridge the gap between assessing the risk and implementing
                the capabilities to manage it.
              </Box>
              <Box mb="16px">
                The emergence of the Automated Security Control Assessment
                (ASCA) market brings a solution to security teams striving to
                optimize the security tools they already own. As this market
                evolves, several vendors have begun offering ASCA solutions,
                each bringing unique capabilities to the table. However, the
                degree of adoption and the progress in deploying these ASCA
                products vary significantly among security teams. The concept of
                a maturity model becomes particularly valuable in these
                situations.
              </Box>

              <Box mb="16px">
                A maturity model helps organizations gauge their current stance
                and provides a clear roadmap for advancing their security
                practices. By understanding where they stand on a maturity
                continuum, security teams can better navigate the complexities
                of new markets and make informed decisions that enhance their
                operational efficiency and security posture.
              </Box>

              <Box mb="16px">
                In collaboration with customers, analysts, and other industry
                stakeholders, we’ve started to frame a maturity model for ASCA,
                and would like to share one dimension of it here. Our aim is to
                help security teams fully leverage the potential of their
                existing controls, transforming them into more effective
                defenses against the threats they face.
              </Box>

              <Box mb="64px">
                Here is a general overview of the maturity model, designed to
                guide organizations through the crucial phases of deploying and
                optimizing their security controls:
                <Heading mt="64px" size="md">
                  Level 0: Unaligned
                </Heading>
                <UnorderedList mb="16px">
                  <ListItem mt="16px">
                    At this stage, the capabilities teams have at their disposal
                    are not aligned with the risks they face. Key features go
                    unused despite offering the potential to improve the
                    organization's security posture. Teams struggle to
                    articulate even basic assessments of value related to their
                    tooling.
                  </ListItem>
                </UnorderedList>
                <Heading size="md">Level 1: Assessment</Heading>
                <UnorderedList mb="16px">
                  <ListItem mt="16px">
                    At this stage, teams can assess the risks they face and
                    compare it to the security capabilities that should be
                    activated based on the tools the organization already owns.
                    While an improvement from Level 0, many teams still struggle
                    to implement the assessment's recommendations, which
                    continues to affect their security posture. Level 1
                    represents an organization that sees the potential for
                    improvement but cannot or has not implemented the changes to
                    realize it.
                  </ListItem>
                </UnorderedList>
                <Heading size="md">Level 2: Action</Heading>
                <UnorderedList mb="16px">
                  <ListItem mt="16px">
                    Moving beyond reports, this level involves integrating the
                    necessary actions into the organization's workflow
                    management system. These actions are pre-defined with
                    detailed steps tailored to the specific security needs. This
                    ensures that the recommendations are actionable and
                    seamlessly integrated into the daily operations, making it
                    easier to follow through and implement the necessary
                    measures. Organizations at Level 2 are addressing the “last
                    mile” challenge effectively utilizing their existing
                    security controls to bridge the gap between assessing the
                    risk and implementing the capabilities to manage it.
                  </ListItem>
                </UnorderedList>
                <Heading size="md">Level 3: Automation </Heading>
                <UnorderedList mb="16px">
                  <ListItem mt="16px">
                    The pinnacle of the model involves automating the
                    implementation of security changes. In a controlled
                    environment, changes are automatically applied with
                    stakeholder change and approval requests incorporated into
                    the deployment process. This not only accelerates the
                    process but also reduces the margin for error and ensures
                    consistency in operations. Level 3 organizations have fully
                    addressed the “last mile” challenge by not only implementing
                    the necessary controls, but also by doing it in a way that
                    maximizes efficiency.
                  </ListItem>
                </UnorderedList>
              </Box>

              <Box mb="64px">
                With the introduction of a maturity model, it's a logical next
                step for us at Reach to utilize this framework to assess how we
                can best support our customers in progressing through each
                stage.
              </Box>

              <Heading size="lg" mb="16px">
                How Reach Implements the Maturity Model
              </Heading>

              <Box mb="64px">
                <Heading size="md" mb="16px">
                  Level 1: Assessment -> Report
                </Heading>
                <UnorderedList mb="16px">
                  <ListItem mt="16px" mb="16px">
                    We provide an assessment report that identifies security
                    capabilities needing activation based on your existing
                    tools. This is the first step towards a more secure
                    environment.
                  </ListItem>
                  <BlogImage margin="0 auto" width="75%" src={reachTra} />
                  <Box fontStyle="italic" fontSize="16px">
                    Point in time assessment of licensed capabilities, the
                    extent they’re being leveraged, and ways in which they can
                    be utilized in combination to address risk.
                  </Box>
                </UnorderedList>
              </Box>

              <Box mb="64px">
                <Heading size="md" mb="16px">
                  Level 2: Action -> Ticket Creation
                </Heading>
                <UnorderedList mb="16px">
                  <ListItem mt="16px" mb="16px">
                    We take it a step further by integrating detailed actions
                    into your workflow management system. These actions contain
                    specific steps for your security needs, ensuring the
                    recommendations are actionable and seamlessly incorporated
                    into your daily operations.
                  </ListItem>
                  <BlogImage margin="0 auto" src={jiraCreation} />
                  <Box fontStyle="italic" fontSize="16px">
                    Integration into Jira that maps the change to the proper
                    project, issue type, and owner within the org.
                  </Box>
                </UnorderedList>
              </Box>

              <Box mb="64px">
                <Heading size="md" mb="16px">
                  Level 3: Automation -> Changes Staged
                </Heading>
                <UnorderedList mb="16px">
                  <ListItem mt="16px" mb="16px">
                    Finally, we automate these changes in a controlled
                    environment. This approach allows for review and approval,
                    speeding up the process and reducing errors, while ensuring
                    uniform best practices.
                  </ListItem>
                  <BlogImage mb="64px" src={stageContent} />
                  <BlogImage src={email} />
                  <Box fontStyle="italic" fontSize="16px">
                    Policies are staged directly back to a test or preview
                    instance for easy rollover into production.
                  </Box>
                </UnorderedList>
              </Box>

              <Box>
                <Heading size="md" mb="16px">
                  Why the Last Mile Matters
                </Heading>
                <Heading size="sm" mb="16px">
                  Implementing security measures effectively offers numerous
                  benefits:
                </Heading>
                <UnorderedList>
                  <ListItem>
                    <B>Better Security:</B> By ensuring that recommendations are
                    acted upon, the overall security posture is strengthened.{' '}
                  </ListItem>
                  <ListItem>
                    <B>Faster Implementation:</B> Automated and pre-populated
                    actions speed up the process, allowing for quicker response
                    times.
                  </ListItem>
                  <ListItem>
                    <B>Consistency:</B> Standardized procedures reduce the
                    likelihood of human error and ensure that impactful changes
                    are actioned on in a uniform manner.
                  </ListItem>
                  <ListItem>
                    <B>Improved Job Satisfaction:</B> Automating low-value tasks
                    like ticket creation frees up security professionals to
                    focus on more strategic work, enhancing job satisfaction and
                    retention.
                  </ListItem>
                </UnorderedList>
              </Box>

              <Box mb="64px">
                The last mile is a critical component of cybersecurity
                operations. By embracing a comprehensive approach that goes
                beyond mere reports to actionable and automated implementation,
                Reach ensures that your organization is not only secure but also
                agile and efficient. Stay tuned for our upcoming posts where we
                will delve deeper into specific use cases and solutions that
                exemplify our commitment to mastering the last mile in
                cybersecurity.
              </Box>

              <Heading size="md">More About Reach Security</Heading>
              <br />
              <Box>
                Reach is the first AI purpose-built to reprogram your security
                infrastructure based on who you are and how you're being
                attacked. Organizations of all sizes trust Reach to make
                mission-critical decisions because it doesn't hallucinate and it
                doesn't make mistakes. It's a different kind of AI. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default LastMileBlog
