import React from 'react'
import { Flex } from '@chakra-ui/react'
import SplashArea from './SplashArea'
import Body from './Body'
import BackgroundTopology from 'components/Shared/BackgroundTopology'
import bgTopo from 'assets/images/background_topo.png'
import { useApp } from 'context/App'

const Customers = () => {
  const { isMobile } = useApp()

  return (
    <BackgroundTopology
      pt={isMobile ? '24px' : '90px'}
      color="white"
      overflow="visible"
      bgImage={bgTopo}
      bgSize="cover"
      desktopparallax={3}
      mobileparallax={6}
    >
      <Flex flexDir="column" minH="100vh">
        <SplashArea />
        <Body />
      </Flex>
    </BackgroundTopology>
  )
}

export default Customers
