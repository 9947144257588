/* eslint-disable react/no-unescaped-entities */
import { Box, Flex, Heading, LightMode, Link } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import PageHeader from 'components/Shared/PageHeader'
import { useApp } from 'context/App'
import React, { useEffect } from 'react'
import BlogAuthor from '../../BlogAuthor'
import { BlogImage } from '../TRABlog'
import PageFooter from 'components/Shared/PageFooter'
import nick from '../AuthorImages/nick3.png'
import proofpointTap from './proofpointTap.png'
import detect from './detect.png'

const D3fendBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])
  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Countering Phishing and Credential Theft with MITRE D3FEND: How
                Reach Keeps You Ahead of the Threats
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">September 12, 2024</Box>
                <BlogAuthor name="Nick Roy" img={nick} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mb="16px">
                Phishing and credential theft remain two of the top methods
                adversaries use to breach networks. To counter these
                effectively, it’s not enough to understand the attacks
                themselves—you also need a strong defensive strategy. That’s
                where a framework like MITRE D3FEND can make all the difference,
                providing a structured approach to securing your organization.
              </Box>
              <Box mb="16px">
                For those unfamiliar, MITRE D3FEND is a knowledge base designed
                to focus on defensive techniques, as opposed to MITRE ATT&CK,
                which focuses on attack tactics, techniques, and procedures
                (TTPs). D3FEND helps organizations deploy and configure security
                tools more effectively, identifying gaps and overlaps while
                ensuring the right defenses are in place.
              </Box>

              <Heading size="md" mb="16px">
                How Reach Extends D3FEND's Capabilities
              </Heading>

              <Box mb="16px">
                Reach goes beyond simply aligning with D3FEND by integrating
                data from email security, endpoint, and identity tools to offer
                actionable recommendations based on the actual threats observed
                in your environment. This means that instead of generic
                guidance, you get tailored advice specific to both your
                organization and the current threat landscape.
              </Box>

              <Box mb="16px">
                To illustrate, let’s break down how Reach could be applied to a
                real-world attack, using a{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://media.defense.gov/2023/Jun/01/2003234055/-1/-1/0/JOINT_CSA_DPRK_SOCIAL_ENGINEERING.PDF"
                >
                  2023 CISA advisory about a Kimsuky phishing campaign
                </Link>
                . This campaign demonstrated TTPs for gaining initial access,
                executing malicious code, and maintaining persistence. By
                mapping this to the D3FEND framework, we can show how Reach
                enhances your defense at each stage of the attack.
              </Box>

              <Heading size="md" mb="16px">
                Message Analysis (D3-MA)
              </Heading>

              <Box mb="64px">
                In the Kimsuky campaign, spear-phishing emails were used to
                deliver malicious payloads. This highlights the need for
                thorough email analysis to detect and block these threats early.
                Reach ingests data from tools like Proofpoint to understand who
                within your organization is being targeted and how the attacks
                are being delivered. With this information, Reach provides
                precise recommendations for configuring Proofpoint, ensuring
                it’s optimized to detect and block phishing attempts.
              </Box>

              <BlogImage mb="64px" src={proofpointTap} />

              <Heading size="md" mb="16px">
                Script Execution Analysis (D3-SEA)
              </Heading>

              <Box mb="16px">
                If a phishing email bypasses initial defenses, adversaries often
                try to trick users into executing malicious scripts. Kimsuky
                attackers used scripts to maintain persistence, reinforcing the
                need for strong endpoint controls. Reach analyzes data from
                previous attacks to recommend enhanced endpoint protections,
                focusing on stricter controls for the users most frequently
                targeted.
              </Box>

              <Heading size="md" mb="16px">
                Multi-factor Authentication (D3-MFA)
              </Heading>

              <Box mb="64px">
                Kimsuky attackers also compromised user credentials,
                underscoring the importance of robust multi-factor
                authentication (MFA) policies. Reach identifies high-risk users
                who need stronger MFA configurations and recommends less strict
                policies for users not currently under attack. By continuously
                analyzing attack data, Reach ensures your MFA settings are
                always aligned with the latest threat patterns.
              </Box>

              <BlogImage margin="0 auto" src={detect} />

              <Heading size="md" mb="16px" mt="64px">
                A Comprehensive and Proactive Approach
              </Heading>

              <Box mb="16px">
                Understanding the stages of an attack is key to effective
                defense. Reach empowers you to look beyond individual security
                tools and take a proactive stance, identifying areas for
                improvement based on real attacks that target your organization.
                By mapping this data to the MITRE D3FEND framework, Reach keeps
                your defenses relevant and up-to-date, helping you stay ahead of
                evolving threats with a comprehensive, continuously updated
                security strategy.
              </Box>

              <Box mb="16px">
                While mapping threats to MITRE D3FENDS is a valuable first step,
                the next challenge is putting it into action. In the next post
                we'll focus on operationalizing D3FEND by turning the data into
                actionable strategies to enhance your security posture. Stay
                tuned to learn how you can apply D3FEND in practice and
                strengthen your organization's defenses.
              </Box>

              <Heading size="md" mb="16px">
                More About Reach Security
              </Heading>
              <Box mb="16px">
                Reach is the first AI purpose-built to reprogram your security
                infrastructure based on who you are and how you're being
                attacked. Organizations of all sizes trust Reach to make
                mission-critical decisions because it doesn't hallucinate and it
                doesn't make mistakes. It's a different kind of AI. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default D3fendBlog
