import React from 'react'
import { Flex, Icon, Link } from '@chakra-ui/react'
import ContentMaxWidth from './ContentMaxWidth'
import { AiOutlineArrowRight } from 'react-icons/ai'
import MotionBox from './MotionBox'
import { useApp } from 'context/App'
import { NavLink } from 'react-router-dom'

const Banner = () => {
  const { isMobile } = useApp()

  return (
    <MotionBox
      animate={{ transform: 'translateY(0px)' }}
      initial={{ transform: 'translateY(-64px)' }}
      transition={{ duration: 1 }}
    >
      <Flex
        w="100%"
        h={isMobile ? '88px' : '48px'}
        bg="reachBlue.800"
        color="white"
        fontSize={isMobile ? '14px' : '14px'}
        p={isMobile ? '16px' : undefined}
      >
        <ContentMaxWidth maxW="1600px" justifyContent="center">
          Reach Security Raises $20M for AI That Transforms How Companies Use
          Their Cybersecurity Products
          <Link
            as={NavLink}
            color="reachTeal.300"
            ml="16px"
            to="/resources/news/reach_raise"
            display="flex"
            alignItems="center"
          >
            Read more now <Icon ml="4px" as={AiOutlineArrowRight} />
          </Link>
        </ContentMaxWidth>
      </Flex>
    </MotionBox>
  )
}

export default Banner
