import React, { useEffect, useRef } from 'react'
import {
  Box,
  Image,
  VStack,
  LightMode,
  Icon,
  Center,
  Flex,
  Link,
  UnorderedList,
  ListItem,
  Button,
} from '@chakra-ui/react'
import { useApp } from 'context/App'
import logoimage from '../images/AnonymousManufacturing/circuit_board_rect.jpeg'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import BackgroundTopology from 'components/Shared/BackgroundTopology'
import topoimg from 'assets/images/background_topo.png'
import headshot from '../images/AnonymousManufacturing/circuit_board_square.jpeg'
import { BsMouse } from 'react-icons/bs'
import {
  AiOutlineArrowRight,
  AiOutlineDollar,
  AiOutlineEye,
  AiOutlineLineChart,
} from 'react-icons/ai'
import BackToTop from 'components/Shared/BackToTop'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const MAX_W = '1100px'

const AnonymousManufacturing = () => {
  const { setIsWhiteHeader, isMobile } = useApp()

  const ref0 = useRef(null)
  const ref1 = useRef(null)
  const ref2 = useRef(null)

  const smallFontSize = isMobile ? '16px' : '20px'
  const mainFontSize = isMobile ? '20px' : '28px'
  const quoteFontSize = isMobile ? '20px' : '26px'
  const headerFontSize = isMobile ? '32px' : '48px'

  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsWhiteHeader(true)
    return () => setIsWhiteHeader(false)
  }, [])
  return (
    <Box>
      <BackToTop />
      <LightMode>
        <Box
          pt="128px"
          bg="white"
          h={isMobile ? 'auto' : '100vh'}
          color="black"
        >
          <ContentMaxWidth maxW="1400px" flexDir="column">
            <Flex alignItems="flex-start" flexDir={isMobile ? 'column' : 'row'}>
              <Box>
                <Image
                  borderRadius="16px"
                  h="96px"
                  mb={isMobile ? '48px' : '32px'}
                  src={logoimage}
                />
                <Box position="relative">
                  <Box
                    lineHeight={1.4}
                    fontSize={isMobile ? '24px' : '48px'}
                    fontWeight="light"
                  >
                    How a Global Manufacturing Company Tackled New Initiatives
                    and Increased Security Stack Effectiveness with Reach
                  </Box>
                </Box>
              </Box>
              <VStack
                flexShrink={0}
                spacing="32px"
                ml={isMobile ? '0' : '40px'}
                mt={isMobile ? '32px' : '56px'}
                mb={isMobile && '72px'}
                color="reachBlue.500"
                alignItems="flex-start"
              >
                <Box>
                  <Link
                    onClick={() =>
                      ref1.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                  >
                    Automating control hardening
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    using features from existing investments
                  </Box>
                </Box>

                <Box display="block">
                  <Link
                    onClick={() =>
                      ref0.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                    whiteSpace="break-spaces"
                  >
                    Hiring for security talent
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    instead of product experts
                  </Box>
                </Box>

                <Box>
                  <Link
                    onClick={() =>
                      ref2.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                  >
                    Deploy SSL decryption
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    by leveraging data
                  </Box>
                </Box>
              </VStack>
            </Flex>
            {!isMobile && (
              <Center mt="128px" flexDir="column">
                <Icon
                  mb="4px"
                  w="32px"
                  h="32px"
                  color="gray.600"
                  as={BsMouse}
                />
                <Box fontSize="14px" color="gray.600">
                  Scroll down
                </Box>
              </Center>
            )}
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <Box>
        <BackgroundTopology
          bgImage={topoimg}
          bgSize="cover"
          pt="128px"
          pb="128px"
          color="white"
        >
          <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
            {!isMobile && (
              <Box
                w="256px"
                h="256px"
                mr={!isMobile && '48px'}
                mx={isMobile && 'auto'}
                mt={isMobile ? '16px' : '48px'}
                mb={isMobile ? '64px' : '128px'}
                bg="black"
                borderRadius="50%"
                flexShrink={0}
                bgImage={headshot}
                bgSize="cover"
                bgPos="50%"
              />
            )}
            <Box>
              <Box
                mb="16px"
                fontSize={isMobile ? '24px' : '34px'}
                width="fit-content"
                borderRadius="16px"
                position="relative"
              >
                <Box
                  bg="#0C153D66"
                  p="16px 32px"
                  borderRadius="8px"
                  position="relative"
                >
                  “In manufacturing, we’re going to have to rely on automating
                  control decisions for our products because I can’t hire tons
                  of people to manage them. It’s a major problem in our
                  environment and is a perfect example of why we’re utilizing
                  Reach.”
                </Box>
              </Box>
              <Box
                fontSize={isMobile ? '18px' : '24px'}
                fontWeight="light"
                ml="32px"
                mb="32px"
              >
                - IT Security Manager
              </Box>
            </Box>
          </ContentMaxWidth>
          <ContentMaxWidth maxW={MAX_W} pt="32px" flexDir="column">
            <Box fontSize="26px" fontWeight="light" mb="24px">
              A global leader in the circuit board manufacturing industry
              enables thousands of companies worldwide to bring their electronic
              products to market at scale. With an emphasis on speed and
              quality, they play a key role in the supply chain of electronic
              devices used by thousands of government agencies and well known
              brands worldwide on a daily basis.
            </Box>
            <Box fontSize="26px" fontWeight="light">
              Securing the enterprise while simultaneously avoiding operational
              disruption is core to their mission, and their IT Security Manager
              has been leading the charge to do so for the last 6 years. He
              knows that vendors for certain product categories are not
              one-size-fits-all and has made a concerted effort to buy what’s
              best for the business, from both a coverage and operational lens.
              With a broad spectrum of complex security products and a limited
              budget for hiring security talent, he needed a way to proactively
              automate control hardening decisions across their entire security
              stack.
            </Box>
          </ContentMaxWidth>
        </BackgroundTopology>
      </Box>

      <Box bg="white" color="black" py="128px">
        <ContentMaxWidth
          maxW={MAX_W}
          alignItems="flex-start"
          flexDir="column"
          fontWeight="normal"
        >
          <Box mb="64px" fontSize="36px" textAlign="center" width="100%">
            Here are the results of this partnership.
          </Box>
          <Flex
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            flexDir={isMobile ? 'column' : 'row'}
          >
            <Center
              mb={isMobile && '24px'}
              w="260px"
              h="260px"
              borderRadius="50%"
              bg="reachTeal.400"
            >
              <Flex color="white" alignItems="center" flexDir="column">
                <Icon w="32px" h="32px" as={AiOutlineLineChart} />
                <Box fontSize="56px" fontWeight="light" lineHeight={1.3}>
                  98%
                </Box>
                <Box textAlign="center" maxW="240px" fontSize="22px">
                  Reduction in time to deploy control hardening
                </Box>
              </Flex>
            </Center>

            <Center
              mb={isMobile && '24px'}
              w="260px"
              h="260px"
              borderRadius="50%"
              bg="reachTeal.400"
            >
              <Flex color="white" alignItems="center" flexDir="column">
                <Icon w="32px" h="32px" as={AiOutlineEye} />
                <Box fontSize="72px" fontWeight="light" lineHeight={1.2}>
                  77%
                </Box>
                <Box textAlign="center" maxW="240px" fontSize="22px">
                  Increase in threat visibility
                </Box>
              </Flex>
            </Center>

            <Center
              mb={isMobile && '24px'}
              w="260px"
              h="260px"
              borderRadius="50%"
              bg="reachTeal.400"
            >
              <Flex color="white" alignItems="center" flexDir="column">
                <Icon w="32px" h="32px" as={AiOutlineDollar} />
                <Box
                  textAlign="center"
                  fontSize="40px"
                  fontWeight="medium"
                  lineHeight={1.2}
                >
                  100,000&apos;s
                </Box>
                <Box textAlign="center" maxW="200px" fontSize="22px">
                  Estimated dollars saved on security product experts
                </Box>
              </Flex>
            </Center>
          </Flex>
        </ContentMaxWidth>
      </Box>

      {/* Text Quote Section 2 */}
      <Box
        ref={ref1}
        bg="gray.100"
        color="black"
        pt="120px"
        pb="24px"
        fontSize={mainFontSize}
        fontWeight="light"
      >
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="48px" fontSize={headerFontSize} fontWeight="medium">
            Automating control hardening using features from existing security
            investments
          </Box>
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            Deploying new features or functionality from their tools presented a
            challenge that was two fold:
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="light"
          >
            <Box display="inline" fontWeight="medium">
              1.
            </Box>{' '}
            Staying on top of hundreds of product features rolled out annually
            was difficult and resource intensive.
          </Box>
          <Box ml={isMobile ? '24px' : '48px'} mb="32px" fontWeight="light">
            <Box display="inline" fontWeight="medium">
              2.
            </Box>{' '}
            Deciding where the team should start with deploying these features
            to reduce the most amount of risk was left to guesswork.
          </Box>
          <Box mb="16px">
            On top of this, they were lacking data to help dictate how security
            policy should be defined. For example, they knew they could use
            forensics and threat event metadata like URL, DNS, and IP
            information from their products to help define:
          </Box>
          <UnorderedList
            spacing="16px"
            ml={isMobile ? '24px' : '72px'}
            mb="16px"
          >
            <ListItem>The content filtering categories to action on </ListItem>
            <ListItem>The file types to block or alert on </ListItem>
            <ListItem>
              The end users requiring a disproportionate amount of detection and
              prevention capabilities
            </ListItem>
          </UnorderedList>
          <Box mb="64px" fontWeight="normal">
            But the entire process of aggregating, normalizing, and actioning
            proved to be extremely manual and near impossible.
          </Box>
        </ContentMaxWidth>
        <ContentMaxWidth pt="40px" alignItems="flex-start" maxW={MAX_W}>
          <Box>
            <Box
              mb="16px"
              fontSize={quoteFontSize}
              width="fit-content"
              borderRadius="16px"
              position="relative"
            >
              <Box
                bg="white"
                p="16px 32px"
                borderRadius="8px"
                position="relative"
              >
                “I’d tell engineers to go look at unreadable logs and turn them
                into a readable format. Then take the data and try to turn it
                into something that you hopefully have a good understanding of
                to convert to action.”
              </Box>
            </Box>
            <Box ml="32px" fontSize={smallFontSize} fontWeight="light">
              - IT Security Manager
            </Box>
          </Box>
          {!isMobile && (
            <Box
              w="240px"
              h="240px"
              ml="48px"
              mt={'-16px'}
              mb="64px"
              bg="black"
              borderRadius="50%"
              flexShrink={0}
              bgImage={headshot}
              bgSize="cover"
              bgPos="50%"
            />
          )}
        </ContentMaxWidth>
      </Box>

      <Box bg="gray.100" color="black" pb="120px">
        <ContentMaxWidth
          maxW={MAX_W}
          alignItems="flex-start"
          flexDir="column"
          fontSize={mainFontSize}
        >
          <Box mb="16px" fontWeight="light">
            After connecting their products to Reach via cloud-based APIs, they
            were able to identify hotspots based on historical attack data
            within minutes. Once connected, Reach automated the entire process
            of:
          </Box>

          <Box ml={isMobile ? '24px' : '48px'} mb="16px" fontWeight="light">
            <Box display="inline" fontWeight="medium">
              1.
            </Box>{' '}
            Pulling and baselining product configurations at initial set up to
            highlight coverage gaps
          </Box>
          <Box ml={isMobile ? '24px' : '48px'} mb="32px" fontWeight="light">
            <Box display="inline" fontWeight="medium">
              2.
            </Box>{' '}
            Modeling threat data and translating to controls of products to fill
            gaps
          </Box>
          <Box ml={isMobile ? '24px' : '48px'} mb="16px" fontWeight="light">
            <Box display="inline" fontWeight="medium">
              3.
            </Box>{' '}
            Providing new configuration recommendations as code to expedite
            deployment
          </Box>
          <Box ml={isMobile ? '24px' : '48px'} mb="32px" fontWeight="light">
            <Box display="inline" fontWeight="medium">
              4.
            </Box>{' '}
            Tracking configuration changes and improvements over time to ensure
            unified protection
          </Box>
          <Box>
            The company now has the confidence that changes to their threat
            landscape will be translated to configurations and improvements
            without any operational overhead.
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* GRAY BIG TEXT SECTION */}

      <Box ref={ref0} bg="white" pt="128px" pb="64px">
        <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
          <Box>
            <Box
              color="gray.900"
              fontSize={headerFontSize}
              fontWeight="medium"
              mb="24px"
            >
              Hiring for security talent instead of product experts
            </Box>
            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="16px"
            >
              Like many organizations, headcount for security is not easy to
              come by at the company, and their IT Security Manager needs to
              allocate resources efficiently. He’s taken on the task of hiring
              and training security talent into leaders within the organization,
              but this doesn’t always mean they’re product experts too.
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* text and quote section */}
      <Box bg="white" color="gray.900" pb="40px">
        <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
          {!isMobile && (
            <Box
              w="240px"
              h="240px"
              mr="48px"
              mx={isMobile && 'auto'}
              mt="16px"
              mb={isMobile ? '48px' : '128px'}
              bg="black"
              borderRadius="50%"
              flexShrink={0}
              bgImage={headshot}
              bgSize="cover"
              bgPos="50%"
            />
          )}

          <Box>
            <Box
              mb="16px"
              fontSize={quoteFontSize}
              width="fit-content"
              borderRadius="16px"
              position="relative"
            >
              <Box
                bg="gray.100"
                p="16px 32px"
                borderRadius="8px"
                position="relative"
              >
                “We’ve hired really talented individuals to lead our security
                program, but that doesn’t always translate to toolset expertise.
                We don’t have the budget to hire product subject matter experts,
                and oftentimes our talent comes in after products have been in
                place for a while. As a manager, I want them to dedicate their
                time to becoming security experts instead of learning the ins
                and outs of our products’ manuals.”
              </Box>
            </Box>
            <Box
              mb="32px"
              ml="32px"
              fontSize={smallFontSize}
              fontWeight="light"
            >
              - IT Security Manager
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* white big text section */}
      <Box bg="white" color="black" pb="96px">
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            With Reach, they can focus on securing their environment with
            minimal operational friction while saving time and resources.
            Reach’s configurations as code delivered for every product allows
            them to automate deployments across the board, and the team can now
            hire security experts instead of security product experts.
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* Text Quote Section 3 */}
      <Box ref={ref2} bg="gray.100" color="black" py="120px">
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="48px" fontSize={headerFontSize} fontWeight="medium">
            Extending network visibility with a data story
          </Box>
          <Box mb="32px" fontSize={mainFontSize} fontWeight="light">
            The manufacturing company needed a data-driven way to justify SSL
            decryption using their firewall. It’s a task that they have been
            trying to accomplish for years, but have been unable to make much
            progress due to the following constraints:
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="normal"
          >
            <Box display="inline" fontWeight="bold">
              1.
            </Box>{' '}
            Operational - Inability to quantify the risk of leaving certain
            network traffic encrypted, given the downstream impact of rolling
            out such a project
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="normal"
          >
            <Box display="inline" fontWeight="bold">
              2.
            </Box>{' '}
            Human Resources - Staffing limitations made taking on a company wide
            initiative to deploy such a project all at once near impossible.
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="normal"
          >
            <Box display="inline" fontWeight="bold">
              3.
            </Box>{' '}
            Device constraints - Concerns around the stability of hosts and
            networking devices if additional capabilities were enabled.
          </Box>

          <Box mb="32px" fontSize={mainFontSize} fontWeight="light">
            They needed an approach that:
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="normal"
          >
            <Box display="inline" fontWeight="bold">
              1.
            </Box>{' '}
            Quantified the number of threats and severity of threats from email
            attack forensics found to use encryption as part of their attack
            chain.
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="normal"
          >
            <Box display="inline" fontWeight="bold">
              2.
            </Box>{' '}
            Determined which URL categories should be decrypted as a starting
            point and why, given privacy concerns from the business and resource
            limitations on their firewall.
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="normal"
          >
            <Box display="inline" fontWeight="bold">
              3.
            </Box>{' '}
            Identified risky employees to help IT and Security target the
            initial roll out of deploying decryption
          </Box>

          <ContentMaxWidth
            py="40px"
            p={isMobile && '8px'}
            alignItems="flex-start"
            maxW={MAX_W}
          >
            <Box>
              <Box
                mb="16px"
                fontSize={mainFontSize}
                width="fit-content"
                borderRadius="16px"
                position="relative"
              >
                <Box
                  bg="white"
                  p={isMobile ? '8px 16px' : '16px 32px'}
                  borderRadius="8px"
                  position="relative"
                >
                  “Within a month of deploying Reach we deployed SSL decryption
                  to the URL categories containing the riskiest traffic to our
                  users. We were able to select a targeted set of users that
                  present the most risk so we knew where to start with roll out.
                  This has been a work in progress for 5.5 years, and with Reach
                  we got it done in just a month.”
                </Box>
              </Box>
              <Box
                ml="32px"
                fontSize={smallFontSize}
                fontWeight="light"
                opacity=".7"
              >
                - IT Security Manager
              </Box>
            </Box>
            {!isMobile && (
              <Box
                w="240px"
                h="240px"
                ml="48px"
                mt="16px"
                bg="black"
                borderRadius="50%"
                mb="128px"
                flexShrink={0}
                bgImage={headshot}
                bgSize="cover"
                bgPos="50%"
              />
            )}
          </ContentMaxWidth>

          <Box mt="32px" fontSize={mainFontSize} fontWeight="light">
            With Reach, they could represent the value of this initiative and
            its relative priority to the business in a way that could be
            understood cross-functionally. Security leaders were able to
            collaborate with counterparts across security, IT, and the business
            better than ever by using Reach’s prescriptive recommendations and a
            data-driven plan.
          </Box>
        </ContentMaxWidth>
      </Box>

      <BackgroundTopology
        bgImage={topoimg}
        bgSize="cover"
        py="160px"
        color="white"
      >
        <ContentMaxWidth maxW={MAX_W} flexDir="column" alignItems="flex-start">
          <Box fontSize={headerFontSize} mb="48px">
            Learn more about how Reach was used to tackle SSL Decryption
          </Box>
          <Box>
            <Box
              mb="16px"
              fontSize={isMobile ? '24px' : '34px'}
              width="fit-content"
              borderRadius="16px"
              position="relative"
            >
              <Box
                bg="#0C153D66"
                p="16px 32px"
                borderRadius="8px"
                position="relative"
              >
                “Reach gave me a staged approach with concrete numbers and
                granular data that made it easy to justify SSL decryption to my
                leadership. Within a month of using Reach, we’ve rolled it out
                to a group of our most attacked users and plan to expand to the
                rest of the company by next year.”
              </Box>
            </Box>
            <Box
              fontSize={isMobile ? '18px' : '24px'}
              fontWeight="light"
              ml="32px"
              mb="32px"
            >
              - IT Security Manager
            </Box>
          </Box>

          <Button
            alignSelf="flex-end"
            size="lg"
            fontSize="32px"
            h="80px"
            colorScheme="reachTeal"
            variant="ghost"
            rightIcon={<Icon as={AiOutlineArrowRight} />}
            onClick={() => history.push('/customers/ssl-decryption')}
          >
            Continue reading
          </Button>
        </ContentMaxWidth>
      </BackgroundTopology>
    </Box>
  )
}

export default AnonymousManufacturing
