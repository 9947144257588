/* eslint-disable react/no-unescaped-entities */
import { Box, Flex, Heading, LightMode, Link } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import PageHeader from 'components/Shared/PageHeader'
import { useApp } from 'context/App'
import React, { useEffect } from 'react'
import PageFooter from 'components/Shared/PageFooter'
import { BlogImage } from '../TRABlog'
import BlogAuthor from '../../BlogAuthor'
import signOnPolicy from './sign-on-policy.png'
import baselineSecurity from './baseline-security.png'
import mostAttackedMalware from './most-attacked-malware.png'
import chloe from '../AuthorImages/chloe.png'

const StrengtheningDefensesBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])
  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Strengthening Defenses Against Ransomware
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">October 17, 2024</Box>
                <BlogAuthor name="Chloe Potsklan" img={chloe} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mb="16px">
                With Cybersecurity Awareness Month in full swing and the 2024
                Grace Hopper Celebration behind us, I had the opportunity to
                present a choose-your-own-adventure tabletop session on
                ransomware. After reflecting on my discussions with attendees at
                GHC ransomware emerged as a challenge and top priority for many.
              </Box>

              <Box mb="16px">
                I’ve been thinking about how ransomware consistently devastates
                organizations globally by targeting the same but obvious low
                hanging fruit. Misconfigurations in IAM and MFA solutions may
                seem straightforward and obvious, but they remain some of the
                most targeted conduits for ransomware attacks to exploit
                successfully. Once misconfigurations are exploited for initial
                access there is a concerted effort for persisting access and
                limiting availability and business continuity. To better counter
                these evolving threats, organizations must implement proactive
                security solutions. My conversations with attendees at GHC also
                focused on the challenges of initial access in ransomware
                attacks and the consequences that follow. These discussions led
                me to reflect on some of the most recent and significant
                ransomware incidents.
              </Box>

              <Box mb="64px">
                The start of wide-scale ransomware attacks can be as trivial as
                an employee password being comprised. Just take the 2021
                Colonial Pipeline example, by virtue of identity controls as
                simple as MFA checks being bypassed, attackers crippled the
                entire east coast fuel supply, impacting not only businesses by
                the livelihood of American citizens. This is an example of
                attackers exploiting poor identity controls to bypass weak or
                non-existing MFA implementations.
              </Box>

              <BlogImage src={signOnPolicy} mb="64px" margin="0 auto" />

              <Box mt="64px" mb="64px">
                To combat attacks like the Colonial Pipeline incident, Reach
                Security interrogates token expiration policies and MFA
                configurations in real time and ensures adaptive protection
                based on live threat data. Beyond enforcing MFA, it’s important
                to account for other contextual attributes of the user’s login,
                things like location, device, or behavioral patterns. For
                instance, had the Colonial Pipeline attack originated from a
                rogue location or untrusted device, Reach’s contextual checks
                would have flagged and potentially blocked the unauthorized
                login attempt before any future damage occurred
              </Box>

              <BlogImage src={baselineSecurity} mb="64px" margin="0 auto" />

              <Box mt="64px" mb="64px">
                Recovering availability for business continuity is critical in
                the face of a ransomware attack and because of that shadow
                copies are often sought out and then targeted and deleted to
                prevent system recovery. While tools like CrowdStrike can detect
                these attempts, Reach takes it further by applying kill chain
                analysis and maps behaviors like encryption and shadow copy
                deletion to known malware families. This enables proactive
                defense through measures like shadow copy locking and automated
                response mechanisms.
              </Box>

              <BlogImage src={mostAttackedMalware} mb="64px" margin="0 auto" />

              <Box mt="64px" mb="16px">
                The thing is ransomware attacks consist of multiple phases and
                each phase requires different controls from different points of
                the enterprise security stack. Integrating with Reach helps with
                preventing it from executing by looking at ransomware
                holistically. Reach enhances defenses by integrating data from
                endpoint, email, and identity tools to deliver tailored
                recommendations. IAM solutions need continuous refinement to
                address specific network vulnerabilities and to do so, Reach
                identifies high-risk users and systems and continuously
                validates MFA configurations dynamically to respond to live
                threats and secure critical systems.
              </Box>

              <Box mb="16px">
                By optimizing configurations for MFA and IAM, Reach Security
                helps organizations stay ahead of ransomware threats and severs
                any initial access attempts and network persistence. Ransomware
                can have devastating consequences, but Reach provides proactive
                measures to block attacks early in the ransomware kill chain. By
                preventing the attack from progressing through its stages, Reach
                ensures that every attempt is met with an optimized, tailored
                defense designed to protect your organization.
              </Box>

              <Box mb="16px">
                Reach helps organizations reduce risk, optimize ROI and drive
                trust by delivering proactive and tailored defenses against
                ransomware. To see how Reach can optimize your environment, we
                offer Tools Rationalization Assessments (TRAs) which evaluates
                your current configurations and identifies opportunities for
                improvement to fortify your environment against ransomware
                incidents. Take advantage of this assessment to get a taste of
                the full capabilities of Reach.
              </Box>

              <Heading size="md" mb="16px">
                More About Reach Security
              </Heading>
              <Box mb="16px">
                Reach is the first AI purpose-built to reprogram your security
                infrastructure based on who you are and how you're being
                attacked. Organizations of all sizes trust Reach to make
                mission-critical decisions because it doesn't hallucinate and it
                doesn't make mistakes. It's a different kind of AI. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default StrengtheningDefensesBlog
