import React from 'react'
import PropTypes from 'prop-types'
import { Center, Flex } from '@chakra-ui/react'
import { useApp } from 'context/App'

const ContentMaxWidth = ({ maxW, bg, ...props }) => {
  const { isDesktop } = useApp()

  const responsiveFlexDir = isDesktop ? 'row' : 'column'
  return (
    <Center w="100%" h="100%" bg={bg}>
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        maxW={maxW}
        px={!isDesktop ? '0px' : '32px'}
        flexDir={responsiveFlexDir}
        {...props}
      />
    </Center>
  )
}

ContentMaxWidth.defaultProps = {
  maxW: '1200px',
}

ContentMaxWidth.propTypes = {
  maxW: PropTypes.string,
  bg: PropTypes.string,
}

export default ContentMaxWidth
