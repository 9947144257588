import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const Card = ({ children, borderColor, bg, ...remaining }) => {
  return (
    <Box
      bg={bg}
      padding="32px"
      borderRadius="24px"
      border=".5px solid"
      borderColor={borderColor || '#819BD2AA'}
      {...remaining}
    >
      {children}
    </Box>
  )
}

Card.defaultProps = {
  bg: '#26386A',
}

Card.propTypes = {
  bg: PropTypes.string,
  borderColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export const MotionCard = motion(Card)

export default Card
