/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import {
  Box,
  Flex,
  Heading,
  LightMode,
  Link,
  ListItem,
  OrderedList,
} from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import B from 'components/Shared/B'

import { BlogImage } from '../TRABlog'
import BlogAuthor from '../../BlogAuthor'
import zach from '../AuthorImages/ZachMarks.png'
import licensingOne from './licensing_1.png'
import licensingTwo from './licensing_2.png'
import licensingThree from './licensing_3.png'
import licensingFour from './licensing_4.png'
import licensingFive from './licensing_5.png'

const LicensingBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Reach Licensing View: Turning Visibility into Value
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">October 31, 2024</Box>
                <BlogAuthor name="Zach Marks" img={zach} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mb="16px">
                When it comes to managing your enterprise security products, how
                do you answer questions like these?
                <OrderedList mt="16px">
                  <ListItem mb="8px">What am I licensed for?</ListItem>
                  <ListItem mb="8px">
                    How are the features I am licensed for being utilized in my
                    environment today?
                  </ListItem>
                  <ListItem>
                    Where and how can I use these licensed features to mitigate
                    exposure in my environment?
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                Turns out, these are hard questions to answer for security
                teams, especially when the tool has shared ownership with other
                parts of IT.
              </Box>
              <Box
                mt="64px"
                mb="64px"
                fontSize="24px"
                fontWeight="lighter"
                lineHeight="40px"
                p="30px"
                bg="gray.100"
              >
                We're excited to announce the launch of{' '}
                <B>Reach’s Licensing View</B>, which was built to help customers
                better understand the value they are getting from the licensed
                features they are paying for in their security products.
              </Box>

              <Heading size="md" mb="16px">
                Key Benefits
              </Heading>
              <Box mb="16px">
                <OrderedList>
                  <ListItem>
                    <B>Understand Licensed Features:</B> Users can understand
                    their consumption of licensed features at a glance. We
                    assign one of three attributes to them: not in use, in use,
                    or Reach Optimized (i.e. used in all ways that Reach’s
                    purpose-built AI model recommends).
                  </ListItem>
                  <BlogImage src={licensingOne} />
                  <Box fontStyle="italic" mb="64px">
                    Drill into feature consumption tied to licensed modules.
                  </Box>
                  <BlogImage src={licensingTwo} />
                  <Box fontStyle="italic" mb="64px">
                    Understand utilization of features from a exposure reduction
                    lens.
                  </Box>
                  <ListItem>
                    <B>Drill into the details of licensed features:</B> Users
                    can drill into a licensed feature and see how Reach
                    recommends using the feature in the context of our control
                    recommendations.
                  </ListItem>
                  <BlogImage src={licensingThree} />
                  <Box fontStyle="italic" mb="64px">
                    Breakdown features by module and consumption status.
                  </Box>
                  <BlogImage src={licensingFour} />
                  <Box fontStyle="italic" mb="64px">
                    Drill into Reach recommended controls that utilize the
                    licensed feature, prioritized by exposure mitigation.
                  </Box>
                  <ListItem>
                    <B>Operationalize licensed features:</B> Quests are paired
                    to each licensed feature, allowing a user to click into a
                    feature, see more context for how they should use it, and
                    jump right into kicking off the deployment process by
                    creating a ticket, downloading a deployment guide, and/or
                    staging the change directly to a test environment.
                  </ListItem>
                  <BlogImage src={licensingFive} />
                  <Box fontStyle="italic" mb="64px">
                    Go from ”visibility” to ”action” by beginning the deployment
                    process.
                  </Box>
                </OrderedList>
              </Box>

              <Box mb="64px">
                Customers of Reach are already taking advantage of the Licensing
                View to drive consumption of licensed modules and address risk
                in their environment. If you are a security leader looking to
                better understand what you’re licensed for and communicate the
                value of your investments from an exposure reduction lens, let’s
                talk!
              </Box>

              <Heading size="md">More About Reach Security</Heading>
              <br />
              <Box>
                Reach is the first AI purpose-built to reprogram your security
                infrastructure based on who you are and how you're being
                attacked. Organizations of all sizes trust Reach to make
                mission-critical decisions because it doesn't hallucinate and it
                doesn't make mistakes. It's a different kind of AI. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default LicensingBlog
