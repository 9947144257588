import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  LightMode,
} from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import FadeIn from 'components/Shared/FadeIn'
import oneToOne from './images/one_to_one.svg'
import diamond from './images/diamond.svg'
import rocket from './images/rocket.svg'
import { NavLink } from 'react-router-dom'
import { useApp } from 'context/App'

const Feature = ({ heading, text, icon }) => {
  const { isMobile } = useApp()

  return (
    <Flex
      flexDir="column"
      alignItems={isMobile ? 'center' : 'flex-start'}
      flex={1}
      flexShrink={0}
      px={isMobile ? '16px' : undefined}
      maxW="520px"
    >
      <Image color="reachBlue.600" boxSize="128px" src={icon} mb="8px" />
      <Heading textAlign={isMobile ? 'center' : undefined} size="lg" mb="24px">
        {heading}
      </Heading>
      <Box
        textAlign={isMobile ? 'center' : undefined}
        fontSize="20px"
        lineHeight={1.7}
      >
        {text}
      </Box>
    </Flex>
  )
}

Feature.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.any,
}

const ProductFeatures = () => {
  const { isMobile } = useApp()

  return (
    <LightMode>
      <Box pb="120px">
        <Center color="black" p={isMobile ? '16px' : '120px'}>
          <FadeIn>
            <Box my={isMobile ? '48px' : undefined} maxW="900px">
              <Heading
                size={isMobile ? 'xl' : '2xl'}
                textAlign="center"
                mb="24px"
              >
                Trustworthy AI for mission-critical decisions.
              </Heading>
              <Box fontSize="24px" textAlign="center">
                AI comes in many flavors. We developed Reach’s to meet the
                exacting demands of enterprise security.
              </Box>
            </Box>
          </FadeIn>
        </Center>
        <ContentMaxWidth
          gap="64px"
          mb="80px"
          maxW="1600px"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <FadeIn>
            <Feature
              icon={oneToOne}
              heading="AI that’s unique to you"
              text="Reach creates a unique AI model for every customer, one dedicated to your team, your operational needs, and your security challenges. It molds itself to the shape of your business, rather than asking you to adjust to it."
            />
          </FadeIn>
          <FadeIn delay={0.4}>
            <Feature
              icon={diamond}
              heading="Purpose-built for reliability"
              text="Reach’s proprietary AI engine doesn’t use third-party services or external data. All customer data remains securely within the product, offering an added layer of confidentiality and trust."
            />
          </FadeIn>
          <FadeIn delay={0.8}>
            <Feature
              icon={rocket}
              heading="Mission-critical decisioning"
              text="Customers trust Reach’s AI to make important decisions because it doesn’t hallucinate and doesn’t make mistakes. It’s a different kind of AI, one designed with accountability in mind—something that’s never existed before in cybersecurity."
            />
          </FadeIn>
        </ContentMaxWidth>
        <Center>
          <Button as={NavLink} to="/terms/security-and-privacy" size="lg">
            Learn More
          </Button>
        </Center>
      </Box>
    </LightMode>
  )
}

export default ProductFeatures
