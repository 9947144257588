import React, { useEffect } from 'react'
import PageHeader from 'components/Shared/PageHeader'
import CompanyBody from './CompanyBody'
import { useApp } from 'context/App'
import PageFooter from 'components/Shared/PageFooter'
import StayInTouch from 'components/Shared/PageSections/StayInTouch'
import Investors from './Investors'

const Company = () => {
  const { setIsWhiteHeader } = useApp()

  useEffect(() => {
    setIsWhiteHeader(true)
    return () => setIsWhiteHeader(false)
  }, [])

  return (
    <>
      <PageHeader />
      <CompanyBody />
      <Investors />
      <StayInTouch />
      <PageFooter />
    </>
  )
}

export default Company
