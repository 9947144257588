/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import {
  Box,
  Flex,
  Heading,
  LightMode,
  Link,
  ListItem,
  OrderedList,
} from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import zach from '../AuthorImages/ZachMarks.png'
import BlogAuthor from '../../BlogAuthor'
import { BlogImage } from '../TRABlog'
import signOnPolicy from './sign_on_policy.png'
import signOnBlock from './sign_on_block.png'
import userActionPolicy from './user_action_policy.png'
import stageContent from './stage_content.png'
import configuration from './configuration.png'
import B from 'components/Shared/B'

const MicrosoftEntraMfaBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])
  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Going Above and Beyond Microsoft’s Impending MFA Requirement
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">September 26, 2024</Box>
                <BlogAuthor name="Zach Marks" img={zach} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mb="32px">
                Beginning in October, Microsoft will mandate multi-factor
                authentication (MFA) for multiple Azure components, including
                the Entra admin portal. Given Microsoft’s research indicates
                that MFA can prevent over 99.2% of account compromise attempts,
                this is great news! For those utilizing Entra Conditional Access
                to govern how users authenticate to mission-critical
                applications, there are additional measures you can take within
                Entra to further reduce the risk of credential compromise. Let’s
                explore some strategies to elevate your Conditional Access
                implementation beyond MFA using the features you’re licensed
                for.
              </Box>

              <Heading size="md" mb="16px">
                1) Risk Based Policies
              </Heading>

              <Box mb="16px">
                In Entra, you can combine MFA with risk-based authentication
                attributes to manage user sessions. You have the option to
                select from two different risk levels:
              </Box>

              <Box mb="16px">
                <OrderedList>
                  <ListItem>
                    <B>User Risk:</B> Assessed by Microsoft's threat
                    intelligence to indicate the likelihood of identity
                    compromise.
                  </ListItem>
                  <ListItem>
                    <B>Sign-in Risk:</B> Also defined by Microsoft, indicating
                    the authenticity of sign-in attempts.
                  </ListItem>
                </OrderedList>
              </Box>

              <Box mb="64px">
                Once these risk levels are assigned there are a series of
                actions you may consider to augment MFA. For example, if the
                user risk level is medium or high, you may want to enforce a
                password reset after successful MFA, as Microsoft defines these
                risk levels as having a higher likelihood of account compromise.
              </Box>

              <BlogImage src={signOnPolicy} />
              <Box fontStyle="italic" mb="64px">
                Force password reset after MFA is successful if user risk levels
                are medium or high.
              </Box>

              <Box mb="64px">
                On the contrary, you may choose to block access entirely if the
                sign-in risk level is high and the device used for the
                authentication attempt is not joined or registered to the
                domain. In this scenario, MFA would not apply due to the context
                considered for the user’s login.
              </Box>

              <BlogImage src={signOnBlock} />
              <Box fontStyle="italic" mb="64px">
                Consider blocking if sign-in risk levels are high and the device
                is not joined or registered.
              </Box>

              <Box mb="32px">
                With Reach, you can take this one step further by tailoring
                these policies to individual users or groups based on their
                unique risk profiles. This ensures your highest-risk users are
                receiving the strongest protections that are dynamically
                adjusted as their risk level changes.
              </Box>

              <Heading size="md" mb="16px">
                2) User Actions
              </Heading>

              <Box mb="64px">
                In addition to applying MFA at login, you may want to enforce
                MFA when users perform certain actions that could amplify the
                impact of a credential compromise. Entra refers to these as user
                actions, and you can require MFA for actions such as changing
                MFA settings, resetting the account password, or
                joining/registering a device. These are often the first targets
                attackers go after when they successfully compromise credentials
                and gain access.
              </Box>

              <BlogImage src={userActionPolicy} />
              <Box fontStyle="italic" mb="64px">
                Utilize MFA for user actions.{' '}
              </Box>

              <Box mb="16px">
                In this example, users would be prompted for MFA if they attempt
                to reset their MFA configuration or password while using a
                device that isn't domain-joined. For users trying to join or
                register their device, MFA should be required regardless of the
                device they are using.
              </Box>
              <Box mb="64px">
                If you’re not already using MFA for user actions, Reach can help
                you mobilize these changes by providing step by step deployment
                guides and ticketing automation through Reach quests.
              </Box>

              <BlogImage src={stageContent} />
              <Box fontStyle="italic" mb="64px">
                Automatically stage MFA enforcements for User Actions
              </Box>

              <Heading size="md" mb="16px">
                3) Phishing-resistant MFA
              </Heading>

              <Box mb="64px">
                Implementing phishing-resistant MFA can be challenging to roll
                out across the entire organization. Therefore, you might
                consider starting with users who have the highest level of
                access, such as your admins. This approach enhances security for
                those with the most critical permissions, going beyond the basic
                requirements that Microsoft has set forth.
              </Box>

              <BlogImage src={configuration} />
              <Box fontStyle="italic" mb="64px">
                Require phishing resistant MFA for admins.
              </Box>

              <Heading size="md" mb="16px">
                Closing
              </Heading>
              <Box mb="16px">
                Regardless of Microsoft’s upcoming requirements, it’s important
                to remember that the bare minimum may not be enough to fully
                secure your organization.
              </Box>
              <Box mb="16px">
                With Reach, you can continuously validate Conditional Access
                settings to ensure they remain effective as your organization
                and its risk landscape evolve. Reach’s real-time monitoring
                ensures your MFA and other security controls adapt to new
                threats and changes within your organization without
                compromising legitimate users' login experience. For more
                details on how Reach can help you automate this entire process,
                check out or security optimization spotlight on{' '}
                <Link
                  href="/resources/blog/microsoft_entra_spotlight"
                  fontWeight="bold"
                >
                  Entra Conditional Access
                </Link>
                , where we dive into the details. Happy MFA’ing!
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default MicrosoftEntraMfaBlog
