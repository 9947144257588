/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import { Box, Flex, Heading, LightMode, Link } from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'

import BlogAuthor from '../../BlogAuthor'
import trent from '../AuthorImages/trent.png'
import { BlogImage } from '../TRABlog'
import serviceNowSetup from './servicenow_setup.png'
import serviceNowDefaults from './servicenow_defaults.png'

const ServiceNowBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Bridging the Last Mile: Reach’s New ServiceNow Integration
                Enhances Cybersecurity Maturity
              </Heading>
              <Flex
                mb="32px"
                py="8px"
                alignItems="center"
                justifyContent="space-around"
              >
                <Box fontSize="12px">July 19, 2024</Box>
                <BlogAuthor name="Trent Weber" img={trent} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mb="16px">
                In cybersecurity, the "last mile" represents the crucial yet
                challenging phase where strategic plans translate into
                actionable security measures. This phase is critical, as it's
                where the effectiveness of security tools is truly tested. At
                Reach, we understand the importance of this last mile and are
                committed to helping our clients navigate it successfully.
                Building on our previous discussions on the{' '}
                <Link fontWeight="bold" href="/resources/blog/last_mile">
                  maturity model
                </Link>{' '}
                and the{' '}
                <Link fontWeight="bold" href="/resources/blog/last_mile">
                  last mile concept
                </Link>
                , we are thrilled to announce a significant enhancement: the
                integration of ServiceNow ITSM with our security platform.
              </Box>
              <Box mb="16px">
                <Heading size="md" mb="16px">
                  Streamlining the Last Mile with ServiceNow
                </Heading>
                The newly completed ServiceNow integration marks a pivotal
                development in our effort to help our customers achieve Level 2
                maturity. At this level, organizations go beyond merely
                generating reports and begin integrating actionable security
                measures directly into their operational workflows. By
                facilitating the creation of detailed, actionable tickets within
                users' workflow management systems, this integration
                significantly reduces the overhead associated with manual ticket
                handling. The integration ensures that each ticket is not only a
                notification of a task but a blueprint for action that aligns
                perfectly with the specific security needs of the organization.
              </Box>
              <Box mb="16px">
                <Heading size="md" mb="16px">
                  Practical Benefits of our ServiceNow Integration
                </Heading>
                <Box mb="16px">
                  The integration with ServiceNow transforms the way security
                  tasks are managed and executed. It automates the ticket
                  creation process, populating tickets with all the necessary
                  details required to address a security concern effectively.
                  This means less time spent by teams writing up requirements
                  and more time focusing on implementing solutions—a key factor
                  in accelerating response times and enhancing the security
                  posture of an organization.
                </Box>
                <Box mb="16px">
                  This integration not only ensures that actionable tasks are
                  seamlessly integrated into the daily workflows but also
                  significantly enhances job satisfaction by reducing redundant,
                  time-consuming work. It aligns with our goal of improving
                  operational efficiency and job satisfaction, thereby aiding in
                  better retention of skilled security professionals.
                  <BlogImage mb="64px" src={serviceNowSetup} />
                  <BlogImage mb="64px" src={serviceNowDefaults} />
                </Box>
                <Box mb="16px">
                  Our ServiceNow integration complements the existing
                  integration with Jira, which we introduced as part of our
                  commitment to addressing the last mile in cybersecurity. The
                  Jira integration focuses on mapping changes to the correct
                  project, issue type, and owner within an organization,
                  ensuring that tasks are directed appropriately and managed
                  efficiently. Together, these integrations provide a
                  comprehensive solution that covers a broad spectrum of
                  workflow management systems, catering to the diverse needs of
                  our clients.
                </Box>
                <Box mb="16px">
                  By situating the ServiceNow integration within the last mile
                  concept and our cybersecurity maturity model, we emphasize our
                  commitment to not just assessing risks but acting on them. As
                  described in our maturity model, moving from Level 1
                  (Assessment) to Level 2 (Action) involves the transition from
                  recognizing security gaps to actively implementing measures to
                  address them. Our integrations with ServiceNow and Jira are
                  critical in enabling organizations to make this transition
                  effectively.
                </Box>
                <Box mb="16px">
                  As we continue to navigate the complexities of cybersecurity,
                  our focus remains on providing solutions that not only secure
                  but also streamline operational processes. The new ServiceNow
                  integration is a testament to our dedication to mastering the
                  last mile, ensuring that our clients can achieve and maintain
                  the highest levels of security maturity.
                </Box>
              </Box>

              <Heading size="md">More About Reach Security</Heading>
              <br />
              <Box>
                Reach is the first AI purpose-built to reprogram your security
                infrastructure based on who you are and how you're being
                attacked. Organizations of all sizes trust Reach to make
                mission-critical decisions because it doesn't hallucinate and it
                doesn't make mistakes. It's a different kind of AI. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default ServiceNowBlog
