import React from 'react'
import PropTypes from 'prop-types'
import { useApp } from 'context/App'
import { Box, Button, Flex } from '@chakra-ui/react'
import { AnimImage } from 'components/Deprecated/Home/MiddleSection2'
import TextBlurb from 'components/Shared/DeprecatedTextBlurb'
import oneClick from '../Home/MiddleSection/content/oneclick_720.webp'

export const PostureAutomationImages = ({ isIn }) => {
  const { isMobile } = useApp()
  return (
    <>
      <AnimImage
        isIn={isIn}
        boxShadow="1px 2px 8px rgba(0,0,0,.05)"
        border="1px solid #070E32"
        borderRadius="16px"
        src={oneClick}
        x={isMobile ? '-8px' : '0px'}
        y="0px"
        delay={0}
      />
    </>
  )
}
PostureAutomationImages.propTypes = {
  isIn: PropTypes.bool,
}

const PostureAutomation = () => {
  const { isMobile } = useApp()
  return (
    <>
      <TextBlurb
        heading="Defensive Posture Automation"
        tagline="Automate the deployment of product features available to you, from basic knobs to advanced content, with a couple of clicks."
        body={`Organizations are being asked to deliver better defense in a measurable way. From basic features to custom rule creation, Reach automates the deployment of our threat-informed configurations back to your tools, by generating configurations as code and change control tickets.  The need for product experts is no longer necessary to deliver the best possible security posture.`}
        mb="16px"
      />
      <Button
        mb="64px"
        variant="outline"
        size="lg"
        onClick={() =>
          window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
          })
        }
      >
        View Examples
      </Button>

      <Flex
        h={isMobile ? '240px' : '600px'}
        w="100%"
        position="relative"
        mb="64px"
      >
        <PostureAutomationImages isIn />
      </Flex>
      <TextBlurb
        heading={null}
        tagline="Problem"
        body={`It’s one thing to receive recommendations for product improvements, but how do you go about implementing them?  Countless hours and resources are needed to manually turn knobs or change settings, and that’s after you’ve combed through lengthy vendor manuals to find the setting you’re looking for.`}
        mb="64px"
      />
      <TextBlurb
        mb="32px"
        heading={null}
        tagline="How Reach helps"
        body={
          <Box>
            <Box mb="16px">
              Reach is the first of its kind to proactively automate
              configuration changes under the context of your
              organization&apos;s security posture - providing configurations as
              code, one-click remediation options, and change control tickets to
              supplement deployment. All candidate configurations are deployed
              as inactive, which enables teams to review as much as needed. The
              result? The best defensive posture for your organization.
            </Box>
          </Box>
        }
      />
    </>
  )
}

export default PostureAutomation
