import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Button, Center, LightMode, Heading, Icon } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { AiOutlineArrowRight } from 'react-icons/ai'
import FadeIn from 'components/Shared/FadeIn'
import { useApp } from 'context/App'

const SplashArea = () => {
  const { isMobile } = useApp()

  return (
    <LightMode>
      <ContentMaxWidth
        borderBottom="1px solid"
        borderColor="gray.200"
        justifyContent="center"
        color="#141414"
      >
        <Box py={isMobile ? '32px' : '160px'} px="16px" maxW="800px">
          <FadeIn>
            <Heading
              textAlign="center"
              size="3xl"
              fontSize={isMobile ? '48px' : '64px'}
              mb="32px"
              lineHeight={1.25}
              fontWeight="black"
            >
              More security doesn&apos;t mean more secure.
            </Heading>
          </FadeIn>
          <FadeIn delay={0.25}>
            <Heading
              fontSize="20px"
              size="lg"
              fontWeight="normal"
              textAlign="center"
              mb="48px"
              lineHeight={1.5}
              letterSpacing={0}
            >
              There are more security companies than ever, building more
              solutions than ever, while more attacks get through than ever.
              It’s clear that setting new mousetraps isn’t the answer. Reach
              gives you a better way to protect your business.
            </Heading>
          </FadeIn>
          <Center>
            <FadeIn delay={0.5}>
              <Button
                h="64px"
                as={NavLink}
                to="/connect"
                rightIcon={<Icon as={AiOutlineArrowRight} />}
                size="lg"
                colorSheme="reachBlue"
                fontSize="20px"
                mb={isMobile ? '56px' : '0px'}
              >
                Request a demo
              </Button>
            </FadeIn>
          </Center>
        </Box>
      </ContentMaxWidth>
    </LightMode>
  )
}

export default SplashArea
