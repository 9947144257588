import React, { useEffect } from 'react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import ResourcesBody from './ResourcesBody'
import { useApp } from 'context/App'

const Resources = () => {
  const { setHeaderBgColor } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <ResourcesBody />
      <PageFooter />
    </>
  )
}

export default Resources
