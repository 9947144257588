import React from 'react'
import PropTypes from 'prop-types'
import { Button, Flex, Box, Spacer, Heading, LightMode } from '@chakra-ui/react'
import { useApp } from 'context/App'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Card from 'components/Shared/Card'

const CustomerCard = ({ isMobileDesign, buttonText, ...props }) => {
  const { isMobile } = useApp()
  if (isMobile || isMobileDesign) {
    return <MobileCustomerCard buttonText={buttonText} {...props} />
  }
  return <DesktopCustomerCard buttonText={buttonText} {...props} />
}

CustomerCard.defaultProps = {
  buttonText: 'View Case Study',
}

CustomerCard.propTypes = {
  buttonText: PropTypes.string,
  isMobileDesign: PropTypes.bool,
}

const DesktopCustomerCard = ({
  img,
  logo,
  personName,
  personTitle,
  personDetails,
  title,
  body,
  caseStudyUrl,
  buttonText,
  ...remaining
}) => {
  const history = useHistory()
  return (
    <LightMode>
      <Card
        bg="#eee"
        p="40px"
        color="gray.900"
        borderRadius="16px"
        maxW="820px"
        display="flex"
        flexDir="column"
        {...remaining}
      >
        <Flex alignItems="flex-end" mb="32px" mt="-112px">
          <Box borderRadius="16px">
            <Flex
              borderRadius="50%"
              w="220px"
              h="220px"
              flexShrink={0}
              overflow="hidden"
              bgImage={img}
              bgSize="cover"
              bgPos="50% 50%"
              alignItems="flex-end"
              px="20px"
              py="8px"
            />
          </Box>

          <Box ml="32px">
            <Box h="32px" w="100%" mb="8px">
              <Box
                w="100%"
                h="100%"
                bgImage={logo}
                bgSize="contain"
                bgPos="0% 50%"
                bgRepeat="no-repeat"
              />
            </Box>
            <Box fontWeight="light" fontSize="24px" color={'gray.900'}>
              {personName} - {personTitle}
            </Box>
            <Box fontWeight="light" fontSize="24px" color={'gray.900'}>
              {personDetails}
            </Box>
          </Box>
        </Flex>

        <Box fontWeight="normal" fontSize="32px" mb="8px">
          {title}
        </Box>

        <Box fontWeight="light" fontSize="24px">
          {body}
        </Box>
        <Spacer />
        <Flex pt="24px" justifyContent="flex-end">
          <Button
            size="lg"
            variant="ghost"
            fontSize="24px"
            onClick={() => history.push(caseStudyUrl)}
            color="blue.600"
          >
            {buttonText}
          </Button>
        </Flex>
      </Card>
    </LightMode>
  )
}

DesktopCustomerCard.propTypes = {
  img: PropTypes.string,
  logo: PropTypes.string,
  personNameColor: PropTypes.string,
  personName: PropTypes.string,
  personTitle: PropTypes.string,
  personDetails: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.any,
  caseStudyUrl: PropTypes.string,
  buttonText: PropTypes.string,
}

const MobileCustomerCard = ({
  img,
  logo,
  personName,
  personTitle,
  title,
  body,
  caseStudyUrl,
  fillHeight,
  buttonText,
}) => {
  const history = useHistory()
  return (
    <LightMode>
      <Flex flexDir="column" h={fillHeight ? '100%' : 'auto'}>
        <Box
          w="100%"
          borderRadius="24px 24px 0 0"
          overflow="hidden"
          border="1px solid"
          borderBottom="0"
          borderColor="reachBlue.300"
        >
          <Flex
            w="100%"
            h="240px"
            overflow="hidden"
            bgImage={img}
            bgSize="cover"
            bgPos="50% 50%"
            alignItems="flex-end"
            px="12px"
            py="4px"
          >
            {personName && (
              <Box
                fontWeight="light"
                fontSize="16px"
                color="white"
                bg="#0004"
                px="8px"
              >
                {personName} - {personTitle}
              </Box>
            )}
          </Flex>
          {logo && (
            <Box p="32px" h="88px" w="100%" bg="white">
              <Box
                w="100%"
                h="100%"
                bgImage={logo}
                bgSize="contain"
                bgPos="50%"
                bgRepeat="no-repeat"
              />
            </Box>
          )}
        </Box>
        <Card
          pt={!logo ? '32px' : '0'}
          borderTop="0"
          bg="white"
          color="gray.900"
          borderTopRadius="0"
          flex={1}
        >
          <Heading
            fontWeight="normal"
            lineHeight={1.3}
            fontSize="24px"
            mb="16px"
          >
            {title}
          </Heading>
          {body}
          <Flex pt="24px" justifyContent="flex-end">
            <Button variant="ghost" onClick={() => history.push(caseStudyUrl)}>
              {buttonText}
            </Button>
          </Flex>
        </Card>
      </Flex>
    </LightMode>
  )
}

MobileCustomerCard.propTypes = {
  fillHeight: PropTypes.bool,
  img: PropTypes.string,
  logo: PropTypes.string,
  personName: PropTypes.string,
  personTitle: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.any,
  caseStudyUrl: PropTypes.string,
  buttonText: PropTypes.string,
}

export default CustomerCard
