import React, { useEffect, useRef } from 'react'
import {
  Box,
  Image,
  VStack,
  LightMode,
  Icon,
  Center,
  Flex,
  Link,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react'
import { useApp } from 'context/App'
import logoimage from '../images/computer_chip_small.jpeg'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import BackgroundTopology from 'components/Shared/BackgroundTopology'
import topoimg from 'assets/images/background_topo.png'
// import headshot from '../images/padlock.jpeg'
import { BsMouse } from 'react-icons/bs'
import {
  AiOutlineClockCircle,
  AiOutlineEye,
  AiOutlineSmile,
} from 'react-icons/ai'
import BackToTop from 'components/Shared/BackToTop'

const MAX_W = '1100px'

const SSLDecryption = () => {
  const { setIsWhiteHeader, isMobile } = useApp()

  const ref0 = useRef(null)
  const ref1 = useRef(null)
  const ref2 = useRef(null)

  const smallFontSize = isMobile ? '16px' : '20px'
  const mainFontSize = isMobile ? '20px' : '28px'
  const largeFontSize = isMobile ? '24px' : '36px'
  const quoteFontSize = isMobile ? '20px' : '26px'
  const headerFontSize = isMobile ? '32px' : '48px'

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsWhiteHeader(true)
    return () => setIsWhiteHeader(false)
  }, [])
  return (
    <Box>
      <BackToTop />
      <LightMode>
        <Box
          pt="128px"
          bg="white"
          h={isMobile ? 'auto' : '100vh'}
          color="black"
        >
          <ContentMaxWidth maxW="1400px" flexDir="column">
            <Flex alignItems="flex-start" flexDir={isMobile ? 'column' : 'row'}>
              <Box>
                <Image
                  borderRadius="16px"
                  h="96px"
                  mb={isMobile ? '48px' : '32px'}
                  src={logoimage}
                />
                <Box position="relative">
                  <Box
                    lineHeight={1.4}
                    fontSize={isMobile ? '24px' : '48px'}
                    fontWeight="light"
                    maxW="800px"
                  >
                    A Global Manufacturing Company’s Threat-Informed Approach to
                    Adopting SSL Decryption on their Palo Alto Networks
                    Firewalls
                  </Box>
                </Box>
              </Box>
              <VStack
                flexShrink={0}
                spacing="32px"
                ml={isMobile ? '0' : '40px'}
                mt={isMobile ? '32px' : '56px'}
                mb={isMobile && '72px'}
                color="reachBlue.500"
                alignItems="flex-start"
              >
                <Box>
                  <Link
                    onClick={() =>
                      ref1.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                  >
                    Identifying the risk
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    hiding in encrypted traffic
                  </Box>
                </Box>

                <Box display="block">
                  <Link
                    onClick={() =>
                      ref0.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                    whiteSpace="break-spaces"
                  >
                    Realized benefits
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    after decryption
                  </Box>
                </Box>

                <Box>
                  <Link
                    onClick={() =>
                      ref2.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                    fontWeight="normal"
                    fontSize={isMobile ? '24px' : '32px'}
                    variant="ghost"
                  >
                    Future plans
                  </Link>
                  <Box
                    fontSize={isMobile ? '24px' : '32px'}
                    fontWeight="light"
                    opacity=".7"
                  >
                    to maximize visibility
                  </Box>
                </Box>
              </VStack>
            </Flex>
            {!isMobile && (
              <Center mt="128px" flexDir="column">
                <Icon
                  mb="4px"
                  w="32px"
                  h="32px"
                  color="gray.600"
                  as={BsMouse}
                />
                <Box fontSize="14px" color="gray.600">
                  Scroll down
                </Box>
              </Center>
            )}
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <Box>
        <BackgroundTopology
          bgImage={topoimg}
          bgSize="cover"
          pt="128px"
          pb="128px"
          color="white"
        >
          <ContentMaxWidth
            maxW={MAX_W}
            flexDir="column"
            alignItems="flex-start"
          >
            <Box fontSize={headerFontSize} mb="32px">
              Background
            </Box>
            <Box fontSize="26px" fontWeight="light" mb="40px">
              A global leader in the manufacturing industry knew the benefits of
              utilizing SSL decryption on their Palo Alto Networks firewalls but
              were cautious to implement the capability. It’s been a top
              priority for their IT Security Manager, an expert in PAN-OS, since
              joining the company. Since 80-90% of their network traffic was
              encrypted, extending visibility would allow his team to get a more
              complete picture of threats entering and exiting the network.
            </Box>
          </ContentMaxWidth>
          <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
            <Box>
              <Box
                mb="16px"
                fontSize={isMobile ? '24px' : '34px'}
                width="fit-content"
                borderRadius="16px"
                position="relative"
              >
                <Box
                  bg="#0C153D66"
                  p="16px 32px"
                  borderRadius="8px"
                  position="relative"
                >
                  “You’re missing out on a huge chunk of your internet traffic
                  if you’re not implementing SSL decryption. The increase in
                  visibility cascades to increased actions on the traffic, like
                  file blocking and DLP, once you start getting this
                  visibility.”
                </Box>
              </Box>
              <Box
                fontSize={isMobile ? '18px' : '24px'}
                fontWeight="light"
                ml="32px"
                mb="32px"
              >
                - IT Security Manager
              </Box>
            </Box>
          </ContentMaxWidth>
          <ContentMaxWidth
            maxW={MAX_W}
            pt="32px"
            alignItems="flex-start"
            flexDir="column"
          >
            <Box mb="24px" fontSize="26px" fontWeight="light">
              Concerns from the business around privacy and operational impact
              kept the project on hold for the better part of 6 years. Deploying
              this capability for all SSL traffic and to every employee in the
              workforce was not feasible. If he wanted buy in, the IT Security
              Manager knew he needed a data-driven, threat-informed approach the
              rolling it out in phases, and to do so he needed to:
            </Box>
            <Box
              ml={isMobile ? '24px' : '48px'}
              mb="16px"
              fontSize={mainFontSize}
              fontWeight="light"
            >
              <Box display="inline" fontWeight="medium">
                1.
              </Box>{' '}
              Identify who was being attacked the most in the organization
            </Box>
            <Box
              ml={isMobile ? '24px' : '48px'}
              mb="16px"
              fontSize={mainFontSize}
              fontWeight="light"
            >
              <Box display="inline" fontWeight="medium">
                2.
              </Box>{' '}
              Identify where attackers were hiding their activities in encrypted
              web-traffic
            </Box>
          </ContentMaxWidth>
        </BackgroundTopology>
      </Box>

      <Box bg="white" color="black" py="128px">
        <ContentMaxWidth
          maxW={MAX_W}
          alignItems="flex-start"
          flexDir="column"
          fontWeight="normal"
        >
          <Box mb="64px" fontSize="36px" textAlign="center" width="100%">
            Here are the results of this effort.
          </Box>
          <Flex
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            flexDir={isMobile ? 'column' : 'row'}
          >
            <Center
              mb={isMobile && '24px'}
              w="260px"
              h="260px"
              borderRadius="50%"
              bg="reachTeal.400"
            >
              <Flex color="white" alignItems="center" flexDir="column">
                <Icon w="32px" h="32px" as={AiOutlineClockCircle} />
                <Box fontSize="56px" fontWeight="light" lineHeight={1.3}>
                  52
                </Box>
                <Box textAlign="center" maxW="200px" fontSize="22px">
                  Weeks worth of people hours saved
                </Box>
              </Flex>
            </Center>

            <Center
              mb={isMobile && '24px'}
              w="260px"
              h="260px"
              borderRadius="50%"
              bg="reachTeal.400"
            >
              <Flex color="white" alignItems="center" flexDir="column">
                <Icon w="32px" h="32px" as={AiOutlineSmile} />
                <Box fontSize="72px" fontWeight="light" lineHeight={1.2}>
                  0
                </Box>
                <Box textAlign="center" maxW="200px" fontSize="22px">
                  Operational impact to the business
                </Box>
              </Flex>
            </Center>

            <Center
              mb={isMobile && '24px'}
              w="260px"
              h="260px"
              borderRadius="50%"
              bg="reachTeal.400"
            >
              <Flex color="white" alignItems="center" flexDir="column">
                <Icon w="32px" h="32px" as={AiOutlineEye} />
                <Box
                  textAlign="center"
                  fontSize="56px"
                  fontWeight="normal"
                  lineHeight={1.2}
                >
                  77%
                </Box>
                <Box
                  textAlign="center"
                  maxW="200px"
                  fontSize="22px"
                  lineHeight={1.2}
                >
                  Increase in threat visibility on the network
                </Box>
              </Flex>
            </Center>
          </Flex>
        </ContentMaxWidth>
      </Box>

      {/* Text Quote Section 2 */}
      <Box
        ref={ref1}
        bg="gray.100"
        color="black"
        pt="120px"
        pb="24px"
        fontSize={mainFontSize}
        fontWeight="light"
      >
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="48px" fontSize={headerFontSize} fontWeight="medium">
            Identifying attacks using encrypted communication
          </Box>
          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            The IT Security Manager’s first step was to look beyond what the
            network could currently see. He knew historical attack data unique
            to their environment was available in their security products, like
            email security and endpoint, and could be used to inform their
            adoption of decryption. However, his team did not have the time or
            resources to pull forensics and analyze attack types from disparate
            sources. Threat modeling using this data would allow him to
            effectively quantify and communicate which users needed decryption
            the most.
          </Box>

          <ContentMaxWidth pt="40px" alignItems="flex-start" maxW={MAX_W}>
            <Box mb="48px">
              <Box
                mb="16px"
                fontSize={quoteFontSize}
                width="fit-content"
                borderRadius="16px"
                position="relative"
              >
                <Box
                  bg="white"
                  p="16px 32px"
                  borderRadius="8px"
                  position="relative"
                  fontWeight="normal"
                >
                  “I could write scripts to parse email forensics related to
                  URLs used in attacks, or iterate through alerts tied to our
                  workforce’s endpoints - but that is a manual, lengthy, and
                  resource-intensive process.”
                </Box>
              </Box>
              <Box ml="32px" fontSize={smallFontSize} fontWeight="light">
                - IT Security Manager
              </Box>
            </Box>
          </ContentMaxWidth>

          <Box mb="16px" fontSize={mainFontSize} fontWeight="light">
            Within minutes, Reach connected to their security stack and:
          </Box>
          <Box
            ml={isMobile ? '24px' : '48px'}
            mb="16px"
            fontSize={mainFontSize}
            fontWeight="light"
          >
            <Box display="inline" fontWeight="medium">
              1.
            </Box>{' '}
            Analyzed email forensics to quantify the number of threats and
            threat types utilizing encryption in their delivery
          </Box>
          <Box ml={isMobile ? '24px' : '48px'} mb="32px" fontWeight="light">
            <Box display="inline" fontWeight="medium">
              2.
            </Box>{' '}
            Correlated these threats with workforce data to determine which
            users received these attacks, which were riskier than average, and
            which had a higher risk contribution relative to the rest of the
            workforce
          </Box>
          <Box fontSize={largeFontSize} fontWeight="normal" mb="16px">
            The result? A prioritized list of risky users derived from threat
            data and a realization that 4% of the population accounted for 70%
            of the company’s overall risk.
          </Box>
        </ContentMaxWidth>
        <ContentMaxWidth pt="40px" alignItems="flex-start" maxW={MAX_W}>
          <Box>
            <Box
              mb="16px"
              fontSize={quoteFontSize}
              width="fit-content"
              borderRadius="16px"
              position="relative"
            >
              <Box
                bg="white"
                p="16px 32px"
                borderRadius="8px"
                position="relative"
                fontWeight="normal"
              >
                “With Reach, I’m using terms like ‘these users provide more risk
                to the company overall’, and I’m actually able to give concrete
                numbers and something valuable to leadership.”
              </Box>
            </Box>
            <Box ml="32px" fontSize={smallFontSize} fontWeight="light">
              - IT Security Manager
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      <Box bg="gray.100" color="black" pb="120px">
        <ContentMaxWidth
          maxW={MAX_W}
          alignItems="flex-start"
          flexDir="column"
          fontSize={mainFontSize}
        >
          <Box mb="16px" fontWeight="light">
            Leadership now had data to understand that a majority of risk lived
            with a minority of users and gave the green light to start SSL
            decryption on a test group of those that were most attacked.
          </Box>

          <Box mb="32px" fontWeight="light">
            Having found the 4% of users posing the highest risk to the
            business, the next hurdle was identifying the URL categories that
            would extend visibility into the highest concentration of threats.
            Decrypting all SSL traffic out of the gate was not an option as
            doing so would:
          </Box>
          <UnorderedList
            spacing="16px"
            ml={isMobile ? '24px' : '72px'}
            mb="48px"
          >
            <ListItem>
              Significantly impact the firewall’s throughput and memory
              consumption
            </ListItem>
            <ListItem>
              Require expensive hardware upgrades across the network outside of
              their refresh lifecycle
            </ListItem>
            <ListItem>
              Require for a large amount of the employee time to monitor and
              manage
            </ListItem>
          </UnorderedList>

          <Box mb="32px">
            <Box
              mb="16px"
              fontSize={quoteFontSize}
              width="fit-content"
              borderRadius="16px"
              position="relative"
            >
              <Box
                bg="white"
                p="16px 32px"
                borderRadius="8px"
                position="relative"
                fontWeight="normal"
              >
                “The security professional in me wants to do it all, but it’s
                not reasonable for me to go from no SSL decryption to full SSL
                decryption on all traffic.”
              </Box>
            </Box>
            <Box ml="32px" fontSize={smallFontSize} fontWeight="light">
              - IT Security Manager
            </Box>
          </Box>

          <Box fontWeight="light" fontSize={mainFontSize} mb="48px">
            The IT Security Manager knew he could follow a similar threat
            modeling approach in analyzing logs and forensics from their
            endpoint and email security products to figure out which URL
            categories were tied to the highest concentration of threats
            targeting their users. He could then utilize this data to justify
            decrypting traffic on the same categories on his firewalls. But once
            again, manually conducting this exercise would require resources
            that he didn&apos;t have.
          </Box>

          <Box fontWeight="light" fontSize={mainFontSize} mb="16px">
            With Reach connected to their products, the IT Security Leader was
            able to get immediate visibility into:
          </Box>
          <UnorderedList
            spacing="16px"
            ml={isMobile ? '24px' : '72px'}
            mb="48px"
          >
            <ListItem>
              The 85% of attacks that leveraged encrypted traffic as part of
              their attack life cycle
            </ListItem>
            <ListItem>
              Network activity from attacks translated into content filtering
              categories
            </ListItem>
            <ListItem>
              Granular risk reduction metrics as a result of enabling decryption
              and extending visibility into traffic stemming from these
              categories
            </ListItem>
          </UnorderedList>

          <Box mb="32px">
            <Box
              mb="16px"
              fontSize={quoteFontSize}
              width="fit-content"
              borderRadius="16px"
              position="relative"
            >
              <Box
                bg="white"
                p="16px 32px"
                borderRadius="8px"
                position="relative"
                fontWeight="normal"
              >
                “With Reach, I’m now able to say:
                <Box pl="32px" py="16px">
                  ‘I’m not decrypting all URL traffic, I’m decrypting these
                  categories based on attacks that we’ve seen and reviewed. I’m
                  decrypting these categories for our highest risk users.’
                </Box>{' '}
                It’s much more manageable.”
              </Box>
            </Box>
            <Box ml="32px" fontSize={smallFontSize} fontWeight="light">
              - IT Security Manager
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* GRAY BIG TEXT SECTION */}

      <Box ref={ref0} bg="white" pt="128px" pb="64px">
        <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
          <Box>
            <Box
              color="gray.900"
              fontSize={headerFontSize}
              fontWeight="medium"
              mb="24px"
            >
              Realized benefits
            </Box>
            <Box
              color="gray.900"
              fontSize={mainFontSize}
              fontWeight="light"
              mb="16px"
            >
              With a phased plan to roll out SSL decryption already in motion,
              the IT Security Leader has his eyes set on how he’ll use the data
              to enhance protections across the board. What started as a staged
              approach to SSL decryption is now enabling the business to
              maximize the return on their firewall investment while
              simultaneously enabling the security teams to provide the best
              security possible for the organization.
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* text and quote section */}
      <Box bg="white" color="gray.900" pb="40px">
        <ContentMaxWidth alignItems="flex-start" maxW={MAX_W}>
          <Box>
            <Box
              mb="16px"
              fontSize={quoteFontSize}
              width="fit-content"
              borderRadius="16px"
              position="relative"
            >
              <Box
                bg="gray.100"
                p="16px 32px"
                borderRadius="8px"
                position="relative"
              >
                “Once SSL decryption is in place for the entire company, I’ll
                move on to deploying Reach’s file blocking profile
                recommendations given that files were previously masked in our
                traffic. It’s difficult to do this effectively when a majority
                of your network traffic is encrypted.”
              </Box>
            </Box>
            <Box
              mb="32px"
              ml="32px"
              fontSize={smallFontSize}
              fontWeight="light"
            >
              - IT Security Manager
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* white big text section */}
      <Box bg="white" color="black" pb="96px">
        <ContentMaxWidth maxW={MAX_W} alignItems="flex-start" flexDir="column">
          <Box mb="48px" fontSize={mainFontSize} fontWeight="light">
            On top of maximizing firewall capabilities, he has also seen a huge
            impact on the data quality in their SIEM as a result of having
            richer data sets of network traffic.
          </Box>

          <Box mb="32px">
            <Box
              mb="16px"
              fontSize={quoteFontSize}
              width="fit-content"
              borderRadius="16px"
              position="relative"
            >
              <Box
                bg="gray.100"
                p="16px 32px"
                borderRadius="8px"
                position="relative"
                fontWeight="normal"
              >
                “Our SIEM has also become better with decryption. We have better
                logs to review and our incident data for analysts to take action
                on has improved significantly.”
              </Box>
            </Box>
            <Box ml="32px" fontSize={smallFontSize} fontWeight="light">
              - IT Security Manager
            </Box>
          </Box>
        </ContentMaxWidth>
      </Box>

      {/* Text Quote Section 3 */}
      <Box ref={ref2} bg="gray.100" color="white">
        <BackgroundTopology py="120px">
          <ContentMaxWidth
            maxW={MAX_W}
            alignItems="flex-start"
            flexDir="column"
          >
            <Box mb="48px" fontSize={headerFontSize} fontWeight="medium">
              What’s next?
            </Box>
            <Box mb="64px" fontSize={mainFontSize} fontWeight="light">
              At the end of the day, data wins. Aggregating and normalizing data
              from disparate security tools, vendor agnostic, gave the
              manufacturing company a clear path to a decision to extend
              visibility across their network. The feedback from across the
              board from both end users and leadership has been overwhelmingly
              positive, and the IT Security Manager is looking forward to
              amplifying the roll out throughout the rest of the year. He
              expects to have SSL decryption enabled for the rest of the
              company’s workforce by 2023 given the early success across the
              board.
            </Box>
            <Box>
              <Box
                mb="16px"
                fontSize={isMobile ? '24px' : '34px'}
                width="fit-content"
                borderRadius="16px"
                position="relative"
              >
                <Box
                  bg="#0C153D66"
                  p="16px 32px"
                  borderRadius="8px"
                  position="relative"
                >
                  “With the way things are going right now, 2023 is 100% in
                  scope for us to roll out decryption further across the rest of
                  the company.”
                </Box>
              </Box>
              <Box
                fontSize={isMobile ? '18px' : '24px'}
                fontWeight="light"
                ml="32px"
                mb="32px"
              >
                - IT Security Manager
              </Box>
            </Box>
          </ContentMaxWidth>
        </BackgroundTopology>
      </Box>
    </Box>
  )
}

export default SSLDecryption
