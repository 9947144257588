import React from 'react'
import PropTypes from 'prop-types'
import TextBlurb from 'components/Shared/DeprecatedTextBlurb'
import { useApp } from 'context/App'
import { Box, Button, Flex, ListItem, UnorderedList } from '@chakra-ui/react'
import { AnimImage } from 'components/Deprecated/Home/MiddleSection2'

import toolsRationalizationBody from '../Home/MiddleSection/content/tools_rationalization_body.png'
import toolsRationalizationProduct1 from '../Home/MiddleSection/content/tools_rationalization_product_1.png'
import toolsRationalizationProduct2 from '../Home/MiddleSection/content/tools_rationalization_product_2.png'
import toolsRationalizationProduct3 from '../Home/MiddleSection/content/tools_rationalization_product_3.png'
import toolsRationalizationProduct4 from '../Home/MiddleSection/content/tools_rationalization_product_4.png'

export const ToolsRationalizationImages = ({ isIn }) => {
  const { isMobile } = useApp()
  return (
    <>
      <AnimImage
        isIn={isIn}
        boxShadow="1px 2px 16px rgba(0,0,0,.15)"
        borderRadius="8px"
        border="1px solid #1D1E21"
        src={toolsRationalizationBody}
        x="0px"
        y="0px"
        h={isMobile ? null : '560px'}
        w={isMobile ? '100%' : null}
        delay={0}
      />
      <AnimImage
        isIn={isIn}
        src={toolsRationalizationProduct1}
        x={isMobile ? '5px' : '4px'}
        h={isMobile ? '150px' : '390px'}
        y={isMobile ? '64px' : '160px'}
        delay={0.4}
      />
      <AnimImage
        isIn={isIn}
        src={toolsRationalizationProduct2}
        x={isMobile ? '76px' : '194px'}
        h={isMobile ? '150px' : '390px'}
        y={isMobile ? '64px' : '160px'}
        delay={0.6}
      />
      <AnimImage
        isIn={isIn}
        src={toolsRationalizationProduct3}
        x={isMobile ? '147px' : '382px'}
        h={isMobile ? '150px' : '390px'}
        y={isMobile ? '64px' : '160px'}
        delay={0.8}
      />
      <AnimImage
        isIn={isIn}
        src={toolsRationalizationProduct4}
        x={isMobile ? '218px' : '568px'}
        h={isMobile ? '150px' : '390px'}
        y={isMobile ? '64px' : '160px'}
        delay={1}
      />
    </>
  )
}
ToolsRationalizationImages.propTypes = {
  isIn: PropTypes.bool,
}

const ToolsRationalization = () => {
  const { isMobile } = useApp()

  return (
    <>
      <TextBlurb
        heading="Tools Rationalization"
        tagline="Immediate understanding of each tool’s ability to stop threats relevant to your organization."
        body={`Security teams today are tasked with deploying, configuring and managing an arsenal of products to defend their networks, but there is no data-driven way to measure and manage the value they’re getting from them. Reach allows you to benchmark current  configurations against the art of the possible to measure and manage the value you’re getting from them continuously. Report how well your tools are performing with visualizations, and make error free configuration changes with recommendations as code to improve enterprise security posture.`}
        mb="16px"
      />
      <Button
        mb="64px"
        variant="outline"
        size="lg"
        onClick={() =>
          window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
          })
        }
      >
        View Examples
      </Button>

      <TextBlurb
        heading={null}
        tagline="Measuring and managing value from deployed tools"
        body={null}
      />
      <Flex
        h={isMobile ? '260px' : '600px'}
        w="100%"
        position="relative"
        mb="64px"
      >
        <ToolsRationalizationImages
          isIn
          w="auto"
          h={isMobile ? '260px' : '600px'}
          x="0"
        />
      </Flex>

      <TextBlurb
        heading={null}
        tagline="Problem"
        body={`The tools you work with on a day-to-day basis have hundreds or thousands of features. Measuring what is best for your organization is difficult and resource intensive, leading to work being informed by experience or best practice guidance instead of quantitative data. Operators don't have time to master manuals or monitor for new features, nor do they have time to figure out how the latest tool fits into the rest of the stack.`}
        mb="64px"
      />

      <TextBlurb
        mb="32px"
        heading={null}
        tagline="How Reach helps"
        body={`Reach knows how your organization is being attacked and how your tools can help. Using context on how you’re being attacked from the products you own, Reach baselines your current products’ configurations against a set of recommendations to highlight protection gaps.`}
      />
      <Box fontSize="18px" lineHeight={1.7} color="reachBlue.100" mb="8px">
        For all of your tools, Reach provides quantifiable metrics for
        improvement in the form of:
      </Box>
      <UnorderedList fontSize="18px" lineHeight={1.7} color="reachBlue.100">
        <ListItem>Disabled controls that should be enabled</ListItem>
        <ListItem>Controls where modification is recommended</ListItem>
        <ListItem>
          Advanced feature adoption or custom rule recommendations
        </ListItem>
      </UnorderedList>
    </>
  )
}

export default ToolsRationalization
