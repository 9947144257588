import React from 'react'
import { useApp } from 'context/App'
import { Box, LightMode, Heading, Button, Center, Icon } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import FadeIn from 'components/Shared/FadeIn'

const TryReach = () => {
  const { isMobile } = useApp()
  const history = useHistory()
  const handleTraClick = () => {
    history.push('/try-reach')
  }

  return (
    <LightMode>
      <Box bg="gray.50">
        <ContentMaxWidth
          display="block"
          py={isMobile ? '0px' : '140px'}
          justifyContent="center"
          color="#1a1a1a"
          bg="#141414"
        >
          <FadeIn>
            <Box bg="reachTeal.400" p={isMobile ? '64px 32px' : '64px'}>
              <Center>
                <Box>
                  <FadeIn>
                    <Heading
                      letterSpacing={-2}
                      textAlign="left"
                      size="2xl"
                      mb="32px"
                      pb="16px"
                      borderBottom="1px solid #111"
                    >
                      Assess your tools today.
                    </Heading>
                  </FadeIn>
                  <FadeIn delay={0.3}>
                    <Box
                      fontSize="26px"
                      letterSpacing={-0.75}
                      fontWeight="medium"
                      textAlign="left"
                      pb="32px"
                    >
                      Get metrics-backed answers to your questions you have
                      about the value your tools are delivering to your
                      enterprise in just a few minutes
                    </Box>
                  </FadeIn>
                </Box>
              </Center>
              <FadeIn delay={0.6}>
                <Button
                  h="72px"
                  w="100%"
                  rightIcon={<Icon as={AiOutlineArrowRight} />}
                  onClick={handleTraClick}
                  size="lg"
                  colorScheme="reachTeal"
                  fontSize="24px"
                  borderRadius="0"
                >
                  Get a free assessment
                </Button>
              </FadeIn>
            </Box>
          </FadeIn>
        </ContentMaxWidth>
      </Box>
    </LightMode>
  )
}

export default TryReach
