import React, { useEffect, useState } from 'react'
import { Center, Heading } from '@chakra-ui/react'
import InView from 'components/Shared/InView'

const INTERVAL = 10
const logFn = (num, base = 0.5) => num / (num + base)
const getPrecision = (num) => (num > 0.99 ? (num > 0.999 ? 3 : 2) : 1)

const ReachBoostAnim = (...props) => {
  const [activeTextVariant, setActiveTextVariant] = useState('out')
  const [isInView, setIsInView] = useState(false)
  const [valueMultiplier, setValueMultipler] = useState(0.25)

  const logVal1 = logFn(valueMultiplier, 0.04)

  const precision1 = getPrecision(logVal1)

  useEffect(() => {
    let interval
    if (isInView) {
      setActiveTextVariant('in')
      setTimeout(() => {
        interval = setInterval(() => {
          setValueMultipler((val) => val + 1)
        }, INTERVAL)
      }, 1100)
      setTimeout(() => {
        setActiveTextVariant('loop')
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [isInView])

  return (
    <InView
      onInView={() => setIsInView(true)}
      maxW="100vw"
      pointerEvents="none"
    >
      <Center>
        <Heading
          textAlign="center"
          lineHeight={1}
          fontSize="48px"
          mb="16px"
          fontWeight="bold"
          fontFamily="mono"
          {...props}
        >
          {activeTextVariant === 'loop'
            ? `+${(100 * logVal1).toFixed(precision1)}%`
            : '???'}
        </Heading>
      </Center>
    </InView>
  )
}
export default ReachBoostAnim
