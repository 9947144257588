import { Box, Center, Flex, Text, Image } from '@chakra-ui/react'
import React from 'react'
import BackgroundTopology from './BackgroundTopology'
import ReachLogo from './ReachLogo'
import bgTopo from 'assets/images/background_topo_darker.png'
import logo from 'assets/images/Autodesk_Logo.svg'

const ReachLogoSplash = () => {
  return (
    <Box h="100vh" w="100%">
      <BackgroundTopology
        bgImage={bgTopo}
        bgSize="100%"
        bgPos="50% 50%"
        gradientStartColor="white"
        gradientEndColor="#DDE0E8"
        h="100%"
      >
        <Center h="100%" pl="40px" flexDir="column">
          <Box h="240px">
            <Image transform="translateY(120px)" h="60px" src={logo} />
          </Box>
          <Box
            transform="translateY(-40px)"
            fontWeight="light"
            fontSize="128px"
          >
            +
          </Box>
          <Flex transform="translateY(-80px)" mr="24px">
            <ReachLogo mr="12px" logoHeight="108px" noText letterSpacing={3} />
            <Text
              onClick={() => history.push('/')}
              cursor="pointer"
              color="reachBlue.500"
              fontWeight="medium"
              fontSize={116}
              fontFamily="GT Walsheim Pro"
              letterSpacing={3}
            >
              Reach
            </Text>
          </Flex>
        </Center>
      </BackgroundTopology>
    </Box>
  )
}

export default ReachLogoSplash
