import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Icon,
  LightMode,
  Link,
  Spacer,
} from '@chakra-ui/react'
import youtubeThumbnail from 'youtube-thumbnail'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import FadeIn from 'components/Shared/FadeIn'
import { RiExternalLinkLine } from 'react-icons/ri'

const VideoEntry = ({ url, imageUrl, title, description, delay = 0 }) => {
  return (
    <FadeIn delay={delay}>
      <Flex
        flexDir="column"
        w="100%"
        h="100%"
        p="32px"
        bg="black"
        color="white"
      >
        <Box
          transition="0.5s"
          border="2px solid"
          borderColor="reachTeal.400"
          overflow="hidden"
        >
          <Box
            bgImage={`url(${imageUrl})`}
            transition=".25s"
            bgSize="cover"
            bgPos="50% 50%"
            h="248px"
            flexShrink={0}
          />
        </Box>
        <Flex pt="16px" flexDir="column" flex={1}>
          <Heading mb="4px" size="xl" fontSize="24px">
            {title}
          </Heading>
          <Box mb="16px" lineHeight={1.2} fontWeight="light" fontSize="16px">
            {description}
          </Box>
          <Spacer />
          <Button
            rightIcon={<Icon as={RiExternalLinkLine} />}
            colorScheme="reachTeal"
            as={Link}
            target="_blank"
            href={url}
          >
            Watch Video
          </Button>
        </Flex>
      </Flex>
    </FadeIn>
  )
}

VideoEntry.propTypes = {
  delay: PropTypes.number,
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

const YoutubeVideoEntry = ({ url, ...remaining }) => {
  const imageUrl = youtubeThumbnail(url)

  return <VideoEntry imageUrl={imageUrl.high.url} url={url} {...remaining} />
}

YoutubeVideoEntry.propTypes = {
  url: PropTypes.string,
}

const VideosBody = () => {
  return (
    <LightMode>
      <ContentMaxWidth maxW="1600px" h="100%" alignItems="start">
        <Box pt="64px">
          <Grid
            gridTemplateColumns="repeat(auto-fit, minmax(320px, 1fr));"
            gap="16px"
          >
            <YoutubeVideoEntry
              delay={0}
              title="F Your ML Model"
              description="Dive into the complexities, strengths, shortcomings, and novel uses of machine learning with Colt in this BSides talk."
              url="https://www.youtube.com/watch?v=gIo3a488E0g&t=129s&ab_channel=BSidesLV"
            />
            <YoutubeVideoEntry
              delay={0.33}
              title="Best Practices Are the Worst"
              description="Organizations are asked, told, reminded, and even have their security measured against industry best practices configurations. But could they actually be detrimental to your company and the industry as a whole?"
              url="https://www.youtube.com/watch?v=DOSL6ZjSUn0&ab_channel=SAINTCON"
            />
            <Box />
          </Grid>
        </Box>
      </ContentMaxWidth>
    </LightMode>
  )
}

export default VideosBody
