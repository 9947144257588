import React from 'react'
import { Box, Grid } from '@chakra-ui/react'
import CustomerCard from 'components/Deprecated/Customers/CustomerCard'
import MotionBoxInView from 'components/Shared/MotionBoxInView'
import { ResponsiveHeading } from 'components/Shared/DeprecatedTextBlurb'
import microChip from '../Customers/images/computer_chip.jpeg'
import pancakes from '../Customers/images/SSOHardening/topography.png'
import orange from '../Customers/images/ToolsRationalizationOrangeFull.jpeg'

const MoreUseCases = () => (
  <Box>
    <MotionBoxInView
      transformOrigin="0% 50%"
      animateIn={{ y: -8, opacity: 1 }}
      animateOut={{ y: 0, opacity: 0.8 }}
      transition={{ duration: 1.25 }}
    >
      <ResponsiveHeading
        fontWeight="semibold"
        fontSize={48}
        color="white"
        lineHeight={1}
        mt="96px"
        mb="16px"
      >
        More Use Cases
      </ResponsiveHeading>
    </MotionBoxInView>
    <Grid
      maxW="100%"
      gridGap="24px"
      gridTemplateColumns="repeat(auto-fit, minmax(80px, 440px));"
      alignItems="center"
      alignContent="center"
    >
      <CustomerCard
        isMobileDesign
        fillHeight
        personName={null}
        personDetails="IT Security Manager"
        img={microChip}
        buttonText="View Use Case"
        title="A Global Manufacturing Company’s Threat-Informed Approach to Adopting SSL Decryption on their Palo Alto Networks Firewalls"
        body={
          <Box>
            <Box mb="16px">
              “You’re missing out on a huge chunk of your internet traffic if
              you’re not implementing SSL decryption. The increase in visibility
              cascades to increased actions on the traffic, like file blocking
              and DLP, once you start getting this visibility.”
            </Box>
            <Box>- IT Security Manager</Box>
          </Box>
        }
        caseStudyUrl="/customers/ssl-decryption"
      />
      <CustomerCard
        isMobileDesign
        fillHeight
        personName={null}
        personDetails="IT Security Manager"
        img={pancakes}
        buttonText="View Use Case"
        title="Assume Credential Compromise: Implementing Layered Protections using Okta"
        body={
          <Box>
            There’s no set of security controls on the market that are presented
            as a “silver bullet” more than Multi-factor Authentication (MFA) and
            Single Sign-On (SSO). Most organizations end up deploying the best
            practices and are left with the bare minimum security value. Learn
            more on how threat data can be used to harden security policy in the
            event of credential compromise without impact to the business.
          </Box>
        }
        caseStudyUrl="/customers/okta-layered-protection"
      />
      <CustomerCard
        isMobileDesign
        fillHeight
        personName={null}
        personDetails={null}
        img={orange}
        buttonText="View Use Case"
        title="Rationalizing Security Tool Spend"
        body={
          <Box>
            More and more IT and Security leaders are talking about the need to
            “do more with less”, with “less” translating to less resources,
            tools, full-time employees, consultants, and support. While this
            sounds great in theory, wouldn’t a more realistic approach be “do
            more with what we have?”
          </Box>
        }
        caseStudyUrl="/customers/tools-rationalization"
      />
    </Grid>
  </Box>
)

export default MoreUseCases
