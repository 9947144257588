import React from 'react'
import PropTypes from 'prop-types'
import MotionBoxInView from './MotionBoxInView'

const FadeIn = ({ y, duration, delay, baseDelay, ease, ...props }) => {
  return (
    <MotionBoxInView
      transition={{ duration, delay: delay + baseDelay, ease }}
      animateIn={{ opacity: 1, y: 0 }}
      animateOut={{ opacity: 0, y }}
      {...props}
    />
  )
}
FadeIn.propTypes = {
  y: PropTypes.number,
  duration: PropTypes.number,
  delay: PropTypes.number,
  baseDelay: PropTypes.number,
  ease: PropTypes.string,
}

FadeIn.defaultProps = {
  y: 50,
  duration: 1,
  delay: 0,
  baseDelay: 0.2,
  ease: 'easeOut',
  inViewOptions: { triggerOnce: true },
}

export default FadeIn
