import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

const ExternalRedirect = ({ url }) => {
  useEffect(() => {
    window.location.href = url
  }, [])
  return <Box />
}

ExternalRedirect.propTypes = {
  url: PropTypes.string,
}

export default ExternalRedirect
