import React, { useEffect } from 'react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import SplashArea from './SplashArea'
import { Box } from '@chakra-ui/react'
import WhatWeDo from './WhatWeDo'
// import HowWeDoIt from './HowWeDoIt'
import Integrations from './Integrations'
import TryReach from './TryReach'
import Banner from 'components/Shared/Banner'

const HomePage = () => {
  const { setIsWhiteHeader, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsWhiteHeader(true)
    return () => setIsWhiteHeader(false)
  }, [])

  return (
    <>
      <Banner />
      <PageHeader top={isMobile ? '88px' : '48px'} isWhite />
      {/* Box is here for spacing - header is positioned absolute (for reasons) */}
      <Box h="96px" />
      <SplashArea />
      <WhatWeDo />
      {/* <HowWeDoIt /> */}
      <Integrations />
      <TryReach />
      <PageFooter />
    </>
  )
}
export default HomePage
