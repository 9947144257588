import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Icon, LightMode, Link, Heading } from '@chakra-ui/react'
import PercentAnimation from './PercentAnimation'
import { AiOutlineDollar, AiOutlineEye, AiOutlineHeart } from 'react-icons/ai'
import { BsLightning } from 'react-icons/bs'
import { useApp } from 'context/App'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import FadeIn from 'components/Shared/FadeIn'

const Subheader = ({ text, icon }) => (
  <Flex alignItems="center">
    <Icon w="24px" h="24px" as={icon} mr="16px" color="reachTeal.400" />
    <Heading
      lineHeight={1.5}
      fontWeight="medium"
      color="reachTeal.400"
      fontSize="28px"
    >
      {text}
    </Heading>
  </Flex>
)

Subheader.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
}

const DemoFeature = ({ delay = 0, text, icon, bulletText }) => {
  return (
    <FadeIn delay={delay}>
      <Box p="16px" py="12px" bg="black">
        <Subheader icon={icon} text={text} />
        <BulletText>{bulletText}</BulletText>
      </Box>
    </FadeIn>
  )
}

DemoFeature.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.any,
  bulletText: PropTypes.string,
  delay: PropTypes.number,
}

const BulletText = ({ ...props }) => (
  <Box
    color="white"
    letterSpacing={-0.5}
    fontWeight="light"
    fontSize="20px"
    lineHeight={1.5}
    ml="40px"
    {...props}
  />
)

const DemoProcess = () => {
  const { isMobile } = useApp()
  const history = useHistory()

  return (
    <LightMode>
      <Box color="black" maxW="720px">
        <Flex
          flexDir={isMobile ? 'column' : 'row'}
          alignItems="center"
          mb={isMobile ? '56px' : '24px'}
        >
          <Heading
            fontWeight="medium"
            lineHeight={1.5}
            fontSize="36px"
            mr={!isMobile && '24px'}
            mb={isMobile ? '32px' : '4px'}
            textAlign={isMobile ? 'center' : 'left'}
          >
            Increase Effectiveness by
          </Heading>
          <PercentAnimation />
        </Flex>

        <Flex flexDir="column" gap={isMobile ? '0px' : '8px'}>
          <DemoFeature
            text="Save Money"
            icon={AiOutlineDollar}
            bulletText="Get the data driven answers you need to questions that will reduce cyber insurance premiums."
          />
          <DemoFeature
            delay={0.33}
            text="No Commitments"
            icon={AiOutlineHeart}
            bulletText="Reach will provide a usage assessment for one security product, on the house."
          />
          <DemoFeature
            delay={0.66}
            text="Lightweight, frictionless API connectivity"
            icon={AiOutlineEye}
            bulletText="Reach only requests read-only API tokens to analyze your configurations (or our pre-bundled app if you’re a Microsoft shop)"
          />
          <DemoFeature
            delay={1}
            text="Lightning fast"
            icon={BsLightning}
            bulletText="Set up in 5 minutes. We’ll give you a report to rationalize security value now and where you could be in the future."
          />
        </Flex>

        <Box
          textAlign={isMobile ? 'center' : 'left'}
          mx="16px"
          pb="8px"
          mt="64px"
          fontSize="20px"
        >
          Have a question?{' '}
          <Link onClick={() => history.push('/connect')}>Talk to our team</Link>
        </Box>
      </Box>
    </LightMode>
  )
}

export default DemoProcess
