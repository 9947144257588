/* eslint-disable react/no-unescaped-entities */
// eslint-disable react/no-unused-vars

import {
  Box,
  Heading,
  LightMode,
  Grid,
  Flex,
  Button,
  Link,
  Icon,
} from '@chakra-ui/react'
import { AiOutlineArrowRight } from 'react-icons/ai'
import HubspotForm from 'react-hubspot-form'
import { NavLink } from 'react-router-dom'
import { useApp } from 'context/App'
import React from 'react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import TextBlurb from 'components/Shared/TextBlurb'
import Testimonial from 'components/Shared/Testimonial'
import autodeskLogo from 'assets/images/autodesk_logo.png'
import nutanixLogo from 'components/Deprecated/Customers/images/nutanixLogo.svg'
import meetReach from 'assets/images/black_hat_meet_reach_founders.png'
import reachOnePagerImage from 'assets/images/reachOnePager.png'
import { BlogImage } from '../Blog/BlogPosts/TRABlog'

const BlackHatMeetReach = () => {
  const { isMobile } = useApp()
  const footerButtonFontSize = isMobile ? '16px' : '24px'
  return (
    <>
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '80px'}>
          <ContentMaxWidth
            maxW="1600px"
            color="black"
            alignItems="flex-start"
            flexDir="column"
            pb="120px"
          >
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="64px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Meet Team Reach at Black Hat
              </Heading>
              <Box mb="64px">
                <Grid
                  gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
                  gridGap={isMobile ? '40px' : '280px 160px'}
                  alignItems="center"
                >
                  <BlogImage src={meetReach} />
                  <TextBlurb
                    heading="Meet Colt, Garrett & Team Reach in our private meeting space at the Mandalay. Refreshments provided."
                    body={
                      <>
                        <HubspotForm
                          portalId="21999761"
                          formId="74a815ef-6544-4200-97f0-6da06c5c6399"
                          loading={<div>Loading...</div>}
                        />
                      </>
                    }
                    flex={1}
                  />
                </Grid>
              </Box>
              <Box mb="16px">
                <Grid
                  gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
                  gridGap={isMobile ? '40px' : '280px 160px'}
                  alignItems="center"
                >
                  <TextBlurb
                    heading="Get to know Reach"
                    body={
                      <>
                        Optimize and protect with your existing tools. Our
                        AI-driven platform reprograms your security
                        infrastructure, ensuring real-time, tailored defenses
                        without adding complexity. Gain visibility, maximize
                        ROI, and enhance protection.
                        <br />
                        <br />
                        <Link
                          href={'/downloads/Reach-Datasheet-Sept2024.pdf'}
                          target="_blank"
                          download
                        >
                          Download
                        </Link>{' '}
                        our comprehensive overview to learn more.
                      </>
                    }
                    flex={1}
                  />
                  <Link
                    borderRadius="8px"
                    href={'/downloads/Reach-Datasheet-Sept2024.pdf'}
                    target="_blank"
                    download
                  >
                    <BlogImage my="0" src={reachOnePagerImage}></BlogImage>
                  </Link>
                </Grid>
              </Box>
            </Box>
          </ContentMaxWidth>
          <ContentMaxWidth
            bg="#000"
            maxW="1600px"
            color="#fff"
            alignItems={isMobile ? 'flex-start' : 'center'}
            flexDir="column"
            py="120px"
          >
            <>
              <Box mb="16px" px={isMobile ? '16px' : undefined}>
                <Heading textAlign="center" size="xl" pt="48px" mb="64px">
                  See what customers say about Reach:
                </Heading>
                <Flex
                  flexDir={isMobile ? 'column' : 'row'}
                  mb="80px"
                  w="100%"
                  justifyContent="center"
                  alignItems="center"
                  gap={isMobile ? '16px' : '40px'}
                >
                  <Testimonial
                    quote="Reach has really shined a light on those blind spots and told us things we didn’t know our products could do.  I think or Reach as a cheat code for our security products."
                    author="Sebastian"
                    title="CISO"
                    logoPath={autodeskLogo}
                    delay={0.33}
                  />
                  <Testimonial
                    quote="Translating an identity-first approach to all products isn’t easy.  Every product speaks identity in a different way.  Reach does the translation dynamically and automatically."
                    author="Marek"
                    title="Sr. Manager, SOC"
                    logoPath={nutanixLogo}
                    delay={0.66}
                  />
                </Flex>
              </Box>
              <Box
                mb="16px"
                px={isMobile ? '16px' : undefined}
                m="0 auto"
                textAlign={isMobile ? 'center' : ''}
              >
                <Grid
                  gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
                  gridGap={isMobile ? '40px' : '280px 160px'}
                  alignItems="center"
                >
                  <Box minH="300px">
                    <Heading mb="16px">Read More</Heading>
                    <Button
                      as={NavLink}
                      to="/resources/blog"
                      h="64px"
                      rightIcon={<Icon as={AiOutlineArrowRight} />}
                      size="lg"
                      colorScheme="reachTeal"
                      fontSize={footerButtonFontSize}
                      variant="outline"
                      mb="16px"
                      w="100%"
                    >
                      Reach Blog
                    </Button>
                    <Button
                      as={NavLink}
                      to="/product"
                      h="64px"
                      rightIcon={<Icon as={AiOutlineArrowRight} />}
                      size="lg"
                      colorScheme="reachTeal"
                      fontSize={footerButtonFontSize}
                      variant="outline"
                      mb="16px"
                      w="100%"
                    >
                      Product
                    </Button>
                  </Box>
                  <Box minH="300px">
                    <Heading mb="16px">Get Reach Now</Heading>
                    <Flex flexDir="column">
                      <Link href="/try-reach">
                        <Button
                          as="a"
                          h="64px"
                          rightIcon={<Icon as={AiOutlineArrowRight} />}
                          size="lg"
                          colorScheme="reachTeal"
                          fontSize={footerButtonFontSize}
                          variant="outline"
                          w="100%"
                          mb="16px"
                        >
                          Try Reach
                        </Button>
                      </Link>
                      <Link href="https://aws.amazon.com/marketplace/pp/prodview-yglym62xy4lp6">
                        <Button
                          as="a"
                          h="64px"
                          rightIcon={<Icon as={AiOutlineArrowRight} />}
                          size="lg"
                          colorScheme="reachTeal"
                          fontSize={footerButtonFontSize}
                          variant="outline"
                          w="100%"
                          mb="16px"
                        >
                          Reach on AWS Marketplace
                        </Button>
                      </Link>
                      <Link href="https://marketplace.crowdstrike.com/listings/reach-security-platform">
                        <Button
                          as="a"
                          h="64px"
                          rightIcon={<Icon as={AiOutlineArrowRight} />}
                          size="lg"
                          colorScheme="reachTeal"
                          fontSize={footerButtonFontSize}
                          variant="outline"
                          w="100%"
                        >
                          Reach on CrowdStrike Marketplace
                        </Button>
                      </Link>
                    </Flex>
                  </Box>
                </Grid>
              </Box>
            </>
          </ContentMaxWidth>
        </Box>
      </LightMode>
    </>
  )
}

export default BlackHatMeetReach
