import React, { useEffect } from 'react'
import {
  Box,
  Heading,
  LightMode,
  Link,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import B from 'components/Shared/B'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'

const ReachRaise = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading mb="16px" fontSize={isMobile ? '30px' : '36px'}>
                Reach Security Raises $20M for AI to Transform How Companies Use
                Their Cybersecurity Products
              </Heading>
              <Box mb="16px" fontStyle="italic">
                Ballistic Ventures leads investment in startup enabling
                companies to deliver the best possible cybersecurity using the
                products they already own
              </Box>

              <Box mb="16px">
                <B>Sunnyvale, CA – March 7, 2024 – </B>
                Reach Security, the company pioneering advanced AI to reinvent
                security operations, today announced $20 million in funding led
                by Ballistic Ventures, with participation from Artisanal
                Ventures and industry luminaries, including Mark McLaughlin,
                former CEO and President of Palo Alto Networks. Existing backers
                Webb Investment Network, Ridge Ventures, and TechOperators also
                joined the round.
              </Box>

              <Box mb="16px">
                Sentiment has shifted in the cybersecurity market. Leaders are
                tired of buying tools but not knowing whether those tools are
                helping, or how to use them more effectively. And defenders are
                stretched too thin to master the growing number of capabilities;
                the last thing in the world they want is more. With
                configurations and policies out of date, attackers easily
                exploit the gaps. Platforms were supposed to fix this, but
                numbers don’t lie: Every year brings more breaches than the year
                before. This change in perspective has driven significant
                momentum for Reach, who are reshaping the way teams consume
                security and capitalize on their existing investments.
              </Box>

              <Box mb="16px">
                Co-founders Garrett Hamilton, CEO, and Colt Blackmore, CTO,
                spent more than a decade building detection and prevention
                products at some of the most iconic cybersecurity companies –
                Palo Alto Networks, Proofpoint, and Cylance. “This gave us the
                unique opportunity to stand shoulder-to-shoulder with hundreds
                of security teams when things went wrong, and it was always the
                same story: They could’ve stopped the attack with the tools they
                already had,” Hamilton said. “Every other company in our
                industry will say that you need another security mousetrap to
                solve this problem. They’re wrong. We’ve looked at
                tens-of-thousands of enterprises and seen firsthand that the
                average security team uses less than 20% of what they have, and
                struggles to secure their organization as a direct result.”
              </Box>

              <Box mb="32px">
                “Reach Security represents a pivotal shift in how cybersecurity
                leaders will buy, deploy and manage their security solutions,”
                said Barmak Meftah, Co-founder and General Partner of Ballistic
                Ventures, who joins the Board of Directors as part of the firm’s
                investment. “Before companies invest in more point products,
                they need to know how to use their current solutions effectively
                to minimize their risk exposure and maximize efficacy without
                disrupting the business,” he added. “From day one, we’ve been
                impressed with the team and technology behind Reach, and their
                approach aligns perfectly with our vision to invest in companies
                that are truly redefining the cybersecurity landscape.”
              </Box>

              <Box fontSize="24px" fontWeight="bold" mb="16px">
                The Technology Behind Reach Security
              </Box>

              <Box mb="16px">
                Reach Security delivers the first AI purpose-built to reprogram
                an organization’s security infrastructure based on who they are
                and how they’re being attacked. Unlike solutions limited to
                cloud security, Reach acts across the entire security estate,
                interrogating each tool, gathering data on who is being targeted
                and how those attacks work. It takes seconds to set up and
                minutes for insight and action, with no new sensors,
                infrastructure, or attack simulations required.
              </Box>

              <Box mb="16px">
                The result is leaders having complete visibility into what has
                been deployed, how it can be improved and the tools necessary to
                help defenders tailor improvements to protect them.
              </Box>

              <Box mb="32px">
                Companies trust Reach to make these mission-critical decisions
                because Reach’s AI does not hallucinate and does not make
                mistakes – it’s a different kind of AI, one designed with
                accountability and reliability in mind.
              </Box>

              <Box fontSize="24px" fontWeight="bold" mb="16px">
                Industry Support for Reach Security
              </Box>

              <UnorderedList mb="16px">
                <ListItem>
                  Mark McLaughlin, the former CEO and President of Palo Alto
                  Networks, and angel investor in Reach: “Security teams
                  continue to grapple with choosing between best-of- breed
                  capabilities and the tremendous benefits provided by
                  platforms. Reach understands this challenge and meets
                  customers on the path between the two options, focusing on
                  what matters – empowering their teams to get the best possible
                  security posture from the tools they already own. I invested
                  in Reach now because their approach is always relevant, and it
                  becomes even more critical when economic headwinds demand that
                  every dollar spent must produce tangible results from security
                  investments.”
                </ListItem>

                <ListItem>
                  Sebastian Goodwin, Global CISO of Autodesk, a Reach customer:
                  “We’ve seen firsthand how Reach’s platform increases our
                  visibility and optimizes our security stack, providing our
                  teams with capabilities to measurably maximize our
                  effectiveness. Reach has offered a valuable capability that
                  helps my team realize Autodesk’s mission to be the most
                  trusted partner in the industries we serve.”
                </ListItem>

                <ListItem>
                  Geoff Belknap, CISO of LinkedIn, a strong Reach supporter:
                  “Reach has found a way to take the age-old problem of ‘too
                  many tools and not enough people’ and solve it with a
                  purpose-built product that focuses on helping organizations be
                  as effective as possible with what they already have. They’re
                  showing that pragmatic approaches to solving security problems
                  can have an outsized impact on our industry.”
                </ListItem>

                <ListItem>
                  Jon Jensen, Vice President of Cyber Security Sales at
                  Presidio, a Reach channel partner: “Reach’s market opportunity
                  is massive. Its product resonates with teams and our
                  customers. The idea that security is most effective when
                  tailored to an organization is something we’ve preached in
                  security for years, and Reach is making it scalable for the
                  first time with AI.”
                </ListItem>
              </UnorderedList>

              <Box>
                Reach Security addresses a universal need. For CISOs and
                security leaders who want to address that need now,{' '}
                <Link fontWeight="bold" as={NavLink} to="/connect">
                  schedule a demo
                </Link>{' '}
                or learn more at{' '}
                <Link fontWeight="bold" as={NavLink} to="/">
                  Reach Security
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default ReachRaise
