import React, { useEffect } from 'react'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import HubspotForm from 'react-hubspot-form'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import FadeIn from 'components/Shared/FadeIn'

const HUBSPOT_FORM_ID = 'b65e302b-3a66-44d3-91a4-c427de25111a'
const HUBSPOT_PORTAL_ID = '21999761'

const ScheduleDemo = () => {
  const { isMobile, setIsWhiteHeader } = useApp()
  useEffect(() => {
    window.scrollTo(0, 0)
    setIsWhiteHeader(true)
    return () => setIsWhiteHeader(false)
  }, [])

  return (
    <>
      <PageHeader />
      <Box pt="128px" minH="100vh">
        <ContentMaxWidth pt={isMobile ? '40px' : '72px'}>
          <FadeIn>
            <Flex alignItems="center" flexDir="column" flex={1} mb="64px">
              <Heading
                textAlign="center"
                size={isMobile ? 'xl' : '3xl'}
                color="black"
                mb="32px"
              >
                See Reach in Action
              </Heading>
              <Text
                color="black"
                textAlign="center"
                maxW="1080px"
                lineHeight={1.8}
                fontSize="20px"
              >
                Connect with our team to see how Reach enables you to deliver
                the best configuration to your security stack without needing to
                spend time manually analyzing security events, identity
                information, available product controls or the last 52 release
                note articles from your vendor.
              </Text>
            </Flex>
          </FadeIn>
        </ContentMaxWidth>
        <Box mb="160px" w="100%" h={isMobile ? '500px' : '700px'}>
          <FadeIn delay={0.5}>
            <ContentMaxWidth maxW="1120px" px={0}>
              <Box flex={1} bg="black" w="100%" p={isMobile ? '16px' : '120px'}>
                <HubspotForm
                  portalId={HUBSPOT_PORTAL_ID}
                  formId={HUBSPOT_FORM_ID}
                  loading={<div>Loading...</div>}
                />
              </Box>
            </ContentMaxWidth>
          </FadeIn>
        </Box>
      </Box>
      <PageFooter />
    </>
  )
}

export default ScheduleDemo
