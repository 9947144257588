import React, { useEffect } from 'react'
import { useApp } from 'context/App'
import ProductBody from './ProductBody'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import Integrations from 'components/Home/Integrations'
// import BlockQuote from 'components/Shared/PageSections/BlockQuote'
import ProductFeatures from './ProductFeatures'
import ProductSplashArea from './ProductSplashArea'

const Product = () => {
  const { setIsWhiteHeader } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsWhiteHeader(true)
    return () => setIsWhiteHeader(false)
  }, [])

  return (
    <>
      <PageHeader />
      <ProductSplashArea />
      <ProductBody />
      <ProductFeatures />
      <Integrations />
      {/* <BlockQuote /> */}
      <PageFooter />
    </>
  )
}

export default Product
