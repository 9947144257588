import React from 'react'
import { Box, Grid } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { useApp } from 'context/App'
import headshot from './images/SebastianGoodwin/sebastian2c.jpg'
import nutanixLogo from './images/nutanixLogo.svg'
import anonymousManufacturingImage from './images/AnonymousManufacturing/circuit_board_square.jpeg'
import CustomerCard from './CustomerCard'

const Body = () => {
  const { isMobile } = useApp()
  return (
    <Box
      flex={1}
      pt={isMobile ? '90px' : '24px'}
      color="white"
      overflow="visible"
      pb="96px"
    >
      <ContentMaxWidth
        px={isMobile ? '16px' : '48px'}
        maxW="2000px"
        flexDir="column"
      >
        <Grid
          maxW="100%"
          gridGap="80px"
          gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr));"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <CustomerCard
            h="100%"
            personName="Sebastian Goodwin"
            personTitle="CISO"
            img={headshot}
            logo={nutanixLogo}
            title="Applying security control cheat codes at Nutanix"
            body={
              <Box>
                <Box mb="16px">
                  “Reach has given us prescriptive guidance on where we can
                  improve in our existing toolsets. It’s 100% tailored to us,
                  and the things that are recommended are done based on the
                  current state of my environment. It’s not theoretical, it’s
                  practical.”
                </Box>
                <Box>- Sebastian Goodwin, CISO of Nutanix.</Box>
              </Box>
            }
            caseStudyUrl="/customers/nutanix"
          />

          <CustomerCard
            h="100%"
            personName="Global Manufacturing"
            personDetails="IT Security Manager"
            img={anonymousManufacturingImage}
            title="Using data to improve product effectiveness at a global manufacturing firm"
            body={
              <Box>
                <Box mb="16px">
                  “We needed to get the full potential out of our products
                  without the organizational overhead to manage them. Reach is
                  giving us what we need to be confident that the products we
                  rely on the most for protection are working effectively for
                  us.”
                </Box>
                <Box>- IT Security Manager</Box>
              </Box>
            }
            caseStudyUrl="/customers/anonymous-manufacturing"
          />
        </Grid>
      </ContentMaxWidth>
    </Box>
  )
}

export default Body
