import React, { useEffect } from 'react'
import PageFooter from 'components/Shared/PageFooter'
import PageHeader from 'components/Shared/PageHeader'
import CareersBody from './CareersBody'
import { useApp } from 'context/App'

const Careers = () => {
  const { setIsWhiteHeader } = useApp()
  useEffect(() => {
    setIsWhiteHeader(true)
    return () => setIsWhiteHeader(false)
  }, [])

  return (
    <>
      <PageHeader />
      <CareersBody />
      <PageFooter />
    </>
  )
}

export default Careers
