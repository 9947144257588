import React, { useState } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import MotionBox from 'components/Shared/MotionBox'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'

const getRandomItem = (array) => array[Math.floor(Math.random() * array.length)]

const taglines = [
  "That's no moon...",
  'Klaatu Barada N... necktie... nectar... nickel... noodle.',
  'Klaatu Barada N... necktie... nectar... nickel... noodle.',
  'Klaatu Barada N... necktie... nectar... nickel... noodle.',
  "Look, maybe I didn't say every tiny syllable, no. But basically I said them, yeah.",
  "What we've got here is a failure to communicate.",
  "Roads? Where we're going we don't need roads.",
  'Leave the gun. Take the cannoli.',
  "I'm sorry, Dave. I'm afraid I can't do that.",
  'So Long, and Thanks for All the Fish.',
  'In the beginning the Universe was created. This has made a lot of people very angry and been widely regarded as a bad move.',
]

const PageNotFound = () => {
  const [randomTagline] = useState(getRandomItem(taglines))

  return (
    <>
      <PageHeader />
      <Box h="100vh" pt="120px">
        <Flex
          h="100%"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Flex
            w="400px"
            h="400px"
            borderRadius="50%"
            bg="reachBlue.500"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            mb="64px"
          >
            <Heading color="white">Aw, heck</Heading>
            <Heading
              color="white"
              fontSize="128px"
              textAlign="100%"
              lineHeight="1"
            >
              404
            </Heading>
          </Flex>
          <MotionBox
            animate={{ opacity: 0.5 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 5, delay: 1 }}
          >
            <Text
              lineHeight="1.5"
              maxWidth="500px"
              size="xl"
              textAlign="center"
              color="white"
            >
              {randomTagline}
            </Text>
          </MotionBox>
        </Flex>
      </Box>
      <PageFooter />
    </>
  )
}

export default PageNotFound
