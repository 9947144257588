import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box, Flex } from '@chakra-ui/react'
import FormInput from 'components/Shared/Form/FormInput'
import WhiteButton from 'components/Shared/WhiteButton'
import { useApp } from 'context/App'

// THIS FILE IS A BOILERPLATE TEMPLATE FOR COPY PASTING
// RECOMMENDED THAT YOU MAKE A FILE FOR THE FORM ALONE
// COPY PASTE THIS INTO IT, AND HOOK INTO THE PROPS

const JoinUsForm = ({
  onSubmitHook,
  email,
  emailConfirm,
  password,
  requestStatus,
}) => {
  const formSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup
      .string()
      .email('Email is not a valid format')
      .required('Email is required'),
    phone: yup.string().required('Phone number is required'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
    defaultValues: {
      email,
      emailConfirm,
      password,
    },
  })

  const onSubmit = () => {
    onSubmitHook() // Can be replaced by logic in the container component or written here
  }

  const { isMobile } = useApp()
  const disabledStatus = false

  return (
    <Box w="100%">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          name="name"
          label="Name"
          disabled={disabledStatus}
          register={register}
          errors={errors}
        />
        <FormInput
          // id="email-123" optional prop for id and label selection conflicts
          name="email"
          label="Email"
          disabled={disabledStatus}
          register={register}
          errors={errors}
        />
        <FormInput
          name="phone"
          type="number"
          label="Phone Number"
          disabled={disabledStatus}
          register={register}
          errors={errors}
        />
        <Flex mt="40px" justifyContent="flex-end">
          <WhiteButton
            mr="8px"
            size="lg"
            w={isMobile ? '100%' : '160px'}
            isLoading={requestStatus === 'loading'}
            loadingText="Submitting"
            disabled={disabledStatus}
            type="submit"
          >
            Submit
          </WhiteButton>
        </Flex>
      </form>
    </Box>
  )
}

JoinUsForm.defaultProps = {
  onSubmitHook: () => {},
}

JoinUsForm.propTypes = {
  onSubmitHook: PropTypes.func,
  email: PropTypes.string,
  emailConfirm: PropTypes.string,
  password: PropTypes.string,
  requestStatus: PropTypes.string,
}

export default JoinUsForm
