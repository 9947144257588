import React from 'react'
import { Box, Heading, Grid, Flex, Button, Link, Icon } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'

const ResourcesFooter = () => {
  const { isMobile } = useApp()
  const footerButtonFontSize = isMobile ? '16px' : '24px'
  return (
    <ContentMaxWidth
      bg="#000"
      maxW="1600px"
      color="#fff"
      alignItems={isMobile ? 'flex-start' : 'center'}
      flexDir="column"
      py="120px"
    >
      <Box
        mb="16px"
        px={isMobile ? '16px' : undefined}
        m="0 auto"
        textAlign={isMobile ? 'center' : ''}
      >
        <Grid
          gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
          gridGap={isMobile ? '40px' : '280px 160px'}
          alignItems="center"
        >
          <Box minH="300px">
            <Heading mb="16px">Read More</Heading>
            <Button
              as={NavLink}
              to="/resources/blog"
              h="64px"
              rightIcon={<Icon as={AiOutlineArrowRight} />}
              size="lg"
              colorScheme="reachTeal"
              fontSize={footerButtonFontSize}
              variant="outline"
              mb="16px"
              w="100%"
            >
              Reach Blog
            </Button>
            <Button
              as={NavLink}
              to="/product"
              h="64px"
              rightIcon={<Icon as={AiOutlineArrowRight} />}
              size="lg"
              colorScheme="reachTeal"
              fontSize={footerButtonFontSize}
              variant="outline"
              mb="16px"
              w="100%"
            >
              Product
            </Button>
          </Box>
          <Box minH="300px">
            <Heading mb="16px">Get Reach Now</Heading>
            <Flex flexDir="column">
              <Link href="/try-reach">
                <Button
                  as="a"
                  h="64px"
                  rightIcon={<Icon as={AiOutlineArrowRight} />}
                  size="lg"
                  colorScheme="reachTeal"
                  fontSize={footerButtonFontSize}
                  variant="outline"
                  w="100%"
                  mb="16px"
                >
                  Try Reach
                </Button>
              </Link>
              <Link href="https://aws.amazon.com/marketplace/pp/prodview-yglym62xy4lp6">
                <Button
                  as="a"
                  h="64px"
                  rightIcon={<Icon as={AiOutlineArrowRight} />}
                  size="lg"
                  colorScheme="reachTeal"
                  fontSize={footerButtonFontSize}
                  variant="outline"
                  w="100%"
                  mb="16px"
                >
                  Reach on AWS Marketplace
                </Button>
              </Link>
              <Link href="https://marketplace.crowdstrike.com/listings/reach-security-platform">
                <Button
                  as="a"
                  h="64px"
                  rightIcon={<Icon as={AiOutlineArrowRight} />}
                  size="lg"
                  colorScheme="reachTeal"
                  fontSize={footerButtonFontSize}
                  variant="outline"
                  w="100%"
                >
                  Reach on CrowdStrike Marketplace
                </Button>
              </Link>
            </Flex>
          </Box>
        </Grid>
      </Box>
    </ContentMaxWidth>
  )
}

export default ResourcesFooter
