import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  Spacer,
} from '@chakra-ui/react'
import FadeIn from 'components/Shared/FadeIn'
import { RiExternalLinkLine } from 'react-icons/ri'
import { useApp } from 'context/App'
import { NavLink } from 'react-router-dom'

const BlogEntry = ({
  url,
  internal,
  imageUrl,
  title,
  description,
  bgSize = 'contain',
  delay = 0,
}) => {
  const { isMobile } = useApp()

  return (
    <FadeIn y={30} duration={0.66} delay={delay}>
      <Flex
        flexDir={isMobile ? 'column' : 'row'}
        gap="32px"
        w="100%"
        h="100%"
        p="32px"
        bg="black"
        color="white"
      >
        <Box
          transition="0.5s"
          border="2px solid"
          borderColor="reachTeal.400"
          overflow="hidden"
          bgColor="white"
          h="fit-content"
          p="32px"
        >
          <Box
            w={isMobile ? '100%' : '320px'}
            h="160px"
            bgImage={`url(${imageUrl})`}
            transition=".25s"
            bgSize={bgSize}
            bgRepeat="no-repeat"
            bgPos="50% 50%"
            flexShrink={0}
          />
        </Box>
        <Flex flexDir="column" flex={1}>
          <Heading mb="4px" size="xl" fontSize="32px">
            {title}
          </Heading>
          <Box mb="16px" lineHeight={1.2} fontWeight="light" fontSize="18px">
            {description}
          </Box>
          <Spacer />
          <Button
            rightIcon={<Icon as={RiExternalLinkLine} />}
            colorScheme="reachTeal"
            as={internal ? NavLink : Link}
            target={internal ? undefined : '_blank'}
            href={url}
            to={url}
          >
            Read More
          </Button>
        </Flex>
      </Flex>
    </FadeIn>
  )
}

BlogEntry.propTypes = {
  delay: PropTypes.number,
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  bgSize: PropTypes.string,
  internal: PropTypes.bool,
}

export default BlogEntry
