import React from 'react'
import PropTypes from 'prop-types'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Heading,
  ListItem,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'
import { useApp } from 'context/App'
import SplashArea from './SplashArea'
import DemoProcess from './DemoProcess'
import B from 'components/Shared/B'
import CustomerTestimonials from './CustomerTestimonials'
import ContactSales from './ContactSales'

const FaqItem = ({ question, answer }) => (
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left" fontSize="24px">
          {question}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Box fontSize="18px" fontWeight="light">
        {answer}
      </Box>
    </AccordionPanel>
  </AccordionItem>
)

FaqItem.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.any,
}

const ToolAssessmentBody = () => {
  const { isMobile } = useApp()

  return (
    <>
      <SplashArea />
      <CustomerTestimonials />
      <ContactSales />
      <ContentMaxWidth
        w="100%"
        mb="48px"
        pt={isMobile ? '64px' : '120px'}
        maxW="1600px"
        flexDir={isMobile ? 'column' : 'row'}
        alignItems="flex-start"
        overflow="hidden"
        borderBottomLeftRadius="10%"
        borderBottomRightRadius="10%"
      >
        <Center pt="8px" pr={!isMobile && '96px'} flex={1}>
          <DemoProcess />
        </Center>
        <Box pb="64px" flex={0.8} color="black">
          <Heading
            mb="40px"
            fontSize="36px"
            textAlign={isMobile ? 'center' : 'left'}
          >
            Frequently Asked Questions
          </Heading>
          <Accordion mb="40px">
            <FaqItem
              question="What's required to get up and running?"
              answer={
                <Box>
                  <Box mb="8px">
                    Not much! Lightweight connections to the following are
                    required to begin data processing:
                  </Box>
                  <UnorderedList mb="8px">
                    <ListItem>
                      Email security product (Proofpoint or Microsoft Defender
                      for O365)
                    </ListItem>
                    <ListItem>
                      Canonical record of identity (AzureAD or Active Directory)
                    </ListItem>
                  </UnorderedList>
                  <Box>
                    Beyond these, we’ll connect to the product you choose for
                    the usage assessment. See below for how we go about
                    connecting to each of the products we support.
                  </Box>
                </Box>
              }
              flex={1}
            />
            <FaqItem
              question="How do I connect my products?"
              answer="You’ll receive a tailored onboarding flow (think TurboTax) that includes a step- by- step walkthrough to connect your products in a streamlined way.  No meeting required."
              flex={1}
            />
            <FaqItem
              question="What products do you support & how do you connect to them?"
              answer={
                <>
                  <UnorderedList>
                    <ListItem>Proofpoint TAP & PPS - API</ListItem>
                    <ListItem>Palo Alto Networks Prisma Access - API</ListItem>
                    <ListItem>Crowdstrike - API</ListItem>
                    <ListItem>Okta - API</ListItem>
                    <ListItem>SentinelOne - API</ListItem>
                    <ListItem>
                      <B color="reachTeal.300">New!</B> Microsoft Defender for
                      O365 Email - Microsoft Enterprise Application*
                    </ListItem>
                    <ListItem>
                      <B color="reachTeal.300">New!</B> Microsoft Conditional
                      Access - Microsoft Enterprise Application*
                    </ListItem>
                    <ListItem>
                      AzureAD - Microsoft Enterprise Application*
                    </ListItem>
                  </UnorderedList>
                  <Box fontSize="14px">
                    * Only one enterprise app needs to be deployed for all
                    Microsoft products
                  </Box>
                </>
              }
            />
            <FaqItem
              question="What if I don’t see my product supported?"
              answer="We are constantly adding new integrations and want to hear from you. If you don’t see your product supported, send us an email at info@reach.security and let us know. We’ll let you know how we can help."
            />
            <FaqItem
              question="How is your output different from a services company?"
              answer="We interrogate your products to learn how your organization is being attacked and how your controls are being used to stop those attacks. This allows us to tailor a set of recommendations for you that is context and prioritization driven."
              flex={1}
            />
            <FaqItem
              question="What’s the difference between you and vendor best practices?"
              answer="Vendor best practice assessments take a generic, check the box approach to configuring your tools and ignore threats observed in your environment.  Reach translates threats seen into configuration recommendations that are custom to you, and only you.  Reach shows how well your products are being utilized by baselining your current configurations against threat-informed configuration recommendations of what’s available."
              flex={1}
            />
            <FaqItem
              question="What’s in it for me?"
              answer={
                <Box>
                  <Box mb="8px">
                    Reach’s tools usage assessment consists of a single report
                    that provides:
                  </Box>
                  <OrderedList mb="8px">
                    <ListItem>
                      A report that helps rationalize the value of your product
                      as it is configured today to protect you.
                    </ListItem>
                    <ListItem>
                      A map on how to improve your configurations using a
                      threat-informed approach.
                    </ListItem>
                  </OrderedList>
                  <Box>
                    We define the effectiveness of your product by indexing the
                    number of attacks delivered in your environment against the
                    number of attacks that would be preventable by modifying
                    configurations.
                  </Box>
                </Box>
              }
              flex={1}
            />
            <FaqItem
              question="What do you do with my data?"
              answer="All data will be deleted within 30 days of the usage assessment being completed.  For more details on data retention and deletion, check out our Security and Privacy documentation."
            />
            <FaqItem
              question="Can I evaluate multiple products?"
              answer="Yes. Reach out (no pun intended) to our team at info@reach.security to learn more about evaluating multiple products."
            />
          </Accordion>
        </Box>
      </ContentMaxWidth>
    </>
  )
}

export default ToolAssessmentBody
